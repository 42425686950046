'use client'
import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'

import { cn } from '@/lib/utils'
import useStore from '@/store'

import Icon from './icon'

const buttonVariants = cva(
  'relative inline-flex cursor-pointer items-center justify-center text-center disabled:pointer-events-none disabled:opacity-50 [&[aria-disabled="true"]]:pointer-events-none [&[aria-disabled="true"]]:opacity-50',
  {
    variants: {
      variant: {
        primary:
          'border-default-primary-button-border bg-default-primary-button-bg text-default-primary-button-text after:pointer-events-none after:border-beige-dark after:transition-colors hover:after:border-beige',
        secondary:
          'border-default-secondary-button-border bg-default-secondary-button-bg text-default-secondary-button-text transition-colors after:border-beige-dark after:transition-colors hover:border-default-secondary-button-hover-border hover:after:border-default-secondary-button-after-hover-border',
        transparent:
          'text-off-white after:border-beige-dark/50 after:transition-colors hover:after:border-off-white',
        tertiary:
          'border-b-2 p-0 py-1 leading-extra-tight before:block before:min-h-[9px] before:w-0 before:-translate-x-6 before:bg-current before:transition-width before:content-[""] before:[mask:url("/assets/icons/icon-arrow-button.svg")_no-repeat_left/18px_9px] hover:before:w-6 hover:before:translate-x-0 group-hover:before:w-6 group-hover:before:translate-x-0',
        outline: 'border-input bg-background hover:bg-accent hover:text-accent-foreground border',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        header:
          'nav-text rounded px-2 py-2 transition-colors hover:bg-beige-light hover:text-black md:px-[10px]',
        icon: 'transition-[opacity_300ms,color_500ms] hover:opacity-50',
        select:
          'text-sm flex h-10 w-full items-center justify-between rounded-md border border-neutral-200 bg-white px-3 py-2 ring-offset-white placeholder:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
        loves:
          'leading-17 relative border-2 border-[#35383999] px-6 py-4 font-sans text-15 font-bold tracking-[0.05em] after:absolute after:bottom-[2px] after:left-[2px] after:right-[2px] after:top-[2px] after:border-[1px] after:border-[#C4BFAF80]',
        modalClose:
          'relative border bg-beige px-4 py-3 text-[16px] font-bold leading-[24px] tracking-[0.04em] text-black shadow-birmingham before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:shadow-birmingham-before after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:shadow-birmingham-after hover:border-gray-light hover:bg-beige-medium hover:text-white hover:shadow-birmingham-red hover:before:shadow-birmingham-before-red hover:after:shadow-birmingham-after-red',
        'permit-rooms':
          'basic-transition group mt-[38px] flex -translate-y-1 translate-x-1 transform items-center gap-2 rounded-sm border-2 border-black bg-pr-yellow px-5 py-[13px] text-center font-sans text-[15px] font-bold tracking-[0.75px] shadow-button-shadow duration-100 hover:translate-x-0 hover:translate-y-0 hover:bg-black hover:text-white hover:shadow-none',

        // locations
        carnaby:
          'shrink-0 bg-almost-black px-4 py-[20px] text-center font-antique uppercase leading-none text-off-white [clip-path:ellipse(49%_50%_at_50%_50%)] hover:bg-carnaby-red sm:px-[27px]',
        kensington:
          'inline-flex items-center gap-x-4 rounded-full font-beton text-[16px] font-bold uppercase leading-[30px] tracking-[1.28px] before:h-[26px] before:w-[26px] before:bg-[url("/assets/kensington-spot-red.svg")] before:bg-contain before:bg-center hover:text-red-light',
        'covent-garden':
          'hover-underline w-fit pb-1 text-justify font-neutra text-[16px] font-[600] uppercase tracking-[1.76px] hover:border-transparent',
        'canary-wharf':
          'border-b border-solid border-canary-wharf-red pb-2 font-futuracond text-[19px] font-bold uppercase leading-[22px] text-canary-wharf-red lg:text-[22px]',
        battersea:
          'rounded border-[3px] border-black bg-battersea-yellow px-[10px] pb-[5px] pt-1 text-center font-gothic text-[16px] font-extrabold uppercase leading-[16px] text-black after:border-0 hover:bg-battersea-orange hover:text-white hover:transition-all hover:duration-500',
        birmingham:
          'relative z-[1] border px-4 py-3 text-[16px] font-bold leading-[24px] tracking-[0.04em] shadow-birmingham before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:-z-10 before:shadow-birmingham-before after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:-z-10 after:-z-[1] after:shadow-birmingham-after hover:border-red-birmingham-border hover:bg-red-birmingham hover:text-white hover:shadow-birmingham-red hover:before:shadow-birmingham-before-red hover:after:shadow-birmingham-after-red',
        edinburgh:
          'relative z-[1] mb-2 border px-4 py-3 text-[12px] font-bold uppercase tracking-[0.08em] after:absolute after:bottom-[-6px] after:left-[5px] after:right-[5px] after:top-[-6px] after:-z-[1] after:border',
        shoreditch:
          'relative border px-4 py-3 font-sans text-[12px] font-bold uppercase leading-[14px] tracking-[0.25em] after:absolute after:bottom-[-6px] after:left-[5px] after:right-[5px] after:top-[-6px] after:-z-[1] after:border',
        'kings-cross':
          'border-b pb-1 font-grotesque text-[14px] font-bold uppercase leading-4 tracking-[0.1em]',
        manchester:
          'hover-no-underline pb-1 font-baskerville text-[18px] font-semibold uppercase italic leading-5 tracking-[0.2em]',
      },
      size: {
        default: '',
        sm: '',
        md: '',
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary', 'transparent'],
        size: 'default',
        class: 'px-8 py-3',
      },
      {
        variant: ['primary', 'secondary', 'transparent'],
        size: 'sm',
        class: 'px-4 py-2.5',
      },
      {
        variant: ['primary', 'secondary', 'transparent', 'tertiary', 'outline'],
        class: 'font-bold leading-none tracking-5 md:text-[0.9375rem]',
      },
      {
        variant: ['primary', 'secondary', 'transparent'],
        class: 'border-2',
      },
      {
        variant: ['primary', 'secondary', 'transparent', 'tertiary'],
        class: 'font-sans text-[0.8125rem]',
      },
      {
        variant: ['primary', 'secondary', 'transparent'],
        class: 'after:absolute after:inset-1 after:border after:opacity-50 after:content-[""]',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    // const variableAttrs: React.ButtonHTMLAttributes<HTMLButtonElement> = {}
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonVariants({ variant, className, size }))}
        ref={ref}
        {...props}
        // {...variableAttrs}
      />
    )
  },
)

Button.displayName = 'Button'

// const BaseButton = ({ href, target, ...props }) => {
//   const isBook = href === '#book' || href === '/#book' || href?.includes('#book')
//   const isExternal =
//     href?.startsWith('http') ||
//     href?.startsWith('mailto') ||
//     href?.startsWith('tel') ||
//     href?.startsWith('#') ||
//     target === '_blank'

//   const onBookingClickHandler = () => {
//     toggleBooking()
//   }

//   if (isBook) {
//     return <Button onClick={onBookingClickHandler} {...props} />
//   }

//   if (href) {
//     return (
//       <Button asChild>{isExternal ? <Link href={href}></Link> : <a href={href}>Login</a>}</Button>
//     )
//   }

//   return <Button {...props} />
// }

const BookingButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const toggleBooking = useStore((state) => state.toggleBooking)

  return (
    <Button onClick={toggleBooking} {...props} ref={ref}>
      {props.children}
    </Button>
  )
})

const WishlistButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Button
      variant="ghost"
      {...props}
      className={cn('h-8 w-8 bg-beige', props.className)}
      ref={ref}
    >
      <Icon variant="heart" />
      <span className="sr-only">Wishlist</span>
    </Button>
  )
})

const AddToBasket = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Button ref={ref} {...props}>
      {props.children ? props.children : 'Add to basket'}
    </Button>
  )
})

const BookmarkButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="ghost"
      {...props}
      className={cn('h-8 w-8 bg-beige', props.className)}
    >
      <Icon variant="bookmark" />
      <span className="sr-only">Bookmark</span>
    </Button>
  )
})

BookingButton.displayName = 'BookingButton'
WishlistButton.displayName = 'WishlistButton'
AddToBasket.displayName = 'AddToBasket'
BookmarkButton.displayName = 'BookmarkButton'

export { AddToBasket, BookingButton, BookmarkButton, Button, buttonVariants, WishlistButton }
export default Button
