'use client'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import { useRef } from 'react'
import { EffectFade, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import type { StoreHero as StoreHeroType } from '@/types'

import StoreHeroPagination from './store-hero-pagination'
import StoreHomeSlide from './store-hero-slide'

const StoreHero = ({ storeSlides, isDisabled }: StoreHeroType) => {
  const swiperRef = useRef<SwiperRef | null>(null)

  if (isDisabled) return null

  return (
    <div className="relative">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        effect="fade"
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: StoreHeroPagination,
        }}
        keyboard
        modules={[EffectFade, Navigation, Pagination, Mousewheel, Keyboard]}
        ref={swiperRef}
        className="relative"
      >
        {storeSlides?.map((item, index) => (
          <SwiperSlide key={index}>
            <StoreHomeSlide block={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination container relative !bottom-6 left-0 right-auto z-10 flex items-center pl-5 sm:pl-10 md:left-auto md:right-0 md:justify-end md:px-5 lg:!bottom-14 [&>*:nth-last-child(1)>:nth-child(2)]:hidden" />
    </div>
  )
}

export default StoreHero
