'use client'
import React from 'react'

import { CustomPortableText } from '@/components/shared/custom-portable-text'
import Button from '@/components/ui/button'
import { StoreHeroSlide } from '@/types'

import CallToAction from '../shared/call-to-action'
import Media from '../shared/media'
import CardFrame from '../ui/card-frame'

const StoreHomeSlide: React.FC<{ block?: StoreHeroSlide }> = ({ block }) => {
  const {
    announcementHeadline,
    announcementToolTip,
    backgroundMedia,
    heading,
    subHeading,
    announcementCTA,
    announcementDescription,
    announcementTitle,
    cta,
    withAnnouncementCard,
  } = block || {}

  return (
    <div className="relative">
      {backgroundMedia && (
        <div className="h-[720px] w-full md:h-[820px]">
          <Media
            data={backgroundMedia}
            cover
            className="h-full w-full object-cover [&>div>div]:z-0"
          />
        </div>
      )}
      {withAnnouncementCard && (
        <div className="absolute inset-0 flex h-full w-full items-end justify-center px-0 pb-[78px] sm:px-5 md:px-9 md:pb-[60px]">
          <div className="grid-container container transition-opacity delay-300 duration-300">
            <div className="relative col-span-12 col-start-1 w-full bg-beige p-3 lg:col-span-10 xl:col-span-7">
              <CardFrame type="diamond-corners-2" className="p-5 font-sans">
                {announcementHeadline && <p className="nav-text">{announcementHeadline}</p>}

                {announcementTitle && (
                  <CustomPortableText
                    value={announcementTitle}
                    paragraphClasses="flex  w-full justify-between text-justify font-sans font-bold uppercase tracking-[4.8px]  md:tracking-[6px] pr-h4"
                    className="mt-3 w-full max-w-[371px] pb-[7px]"
                  />
                )}
                {announcementToolTip && (
                  <div className="relative -right-9 left-auto mb-[15px] flex w-full justify-end">
                    <span className="text-sm items-center bg-turquoise px-3 py-[2px] text-center font-sans font-bold italic leading-1.4 text-black">
                      {announcementToolTip}
                    </span>
                  </div>
                )}
                {announcementDescription && (
                  <CustomPortableText
                    value={announcementDescription}
                    paragraphClasses="label-1 text-right"
                    className="mb-5 mt-4"
                  />
                )}
                {announcementCTA && (
                  <Button variant="tertiary" asChild className="mt-2 xl:col-start-3 xl:-col-end-1">
                    <CallToAction {...announcementCTA} />
                  </Button>
                )}
              </CardFrame>
            </div>
          </div>
        </div>
      )}
      {!withAnnouncementCard && (
        <div className="absolute inset-0 flex h-full w-full items-end justify-center px-0 pb-[78px] sm:px-5 md:px-9 md:pb-[60px]">
          <div className="xl:grid-container container transition-opacity delay-300 duration-300">
            <div className="col-start-1 col-end-11">
              {heading && <h3 className="h3 mb-2 pb-1 text-off-white">{heading}</h3>}
              {subHeading && <h4 className="h4 text-off-white">{subHeading}</h4>}
              {cta && (
                <Button variant="transparent" asChild className="mt-9 xl:col-start-3 xl:-col-end-1">
                  <CallToAction {...cta} />
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StoreHomeSlide
