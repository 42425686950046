'use client'

import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Link from 'next/link'
import { useRef, useState } from 'react'

import Media from '@/components/shared/media'
import OrnamentHeading from '@/components/shared/ornament-heading'
import useMediaQuery from '@/hooks/use-media-query'
import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import type { FoundingMythsScroller as FoundingMythsScrollerType } from '@/types'

import CursorTooltipWrapper from '../ui/cursor-tooltip-wrapper'

gsap.registerPlugin(ScrollTrigger)

const FoundingMythsScroller = ({
  myths,
  cursorTooltip,
  tooltipCaption,
  isDisabled,
}: FoundingMythsScrollerType) => {
  const [activeIdx, setActiveIdx] = useState<number>(0)

  const mediaRefs = useRef<(HTMLDivElement | null)[]>([])
  const sectionRef = useRef<HTMLDivElement | null>(null)

  const isDesktop = useMediaQuery('(min-width: 1024px)')

  useGSAP(
    () => {
      mediaRefs.current.forEach((panel, idx) => {
        if (!panel) return

        ScrollTrigger.create({
          trigger: panel,
          start: 'top center',
          end: 'bottom center',
          onEnter: () => setActiveIdx(idx),
          onEnterBack: () => setActiveIdx(idx),
          invalidateOnRefresh: true,
        })
      })
    },
    { scope: mediaRefs, dependencies: [mediaRefs] },
  )

  if (isDisabled) return null

  return (
    myths && (
      <CursorTooltipWrapper isVisible={!!cursorTooltip} text={tooltipCaption}>
        <section
          ref={sectionRef}
          className="snap-y snap-proximity transition-colors duration-500 ease-in-out"
          style={{
            backgroundColor: myths[activeIdx].color?.hex,
            color: myths[activeIdx].textColor,
          }}
        >
          <div className="grid-container container">
            {isDesktop && (
              <div className="sticky left-0 top-0 col-span-7 col-start-2 h-[116vh] w-full xl:h-[111vh]">
                {myths.map((item, index) => (
                  <div
                    key={index}
                    className="absolute bottom-0 left-0 top-1/2 h-[240px] w-full -translate-y-1/2 justify-between transition-opacity duration-500 ease-in-out"
                  >
                    <Link href={resolveHref(myths[activeIdx])}>
                      <OrnamentHeading
                        className={cn(
                          myths[activeIdx].textColor === 'light' ? 'text-white' : 'text-black',
                          activeIdx === index ? 'opacity-1 visible' : 'invisible opacity-0',
                        )}
                        style="style-3"
                        titleVariant="h1"
                        title={item.title}
                        subtitle={'Founding Myths'}
                        excerpt={item.excerpt}
                        isReadMoreVisible
                      />
                    </Link>
                  </div>
                ))}
              </div>
            )}
            <div className="col-span-20 flex w-full flex-col gap-32 py-[5vh] lg:col-span-9 lg:col-start-10 lg:gap-[20vh] lg:pb-[40vh] lg:pt-[31vh]">
              {myths.map((item, index) => (
                <div
                  key={index}
                  ref={(el) => {
                    mediaRefs.current[index] = el
                  }}
                  className="flex h-[70vh] w-full snap-center flex-col justify-center gap-8 lg:h-[50vh]"
                >
                  {!isDesktop && (
                    <Link href={resolveHref(item)}>
                      <OrnamentHeading
                        className={
                          myths[activeIdx].textColor === 'light' ? 'text-white' : 'text-black'
                        }
                        style="style-3"
                        titleVariant="h1"
                        title={item.title}
                        subtitle={'Founding Myths'}
                        excerpt={item.excerpt}
                        isReadMoreVisible
                      />
                    </Link>
                  )}
                  <Link className="h-[253px] w-full lg:h-full" href={resolveHref(item)}>
                    <Media data={item.featuredMedia} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </CursorTooltipWrapper>
    )
  )
}

export default FoundingMythsScroller
