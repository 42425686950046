'use client'
import 'swiper/css'

import React, { useEffect, useRef, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import BlogCard from '@/components/shared/blog-card'
import BlogCardVertical from '@/components/shared/blog-card-vertical'
import Divider from '@/components/ui/divider'
import SliderNavigation from '@/components/ui/slider-navigation'
import { cn } from '@/lib/utils'
import type { FeatureAndList as FeatureAndListType, Post } from '@/types'

import CallToAction from '../shared/call-to-action'
import Button from '../ui/button'

interface FeatureAndListProps extends FeatureAndListType {
  className?: string
}

const FeatureAndList = ({
  title,
  subtitle,
  variant,
  posts,
  featuredPost,
  postsList,
  link,
  className,
  isDisabled,
}: FeatureAndListProps) => {
  let featured: Post
  let items: Post[]
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const headerElement = headerRef.current

    const handleResize = (entries) => {
      if (entries[0].target) {
        setHeaderHeight(entries[0].target.offsetHeight)
      }
    }

    // Create a ResizeObserver instance
    const observer = new ResizeObserver((entries) => {
      handleResize(entries)
    })

    // Observe the header element
    if (headerElement) {
      observer.observe(headerElement)
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (headerElement) {
        observer.unobserve(headerElement)
      }
    }
  }, [])

  if (variant === 'manual') {
    featured = featuredPost
    items = postsList
  } else {
    if (!posts?.length) return null
    featured = posts[0]
    items = posts.slice(1)
  }

  if (isDisabled) return null

  return (
    <section
      className={cn('mb-10 py-12 md:mb-20 md:py-16', className)}
      style={{
        ['--feature-and-list-header-height' as any]: `${headerHeight}px`,
      }}
    >
      <div className="grid-container container md:pt-[var(--feature-and-list-header-height)]">
        <div className="col-span-full md:sticky md:top-[calc(var(--header-height)+20px)] md:col-span-8 md:col-start-2 md:-mt-[var(--feature-and-list-header-height)] md:self-start">
          <div className="col-span-full pb-7 md:col-start-2" ref={headerRef}>
            {subtitle && <p className="h3 mb-2.5">{subtitle}</p>}
            {title && <h2 className="h5">{title}</h2>}
          </div>
          {featured && (
            <BlogCard {...featured} showExcerpt={true} className="max-w-sm md:max-w-none" />
          )}
        </div>

        <Divider
          type="scalable-line-diamonds-vertical"
          className="relative hidden h-full md:col-span-2 md:col-start-10 md:flex md:pb-12"
        />
        {items && (
          <div className="col-span-full hidden md:col-span-8 md:col-start-12 md:mb-[150px] md:block">
            {items.map((post, index) => (
              <BlogCardVertical
                {...post}
                showExcerpt={true}
                showTags={true}
                variant={'horizontal'}
                key={index}
              />
            ))}
            {link && (
              <Button variant="secondary" asChild>
                <CallToAction
                  linkType="internal"
                  linkInternal={link}
                  _type="callToAction"
                  label="View more"
                />
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="container overflow-hidden md:hidden">
        <Divider type="scalable-line-diamonds" className="my-6 flex w-full" />
        <Swiper
          modules={[Navigation]}
          slidesPerView="auto"
          spaceBetween={20}
          loop
          className="[&&]:overflow-visible"
        >
          {items.map((post, index) => (
            <SwiperSlide key={index} className="max-w-sm [&&]:w-[75vw]">
              {/* <BlogCardVertical {...post} showExcerpt={true} showTags={true} /> */}
              <BlogCard {...post} showExcerpt={true} className="max-w-sm md:max-w-none" />
            </SwiperSlide>
          ))}
          <SliderNavigation type="prev" className="xl:-translate-x-full" />
          <SliderNavigation type="next" className="xl:translate-x-full" />
        </Swiper>
      </div>
    </section>
  )
}

export default FeatureAndList
