'use client'

import { useEffect, useRef, useState } from 'react'

import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import type { FullWidthGuideFeature } from '@/types'

import Media from '../shared/media'
import SideLink from '../shared/side-link'

const FullWidthGuideFeature = ({
  media,
  tag,
  ctaTitle,
  ctaSubtitle,
  cta,
  quote,
  isDisabled,
}: FullWidthGuideFeature) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)

  const isModuleVisible = useIsOnscreen({
    ref: sectionRef,
    rootMargin: '0px 0px -60% 0px',
    once: false,
  })
  const isInfoVisible = useIsOnscreen({
    ref: infoRef,
    rootMargin: '0px 0px -15% 0px',
    once: false,
  })

  if (isDisabled) return null

  return (
    <section
      ref={sectionRef}
      className="relative h-[calc(100vh-var(--header-height))] overflow-hidden"
    >
      <Media
        data={media}
        cover
        className={cn(
          'h-full opacity-0 transition-opacity duration-1000 ease-out after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[#000]/30',
          {
            'opacity-100': isModuleVisible,
          },
        )}
      />
      <div className="container absolute inset-0 z-10 flex h-full items-center justify-center text-center">
        <div
          className={cn(
            'first-title mx-auto flex max-w-[95vw] flex-col text-center transition-colors duration-1000 ease-out lg:max-w-[65vw]',
            isModuleVisible ? 'text-beige' : 'text-black',
          )}
        >
          {tag && <p className="tag mb-4">{tag}</p>}
          {quote && <h2>{quote}</h2>}
        </div>
      </div>
      <div
        className="absolute bottom-0 right-0 z-20 flex w-[100vw] justify-end py-28"
        ref={infoRef}
      >
        <div
          className={cn(
            `featured-story group z-20 h-[104px] w-[360px] bg-beige p-2 transition-transform duration-700 ease-out md:bottom-60`,
            isInfoVisible ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <SideLink title={ctaTitle} subtitle={ctaSubtitle} cta={cta} />
        </div>
      </div>
    </section>
  )
}

export default FullWidthGuideFeature
