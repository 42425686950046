import Link from 'next/link'

import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import { Post } from '@/types'

import { BookmarkButton } from '../ui/button'
import Media from './media'

interface BlogCardVerticalProps extends Omit<Post, '_type'> {
  _type: 'post' | 'myth' | 'event'
  variant?: 'horizontal' | 'vertical'
  className?: string
  showExcerpt?: boolean
  showTags?: boolean
}

const BlogCardVertical = ({
  _type,
  featuredMedia,
  title,
  category,
  slug,
  variant,
  excerpt,
  className,
  showExcerpt,
  showTags,
}: BlogCardVerticalProps) => {
  const tag = _type === 'myth' ? 'Founding myth' : _type === 'event' ? 'event' : category

  return (
    <Link
      href={resolveHref({
        _type,
        slug,
        category,
      })}
      className={cn(
        `group mb-[36px] grid grid-cols-1 gap-[30px] transition-opacity duration-300 md:grid-cols-2`,
        className,
      )}
    >
      <div className="relative">
        {/* <BookmarkButton className="absolute right-[7px] top-2 z-20 opacity-0 transition-opacity duration-200 group-hover:opacity-100" /> */}
        <Media
          data={featuredMedia}
          aspectRatio="4/3"
          className={`${variant == 'horizontal' && 'mr-[24px]'}`}
        />
      </div>

      <div className="flex flex-col justify-end">
        {tag && showTags && <p className="tag mb-2">{tag}</p>}
        {title && (
          <h4 className="leading-21 mb-2 font-serif text-[21px]">
            <span className="hover-underline group-hover-underline">{title}</span>
          </h4>
        )}
        {showExcerpt && excerpt && (
          <p className="text-sm text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap lg:overflow-visible lg:whitespace-normal">
            {excerpt}
          </p>
        )}
      </div>
    </Link>
  )
}

export default BlogCardVertical
