import { cn } from '@/lib/utils'

type CardFrameProps = {
  children: React.ReactNode
  type:
    | 'single-line'
    | 'double-line'
    | 'triple-line'
    | 'diamond-corners'
    | 'diamond-corners-2'
    | 'single-line-full'
  className?: string
}

const CardFrame = ({ children, type = 'single-line', className }: CardFrameProps) => {
  if (type === 'single-line') {
    return (
      <div className={`relative ${className}`}>
        <div className="absolute left-1.5 right-1.5 top-0 h-[3px] bg-black"></div>
        <div className="absolute bottom-0 left-1.5 right-1.5 h-[3px] bg-black"></div>
        <div className="absolute bottom-0 left-0 top-0 w-[3px] bg-black"></div>
        <div className="absolute bottom-0 right-0 top-0 w-[3px] bg-black"></div>
        {children}
      </div>
    )
  }

  if (type === 'double-line') {
    return (
      <div
        className={cn(
          'relative border-2 border-current before:pointer-events-none before:absolute before:inset-1 before:border',
          className,
        )}
      >
        {children}
      </div>
    )
  }

  if (type === 'single-line-full') {
    return (
      <div
        className={cn(
          'relative before:pointer-events-none before:absolute before:inset-0 before:border-2 before:border-current',
          className,
        )}
      >
        {children}
      </div>
    )
  }

  if (type === 'diamond-corners') {
    return (
      <div
        className={cn(
          'relative border-2 border-current before:pointer-events-none before:absolute before:inset-1 before:border',
          className,
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute left-[-11px] top-[-3px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute bottom-[-3px] left-[-11px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute right-[-11px] top-[-3px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute bottom-[-3px] right-[-11px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        {children}
      </div>
    )
  }

  if (type === 'triple-line') {
    return (
      <div className={`relative ${className}`}>
        <div className="absolute left-[7px] right-[7px] top-0 flex h-1.5 items-center border-y border-black">
          <div className="h-0.5 w-full bg-black"></div>
        </div>
        <div className="absolute bottom-0 left-[7px] right-[7px] flex h-1.5 items-center border-y border-black">
          <div className="h-0.5 w-full bg-black"></div>
        </div>
        <div className="absolute bottom-0 left-0 top-0 flex w-1.5 justify-center border-x border-black">
          <div className="h-full w-0.5 bg-black"></div>
        </div>
        <div className="absolute bottom-0 right-0 top-0 flex w-1.5 justify-center border-x border-black">
          <div className="h-full w-0.5 bg-black"></div>
        </div>
        {children}
      </div>
    )
  }

  if (type === 'diamond-corners-2') {
    return (
      <div className={`relative ${className}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute left-[-3.5px] top-[-3.5px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute right-[-3.5px] top-[-3.5px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute bottom-[-3.5px] right-[-3.5px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          fill="none"
          className="absolute bottom-[-3.5px] left-[-3.5px]"
        >
          <path
            fill="#353839"
            d="M5.333 2.667C4.51 1.843 4 0 4 0s-.51 1.843-1.333 2.667C1.843 3.49 0 4 0 4s1.843.51 2.667 1.333C3.49 6.157 4 8 4 8s.51-1.843 1.333-2.667C6.157 4.51 8 4 8 4s-1.843-.51-2.667-1.333Z"
          />
        </svg>

        <div className="relative z-[1] before:absolute before:bottom-1.5 before:left-0 before:right-0 before:top-1.5 before:border-x-[0.5px] after:absolute after:bottom-0 after:left-1.5 after:right-1.5 after:top-0 after:z-[0] after:border-y-[0.5px]">
          {children}
        </div>
      </div>
    )
  }

  return null
}

export default CardFrame
