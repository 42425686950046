const plugin = require('tailwindcss/plugin')
const colors = require('tailwindcss/colors')
const defaultTheme = require('tailwindcss/defaultTheme')

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./app/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    colors: {
      'default-bg-color': 'var(--default-bg-color)',
      'default-text-color': 'var(--default-text-color)',
      'default-intro-color': 'var(--default-intro-color)',
      'default-primary-button-text': 'var(--default-primary-button-text)',
      'default-primary-button-border': 'var(--default-primary-button-border)',
      'default-primary-button-bg': 'var(--default-primary-button-bg)',
      'default-secondary-button-bg': 'var(--default-secondary-button-bg)',
      'default-secondary-button-border': 'var(--default-secondary-button-border)',
      'default-secondary-button-hover-border': 'var(--default-secondary-button-hover-border)',
      'default-secondary-button-text': 'var(--default-secondary-button-text)',
      'default-secondary-button-after-hover-border':
        'var(--default-secondary-button-after-hover-border)',
      white: 'rgba(255, 255, 255, 1)',
      black: 'rgba(53, 56, 57, 1)',
      'deep-black': 'rgba(0, 0, 0, 1)',
      beige: {
        DEFAULT: 'rgba(240, 236, 224, 1)',
        dark: 'rgba(196, 191, 175, 1)',
        medium: 'rgba(196, 191, 175, 0.50)',
        light: 'rgba(196, 191, 175, 0.3)',
      },
      'off-white': 'rgba(255, 253, 249, 1)',
      orange: 'rgba(225, 168, 148, 1)',
      blue: 'rgba(54, 83, 114, 1)',
      red: {
        DEFAULT: 'rgba(195, 46, 47, 1)',
        light: 'rgba(231, 58, 58, 1)',
        birmingham: 'rgba(196, 62, 64, 1)',
        'birmingham-border': 'rgba(125, 35, 32, 1)',
        'kings-cross': 'rgba(180, 64, 65, 1)',
        battersea: '#DF6F5B',
        menu: 'rgba(232, 89, 89, 1)',
      },
      green: {
        battersea: '#95B59F',
      },
      turquoise: 'rgba(136, 210, 192, 1)',
      'pr-yellow': 'rgba(249, 224, 131, 1)',
      'kensington-peach': 'rgba(244, 166, 162, 1)',
      'battersea-orange': 'rgba(223, 111, 91, 1)',
      'battersea-yellow': 'rgba(238, 204, 147, 1)',
      neutral: colors.neutral,
      'canary-wharf-red': 'rgba(149, 43, 37, 1)',
      'almost-black': 'rgba(21, 21, 21, 1)',
      current: 'currentColor',
      transparent: 'transparent',
      'gray-light': 'rgba(196, 191, 175, 0.5)',
      'gray-medium': '#7f7f7f',
      'blue-light': '#365372',
      'red-kings-cross': '#B44041',
      'shoreditch-yellow': '#F3B85B',
      'kings-cross-brown': '#5A2E29',
      'manchester-blue': '#3D9CA8',
      'light-red': 'rgba(156, 48, 35, 1)',
      'carnaby-red': '#B04F43',
    },

    fontFamily: {
      sans: ['var(--font-gill-sans)', ...defaultTheme.fontFamily.sans],
      'sans-std-cond': ['var(--font-gill-sans-std-cond)'],
      serif: ['var(--font-cheltenham-bt)', ...defaultTheme.fontFamily.serif],
      italic: ['var(--font-cheltenham-lt)'],
      antique: ['antique-olive'],
      futura: ['futura-pt'],
      futuracond: ['futura-pt-condensed'],
      dubba: ['var(--font-dubba-dubba-nf)'],
      acme: ['Acme Gothic'],
      acmeextrawide: ['Acme Gothic Extrawide'],
      beton: ['var(--font-beton-lt)'],
      railroad: ['railroad-gothic-atf'],
      caslon: ['big-caslon-fb'],
      battersea: ['var(--font-dishoom-battersea)'],
      gothic: ['acme-gothic'],
      gothicextrawide: ['acme-gothic-extrawide'],
      bebas: ['var(--font-bebas-neue)'],
      grotesque: ['monotype-grotesque'],
      grotesueextended: ['monotype-grotesque-extended'],
      neutra: ['var(--font-neutra)'],
      modern: ['var(--font-modern-mt-std)'],
      baskerville: ['baskerville-urw'],
      elza: ['elza-condensed'],
      bookman: ['var(--font-bookman-jfpro)'],
    },
    fontSize: {
      base: '1rem', // 16px
      10: '0.6rem', // 10px
      11: '0.688rem', // 11px
      12: '0.75rem', // 12px
      13: '0.8125rem', // 13px
      14: '0.875rem', // 14px
      15: '0.9375rem', // 15px
      16: '1.00rem', // 16px
      17: '1.063rem', // 17px
      18: '1.125rem', // 18px
      20: '1.25rem', // 20px
      21: '1.3125rem', // 21px
      22: '1.375rem', // 22px
      24: '1.5rem', // 24px
      25: '1.5625rem', // 25px
      27: '1.6875rem', // 27px
      30: '1.875rem', // 30px
      32: '2rem', // 32px
      36: '2.25rem', // 36px
      40: '2.5rem', // 40px
      42: '2.625rem', // 42px
      44: '2.75rem', // 44px
      52: '3.25rem', // 52px
      58: '3.625rem', // 58px
      60: '3.75rem', // 60px
      clamp: 'clamp(20px, 5.5vw, 6rem)',
    },
    letterSpacing: {
      normal: '0',
      1: '0.01em', // 1%
      2: '0.02em', // 2%
      5: '0.05em', // 5%
      10: '0.1em', // 10%
      12: '0.12em', // 12%
      15: '0.15em', // 15%
      20: '0.2em', // 20%
      25: '0.25em', // 25%
    },
    container: {
      center: true,
      padding: {
        DEFAULT: 'var(--container-padding)',
      },
      screens: {
        '2xl': '1536px',
      },
    },
    aspectRatio: {
      auto: 'auto',
      square: '1 / 1',
      video: '16 / 9',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
      14: '14',
      15: '15',
      16: '16',
    },
    extend: {
      lineHeight: {
        'extra-tight': '1.15',
        snug: '1.33',
        1.2: '1.2',
        1.3: '1.3',
        1.4: '1.4',
        44: '2.75rem',
        48: '3rem',
        52: '3.25rem',
        59: '3.688rem',
        90: '5.625rem',
      },
      gridTemplateColumns: {
        header: '1fr max-content 1fr',
        'ui-kit': 'minmax(min-content, 30vw) 1fr',
        18: 'repeat(18, minmax(0, 1fr))',
        20: 'repeat(20, minmax(0, 1fr))',
      },
      gridRowEnd: {
        '-1': '-1',
      },
      gridTemplateRows: {
        'animate-height-open': '1fr',
        'animate-height-closed': '0fr',
      },
      gridColumnStart: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
        '-1': '-1',
      },
      gridColumnEnd: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
        '-1': '-1',
      },
      gridColumn: {
        'span-13': 'span 13 / span 13',
        'span-14': 'span 14 / span 14',
        'span-15': 'span 15 / span 15',
        'span-16': 'span 16 / span 16',
        'span-17': 'span 17 / span 17',
        'span-18': 'span 18 / span 18',
        'span-19': 'span 19 / span 19',
        'span-20': 'span 20 / span 20',
      },
      transitionProperty: {
        width: 'width, transform',
        opacity: 'opacity, visibility',
        right: 'right',
      },
      transitionDuration: {
        slider: '1500ms',
        reveal: '1000ms',
      },
      zIndex: {
        content: '2',
        footer: '2',
        chat: '3',
        'home-hero': '4',
        'audio-player': '5',
        'header-nav': '6',
        header: '7',
        booking: '20',
        cart: '20',
        'disable-preview': '100',
      },
      width: {
        'full-without-scrollbar': 'calc(100% - var(--removed-body-scroll-bar-size, 0px))',
      },
      height: {
        's-screen': ['100vh', '100svh'],
        'l-screen': ['100vh', '100lvh'],
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        'rotate-slightly': {
          '0%': { transform: 'rotate(0deg)' },
          '50%': { transform: 'rotate(-4deg)' },
          '100%': { transform: 'rotate(0deg)' },
        },
        'rotate-slightly-oposite': {
          '0%': { transform: 'rotate(0deg)' },
          '50%': { transform: 'rotate(4deg)' },
          '100%': { transform: 'rotate(0deg)' },
        },
        'cheff-walla-covent-garden-lower': {
          '0%': { height: 'calc(100% - 66px)' },
          '20%': { height: 'calc(100% - 81px)' },
          '100%': { height: 'calc(100% - 66px)' },
        },
        'cheff-walla-covent-garden': {
          '0%': { height: '100%' },
          '20%': { height: 'calc(100% - 15px)' },
          '100%': { height: '100%' },
        },
        'food-covent-garden-1': {
          '0%': { height: '33px' },
          '25%': { height: '27px' },
          '50%': { height: '17px' },
          '75%': { height: '27px' },
          '100%': { height: '33px' },
        },
        'food-covent-garden-1-two': {
          '0%': { height: '44px' },
          '25%': { height: '37px' },
          '50%': { height: '27px' },
          '75%': { height: '37px' },
          '100%': { height: '44px' },
        },
        'food-covent-garden-2': {
          '0%': { height: '27px' },
          '25%': { height: '20px' },
          '50%': { height: '30px' },
          '75%': { height: '37px' },
          '85%': { height: '44px' },
          '100%': { height: '35px' },
        },
        'food-covent-garden-2-two': {
          '0%': { height: '17px' },
          '25%': { height: '10px' },
          '50%': { height: '20px' },
          '70%': { height: '27px' },
          '85%': { height: '34px' },
          '100%': { height: '25px' },
        },
        'food-covent-garden-4': {
          '0%': { height: '28px' },
          '50%': { height: '36px' },
          '100%': { height: '28px' },
        },
        'food-covent-garden-4-two': {
          '0%': { height: '17px' },
          '50%': { height: '25px' },
          '100%': { height: '17px' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'spin-slow': 'spin 5s linear infinite',
        'rotate-slightly': 'rotate-slightly 2s infinite',
        'rotate-slightly-oposite': 'rotate-slightly-oposite 2s infinite',
        'cheff-walla-covent-garden': 'cheff-walla-covent-garden 3s ease-in-out infinite',
        'cheff-walla-covent-garden-lower':
          'cheff-walla-covent-garden-lower 3s ease-in-out infinite',
        'food-covent-garden-1': 'food-covent-garden-1 2s ease-in-out infinite',
        'food-covent-garden-1-two': 'food-covent-garden-1-two 2s ease-in-out infinite',
        'food-covent-garden-2': 'food-covent-garden-2 1.5s ease-in-out infinite',
        'food-covent-garden-2-two': 'food-covent-garden-2-two 1.5s ease-in-out infinite',
        'food-covent-garden-3': 'food-covent-garden-2-two 2s ease-in-out infinite',
        'food-covent-garden-3-two': 'food-covent-garden-2 2s ease-in-out infinite',
        'food-covent-garden-4': 'food-covent-garden-4 3s ease-in-out infinite',
        'food-covent-garden-4-two': 'food-covent-garden-4-two 3s ease-in-out infinite',
      },
      spacing: {
        56.25: '56.25vw',
        '1em': '1em',
        '60px': '60px',
        '1px': '1px',
        container: 'var(--container-padding)',
        'header-mobile-bottom-bar-height': 'var(--header-mobile-bottom-bar-height)',
        'header-height': 'var(--header-height)',
        'anchor-links-height': 'var(--anchor-links-height)',
        'container-with-padding': 'calc(100% + var(--container-padding) * 2)',
        800: '800px',
        85: '85%',
      },
      borderWidth: {
        3: '3px',
      },
      screens: {
        'small-phone': { raw: '(max-height: 700px) and (max-width: 380px)' },
        'short-desktop': { raw: '(max-height: 800px) and (min-width: 1280px)' },
        print: { raw: 'print' },
      },
      boxShadow: {
        birmingham: '1.5px 1.5px 0px 0px rgba(53, 56, 57, 1)',
        'birmingham-red': '1.5px 1.5px 0px 0px rgba(125, 35, 32, 1)',
        'birmingham-after': '3px 3px 0px 1px rgba(53, 56, 57, 1)',
        'birmingham-after-red': '3px 3px 0px 1px rgba(125, 35, 32, 1)',
        'birmingham-before': '4.5px 4.5px 0px 1px rgba(53, 56, 57, 1)',
        'birmingham-before-red': '4.5px 4.5px 0px 1px rgba(125, 35, 32, 1)',
        'button-shadow':
          ' -4px 4px 0px 0px #353839, -3px 3px 0px 0px #353839, -2px 2px 0px 0px #353839, -1px 1px 0px 0px #353839',
      },
      dropShadow: {
        battersea: '-8.82px 8.82px #000',
        'battersea-md': '-4px 4px #000000',
        'button-shadow':
          ' -4px 4px 0px 0px #353839, -3px 3px 0px 0px #353839, -2px 2px 0px 0px #353839, -1px 1px 0px 0px #353839',
      },
      backgroundImage: {
        collaboratorGradient:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)',
        partialListGradient: 'linear-gradient(180deg, rgba(210, 201, 173, 0.00) 0%, #D2C9AD 71%)',
      },
    },
  },
  future: {
    hoverOnlyWhenSupported: true,
  },
  variants: {
    aspectRatio: ['responsive', 'hover'],
  },
  plugins: [
    require('tailwind-scrollbar-hide'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/container-queries'),
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          'animation-delay': (value) => {
            return {
              'animation-delay': value,
            }
          },
        },
        {
          values: theme('transitionDelay'),
        },
      )
    }),
    // Hover media queries
    plugin(({ addVariant }) => {
      addVariant('has-hover', '@media (hover: hover)')
      addVariant('no-hover', '@media (hover: none)')
    }),
    require('tailwindcss-animate'),
  ],
}
