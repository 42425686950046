'use client'

// import { ChevronLeft, ChevronRight } from 'lucide-react'

import * as React from 'react'
import { DayPicker } from 'react-day-picker'

import { cn } from '@/lib/utils'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  // const defaultClassNames = getDefaultClassNames()

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('max-w-sm: datepicker p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-y-0',
        month: 'space-y-3 text-center',
        caption_label: 'text-center body flex items-center gap-1',
        nav: 'space-x-1 flex items-center',
        button_next: 'absolute bottom-1 right-[40%] disabled:opacity-50',
        button_previous: 'absolute bottom-1 left-[40%] disabled:opacity-50',
        day: cn(
          'h-9 w-9 p-0 text-center font-normal aria-selected:bg-black aria-selected:text-white',
        ),
        weekday: 'text-center',
        today:
          'relative before:content-[""] before:absolute before:rounded-full before:bg-black before:h-[5px] before:w-[5px] before:left-[43%] before:bottom-0',
        dropdown: `absolute opacity-0 z-10 appearance-none inset-0 w-full m-0 p-0 border-none`,
        dropdown_root: `relative inline-flex items-center`,
        dropdowns: `flex justify-start gap-4`,
        chevron: ``,
        months_dropdown: ``,
        years_dropdown: ``,
        day_button: '',
        disabled: 'opacity-50',
        focused: '',
        footer: '',
        hidden: '',
        month_caption: '',
        month_grid: '',
        outside: '',
        range_end: '',
        range_middle: '',
        range_start: '',
        root: '',
        selected: '',
        week: '',
        week_number: '',
        week_number_header: '',
        weekdays: '',
        weeks: '',

        ...classNames,
      }}
      // components={
      // {
      // IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
      // IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
      // }
      // }
      // initialFocus
      {...props}
      hideNavigation
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
