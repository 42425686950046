'use client'

import { useRef } from 'react'

import OrnamentHeading from '@/components/shared/ornament-heading'
import VideoControlled, { VideoControlledRef } from '@/components/shared/video-controlled'
import { VideosBlock as VideosBlockType } from '@/types'

const VideosBlock = ({ tag, title, copy, videos, isDisabled }: VideosBlockType) => {
  const videoRefs = useRef<(VideoControlledRef | null)[]>([])

  const onPlayHandler = (event) => {
    const curVideo = event.target.media.nativeEl

    videoRefs.current.forEach((videoRef) => {
      const video = videoRef?.current
      if (video && video !== curVideo && !video.paused) {
        videoRef.pause()
      }
    })
  }

  if (isDisabled) return null

  return (
    <section className="relative bg-black py-[46px] text-white md:grid-cols-20 lg:pb-[80px] lg:pt-[90px]">
      <hr className="absolute inset-x-0 top-1 border-t-[3px] border-beige" />
      <div className="grid-container container">
        <OrnamentHeading
          className="col-span-full mb-8 lg:col-span-10 lg:col-start-6 lg:mb-12"
          style="style-3"
          titleVariant="h1"
          title={title}
          subtitle={tag}
          excerpt={copy}
          withoutTopLine
          withoutBottomLine
        />
        <div
          className="col-span-full flex gap-5 overflow-y-hidden overflow-x-scroll scrollbar-hide lg:gap-[2%]"
          onPlay={onPlayHandler}
        >
          {videos.map((item, index) => (
            <VideoControlled
              key={index}
              ref={(videoControlledRef) => {
                videoRefs.current[index] = videoControlledRef
              }}
              media={item.media}
              title={item.videoTitle}
              className="w-60 flex-shrink-0 bg-white lg:w-[23.5%]"
              buttonPosition="bottom"
              aspectRatio="videos-block"
              onPlay={onPlayHandler}
            />
          ))}
        </div>
      </div>
      {/* <hr className="absolute inset-x-0 bottom-[3px] border-t-[3px] border-beige" /> */}
    </section>
  )
}

export default VideosBlock
