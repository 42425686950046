import { cn } from '@/lib/utils'
import { ManualCard } from '@/types'

import { SanityImage } from './sanity-image'

type BenefitsCard = ManualCard & {
  className?: string
}

export const BenefitsCard = ({ title, copy, image, className }: BenefitsCard) => {
  return (
    <div className={cn('group block transition-opacity duration-300', className)}>
      <div className="mb-4 flex aspect-square items-center px-7">
        {image && <SanityImage data={image} className="w-full object-contain" />}
      </div>
      {title && <h6 className="tag mb-2 text-center">{title}</h6>}
      {copy && <p className="body text-center">{copy}</p>}
    </div>
  )
}
