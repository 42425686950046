import { cn } from '@/lib/utils'

type TagLabelProps = {
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
}

const TagLabel = ({ className, children, ...props }: TagLabelProps) => {
  return (
    <span
      className={cn(
        `label-tag inline-block py-1 pl-3 pr-2 transition-colors duration-200 group-hover:bg-beige-dark`,
        className,
      )}
      {...props}
    >
      {children}
    </span>
  )
}

export default TagLabel
