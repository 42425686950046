'use client'
import 'swiper/css'

import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SLIDER_DURATION } from '@/lib/constants'
import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import type { FoundingMythsSlider } from '@/types'

import Media from '../shared/media'
import OrnamentHeading from '../shared/ornament-heading'
import SharedCopyBlock from '../shared/shared-copy-block'
import Divider from '../ui/divider'
import SliderNavigation from '../ui/slider-navigation'

const FoundingMythsSlider = ({ headerCopy, myths = [], isDisabled }: FoundingMythsSlider) => {
  if (!myths.length) return null

  if (isDisabled) return null

  return (
    <div className="overflow-hidden">
      <Divider type="7-line" />
      <div className="container mt-20 flex flex-col gap-14 pb-20 lg:mt-28">
        {headerCopy && <SharedCopyBlock textAlign="center" {...headerCopy} subtitleVariant="h3" />}
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          slidesPerView="auto"
          className="max-w-full [&&]:overflow-visible"
          breakpoints={{
            768: {
              spaceBetween: 30,
            },
            1024: {
              spaceBetween: 50,
            },
            1280: {
              spaceBetween: 70,
            },
          }}
          speed={SLIDER_DURATION}
          loop
        >
          {myths.map((myth, index) => {
            return (
              <SwiperSlide
                key={index}
                className={cn(
                  `max-w-[550px] lg:max-w-none [&&]:h-auto [&&]:w-[75%] [&&]:lg:w-[70%]`,
                  {
                    'text-black': myth.textColor === 'dark',
                    'text-off-white': myth.textColor === 'light',
                  },
                )}
                style={{
                  backgroundColor: myth?.color?.hex?.toUpperCase(),
                }}
              >
                <Link
                  href={resolveHref(myth)}
                  className="flex h-full flex-col justify-between gap-1 p-3 md:p-6 lg:grid lg:grid-cols-2 lg:justify-center lg:gap-10"
                >
                  <div className="aspect-h-9 aspect-w-16 relative block lg:aspect-h-4 lg:aspect-w-5">
                    <Media data={myth.featuredMedia} cover className="absolute" />
                  </div>
                  <OrnamentHeading
                    className="h-full"
                    title={myth.title}
                    style="style-3"
                    subtitle="Founding Myth"
                    excerpt={myth.excerpt}
                    isReadMoreVisible
                    isFullHeightBorders
                  />
                </Link>
              </SwiperSlide>
            )
          })}
          <span slot="container-end">
            <SliderNavigation type="prev" />
            <SliderNavigation type="next" />
          </span>
        </Swiper>
      </div>
    </div>
  )
}

export default FoundingMythsSlider
