'use client'
import clsx from 'clsx'
import React, { useState } from 'react'

import { CustomPortableText } from '@/components/shared/custom-portable-text'
import Media from '@/components/shared/media'
import OrnamentHeading from '@/components/shared/ornament-heading'
import ProductCard from '@/components/shared/product-card'
import Button from '@/components/ui/button'
import { CollectionPageGrid, Product } from '@/types'

const MobileCollectionGrid = ({
  breakoutMediaBlocks,
  product,
  breakoutTextBlocks,
  hasLargerProductCardLeft,
  hasLeft,
  hasLoadMoreButton,
}: CollectionPageGrid) => {
  const [seeMore, setSeeMore] = useState(false)
  let showAllProductas = seeMore ? product?.length : 13
  const gridProducts: Product[] = product?.slice(0, 4)
  const centerProducts: Product[] = product?.slice(4, 9)
  const normalSizeProducts: Product[] = product?.slice(9, 11)
  const largerProducts: Product[] = product?.slice(11, showAllProductas)

  return (
    <div className="my-[30px] md:hidden lg:my-[61px]">
      <div className="grid-container container mb-[60px] gap-y-6 [&>*:nth-child(1)>div>img]:h-[350px] [&>*:nth-child(1)>div>img]:md:h-[324px] [&>*:nth-child(1)]:col-start-1 [&>*:nth-child(1)]:col-end-7 [&>*:nth-child(2)]:col-start-1 [&>*:nth-child(2)]:col-end-4 [&>*:nth-child(3)]:col-start-4 [&>*:nth-child(3)]:col-end-7 [&>*:nth-child(4)]:col-start-1 [&>*:nth-child(4)]:col-end-4">
        {gridProducts?.length > 0 &&
          gridProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
      </div>
      <div className="container">
        <div className={clsx('grid-container mb-7 md:mb-[75px]')}>
          <div
            className={clsx(
              hasLeft
                ? 'order-1 col-start-1 col-end-7 md:col-start-12 md:col-end-20'
                : 'oredr-1 col-start-1 col-end-7 md:col-end-10',
            )}
          >
            {breakoutMediaBlocks && <Media data={breakoutMediaBlocks?.media} className="h-auto" />}
            {hasLeft && normalSizeProducts?.length > 0 && (
              <div className="my-[40px] grid grid-cols-2 gap-5">
                {normalSizeProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
              </div>
            )}
          </div>

          <div
            className={clsx(
              'flex flex-col-reverse md:flex-col',
              hasLeft
                ? 'order-1 col-start-1 col-end-7 md:col-end-11'
                : 'col-start-1 col-end-7 md:col-start-11 md:col-end-20',
            )}
          >
            {centerProducts?.length > 0 && (
              <div className="mt-10 grid grid-cols-2 gap-5 md:mt-0 xl:gap-6 [&>*:nth-child(1)]:col-span-2 md:[&>*:nth-child(1)]:col-span-1">
                {centerProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
              </div>
            )}
            <div className="mt-[30px] flex flex-col items-center justify-between lg:mt-[60px]">
              <OrnamentHeading className="[&>svg]:mb-0" style="style-1" />
              <div className="py-[30px] lg:py-[60px]">
                {breakoutTextBlocks?.text && (
                  <CustomPortableText
                    value={breakoutTextBlocks?.text}
                    paragraphClasses="pre-header text-center block max-w-[552px] w-full"
                  />
                )}
                {breakoutTextBlocks?.quoteSource && (
                  <span className="block pt-8 text-center font-sans text-[0.625rem] font-bold uppercase tracking-10 text-black">
                    {breakoutTextBlocks?.quoteSource}
                  </span>
                )}
              </div>
              <OrnamentHeading className="[&>svg]:mb-0" style="style-1" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className={clsx(
            'mb-[75px] gap-7',
            hasLargerProductCardLeft ? 'flex-col lg:flex-row-reverse' : 'flex-col lg:flex-row',
          )}
        >
          {largerProducts?.length > 0 && (
            <div className="grid grid-cols-2 gap-5">
              {largerProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
            </div>
          )}
        </div>
      </div>

      {hasLoadMoreButton && (
        <div className="flex items-center justify-center pb-7">
          <Button className="mx-auto" variant={'secondary'} onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'Loading less...' : 'Loading more provisions...'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default MobileCollectionGrid
