import { useState } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share'

import Icon from '@/components/ui/icon'

const SocialButtons = () => {
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)

  const onClickCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setIsLinkCopied(true)
      setTimeout(() => setIsLinkCopied(false), 1000) // Reset button opacity after 2 seconds
    })
  }

  return (
    <div className="relative flex flex-col items-center border border-gray-light bg-beige py-2 text-black">
      <div className="relative w-full cursor-pointer pl-4 pr-8">
        <div className="absolute inset-0 opacity-0" onClick={onClickCopy}>
          <Icon variant="link" />
        </div>
        {isLinkCopied && <p className="tag absolute -top-8 text-black opacity-50">copied</p>}
        <span className="nav-text-small text-gray-400 hover:text-gray-300 text-nowrap text-left">
          Copy link
        </span>
      </div>

      <div className="relative w-full cursor-pointer pl-4 pr-8">
        <EmailShareButton
          url={window.location.href}
          title="mail"
          className="absolute inset-0 opacity-0"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
        <span className="nav-text-small text-gray-400 hover:text-gray-300 text-nowrap text-left">
          Email
        </span>
      </div>

      <div className="relative w-full cursor-pointer pl-4 pr-8">
        <WhatsappShareButton
          url={window.location.href}
          title="whatsapp"
          className="absolute inset-0 opacity-0"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <span className="nav-text-small text-gray-400 hover:text-gray-300 text-nowrap text-left">
          Whatsapp
        </span>
      </div>

      <div className="absolute -right-[5px] bottom-[70%] h-2 w-2 -rotate-45 border-b border-r border-gray-light bg-beige md:-bottom-[5px] md:left-[34%] md:rotate-45" />
    </div>
  )
}

export default SocialButtons
