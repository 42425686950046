import { cn } from '@/lib/utils'
import type { CtaBanner } from '@/types'

import CallToAction from '../shared/call-to-action'
import Media from '../shared/media'
import { Button } from '../ui/button'
import CardFrame from '../ui/card-frame'

interface CtaBannerProps extends CtaBanner {
  className?: string
}

const CtaBanner = ({
  media,
  subtitle,
  title,
  copy,
  className,
  cta,
  isDisabled,
}: CtaBannerProps) => {
  if (isDisabled) return null

  return (
    <div
      className={cn(
        'container pb-14 md:grid md:grid-flow-dense md:grid-cols-20 md:items-center md:pb-28',
        className,
      )}
    >
      <Media
        data={media}
        className="-ml-[var(--container-padding)] w-[calc(100%+var(--container-padding)*2)] md:col-span-12 md:col-start-1 md:row-start-1 md:row-end-2 md:ml-0 md:w-full"
        captionClassName="hidden md:inline-flex"
      />
      <CardFrame
        type="double-line"
        className="mobile-container relative z-[1] -mt-20 bg-default-bg-color p-5 text-default-text-color md:col-span-10 md:col-start-11 md:row-start-1 md:row-end-2 md:mt-0 md:px-8"
      >
        {subtitle && <p className="tag mb-3">{subtitle}</p>}
        {!!title && <h2 className="h2 text-justify">{title}</h2>}
        {!!copy && <p className="paragraph pt-4 text-right">{copy}</p>}

        {cta && (
          <Button className="mt-9 md:mt-6 lg:mt-9" variant="secondary" asChild>
            <CallToAction {...cta} />
          </Button>
        )}
      </CardFrame>
    </div>
  )
}

export default CtaBanner
