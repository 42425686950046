import * as Portal from '@radix-ui/react-portal'
import React, { useEffect, useState } from 'react'

type CursorTooltipProps = {
  text: string
  isVisible: boolean
}

// Throttle function to limit how often tooltip updates
const throttle = (callback: Function, limit: number) => {
  let waiting = false
  return (...args: any[]) => {
    if (!waiting) {
      callback(...args)
      waiting = true
      setTimeout(() => {
        waiting = false
      }, limit)
    }
  }
}

const CursorTooltip = ({ text, isVisible }: CursorTooltipProps) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleScroll = throttle((e: WheelEvent) => {
      setPosition({
        x: e.pageX + e.deltaX + 20,
        y: e.pageY + e.deltaY + 15,
      })
    }, 20)

    const handleMouseMove = throttle((e: MouseEvent) => {
      setPosition({
        x: e.pageX + 20,
        y: e.pageY + 15,
      })
    }, 20)

    window.addEventListener('wheel', handleScroll)
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('wheel', handleScroll)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <Portal.Root>
      <div
        className={`text-sm z-50 transform cursor-pointer bg-beige-dark px-3 py-2 text-center font-sans font-bold capitalize tracking-2 text-black transition-opacity duration-300 ease-in-out lg:absolute ${
          isVisible ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
        }`}
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
          transition: 'top 0.1s , left 0.1s ',
        }}
      >
        {text}
      </div>
    </Portal.Root>
  )
}

export default CursorTooltip
