'use client'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'

import { cn } from '@/lib/utils'
import type { PlaylistModule } from '@/types'

import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'
import OrnamentHeading from '../shared/ornament-heading'
import { SanityImage } from '../shared/sanity-image'
import Button from '../ui/button'

const PlaylistModule = ({
  heading,
  backgroundMedia,
  moduleHeading,
  modulePreHeading,
  playlistTrack,
  subHeading,
  isDisabled,
}: PlaylistModule) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % (playlistTrack?.length || 1)
      setIsPlaying(true) // Start playing when navigating
      return newIndex
    })
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? (playlistTrack?.length || 1) - 1 : prevIndex - 1
      setIsPlaying(true) // Start playing when navigating
      return newIndex
    })
  }

  const handleTrackClick = (index: number) => {
    if (index === currentIndex) {
      // Toggle play/pause if the same track is clicked
      setIsPlaying((prev) => !prev)
    } else {
      // Switch to the new track and start playing
      setCurrentIndex(index)
      setIsPlaying(true)
    }
  }

  useEffect(() => {
    if (listRef.current) {
      const activeElement = listRef.current.querySelector('.active') as HTMLElement
      if (activeElement) {
        listRef.current.scrollTo({
          top: activeElement.offsetTop - listRef.current.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }, [currentIndex])

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch((error) => console.error('Error playing audio:', error))
      } else {
        audioRef.current.pause()
      }
    }
  }, [isPlaying, currentIndex])

  const currentItem = playlistTrack ? playlistTrack[currentIndex] : null

  if (isDisabled) return null

  return (
    <div className="my-[50px] md:my-[60px]">
      <div className="grid-container container">
        <div className="col-span-6 col-start-1 sm:col-span-4 sm:col-start-2 md:col-span-10 md:col-start-6 lg:col-span-8 lg:col-start-7">
          <OrnamentHeading
            className="text-black"
            title={moduleHeading}
            style="style-1"
            subtitle={modulePreHeading}
          />
        </div>
        <div className="relative col-span-6 col-start-1 mt-[30px] overflow-hidden md:col-span-20 md:mt-[58px]">
          {backgroundMedia && (
            <Media
              data={backgroundMedia}
              className="h-full min-h-[600px] w-full object-cover sm:h-[700px] lg:h-[533px]"
            />
          )}
          <div className="absolute bottom-0 z-10 flex h-full w-full flex-col justify-between gap-8 px-5 md:items-end lg:flex-row lg:px-10">
            <div
              ref={listRef}
              className="relative flex w-full max-w-full flex-col scrollbar-hide lg:h-full lg:max-w-[668px] lg:overflow-y-auto"
            >
              <div className="absolute top-6 lg:bottom-[-49%] lg:top-auto lg:h-full">
                {heading && <h3 className="h3 mb-2 text-off-white">{heading}</h3>}
                {subHeading && (
                  <CustomPortableText
                    value={subHeading}
                    paragraphClasses="[&]:h4 !mt-0 !text-off-white w-full max-w-[668px]"
                  />
                )}
                <div className="ml-0 mt-7 hidden flex-col gap-4 pb-10 lg:flex">
                  {playlistTrack?.map((item, index) => {
                    const {} = item || {}
                    return (
                      <div
                        key={index}
                        className={cn(
                          'BasicTransition flex w-full justify-start gap-4 p-2.5',
                          isPlaying && index === currentIndex
                            ? 'active max-w-[380px] bg-[#F0ECE0]'
                            : 'max-w-[347px] bg-[#fffdf9cc]',
                        )}
                        onClick={() => handleTrackClick(index)}
                      >
                        <div className="relative">
                          {index === currentIndex && (
                            <audio
                              ref={audioRef}
                              src={item?.audio?.url ? item?.audio?.url : ''}
                              className="hidden"
                              controls
                            ></audio>
                          )}
                          <div
                            className={cn(
                              'absolute left-[50%] top-[50%] z-[1] translate-x-[-50%] translate-y-[-50%]',
                            )}
                          >
                            <Image
                              src={
                                isPlaying && index === currentIndex
                                  ? ' /assets/icons/pause.svg'
                                  : '/assets/icons/play-in-circle.svg'
                              }
                              width={30}
                              height={30}
                              alt="Play/Pause Icon"
                              onClick={(e) => {
                                e.stopPropagation() // Prevent triggering parent onClick
                                setIsPlaying((prev) => !prev)
                              }}
                            />
                          </div>
                          {item?.thumbnailImage && (
                            <SanityImage
                              data={item?.thumbnailImage}
                              className="h-[48] w-[48px] object-cover"
                            />
                          )}
                        </div>
                        <div>
                          {item?.trackName && (
                            <p className="font-sans text-base font-bold text-black">
                              {item.trackName}
                            </p>
                          )}
                          {item?.artistName && (
                            <span className="font-sans text-base tracking-[0.32px] text-black">
                              {item.artistName}
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="mb-6 flex w-full max-w-full flex-col justify-start gap-4 lg:mb-10 lg:max-w-[600px] lg:justify-end">
              <h3 className="h3 mb-0 block text-[12px] text-off-white lg:hidden">Now playing</h3>

              <div className="ml-0 flex flex-col gap-0 pb-0 lg:mt-7 lg:hidden lg:pb-10">
                {currentItem && (
                  <div
                    className="flex w-full max-w-[347px] justify-start gap-4 bg-[#F0ECE0] p-2.5 opacity-100"
                    onClick={() => setIsPlaying((prev) => !prev)}
                  >
                    <div className="relative">
                      {currentItem?.thumbnailImage && (
                        <SanityImage
                          data={currentItem?.thumbnailImage}
                          className="h-[48] w-[48px] object-cover"
                        />
                      )}
                      <div
                        className={cn(
                          'absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]',
                        )}
                      >
                        <Image
                          src={
                            isPlaying
                              ? ' /assets/icons/pause.svg'
                              : '/assets/icons/play-in-circle.svg'
                          }
                          width={30}
                          height={30}
                          alt="Play/Pause Icon"
                        />
                      </div>
                    </div>
                    <div>
                      {currentItem?.trackName && (
                        <p className="font-sans text-[0.875rem] font-bold text-black sm:text-[1rem]">
                          {currentItem.trackName}
                        </p>
                      )}
                      {currentItem?.artistName && (
                        <span className="font-sans text-[0.875rem] tracking-[0.32px] text-black sm:text-[1rem]">
                          {currentItem.artistName}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex w-full flex-row justify-start gap-2 lg:justify-end lg:gap-4">
                <Button
                  onClick={handlePrev}
                  variant="transparent"
                  className="whitespace-nowrap px-4 md:px-8"
                  disabled={currentIndex === 0}
                >
                  Prev
                </Button>

                <Button
                  variant="transparent"
                  className="block whitespace-nowrap px-4 md:px-8 lg:hidden"
                >
                  Purchase
                </Button>

                <Button
                  onClick={handleNext}
                  variant="transparent"
                  className="whitespace-nowrap px-4 md:px-8"
                  disabled={currentIndex === (playlistTrack?.length || 0) - 1}
                >
                  Next
                </Button>

                <Button
                  variant="transparent"
                  className="hidden whitespace-nowrap px-4 md:px-8 lg:block"
                >
                  Purchase the record
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlaylistModule
