import { cn } from '@/lib/utils'
import { CallToActionType } from '@/types'

import CallToAction from '../shared/call-to-action'
import Button from './button'
import CardFrame from './card-frame'

type FramedCardProps = {
  className?: string
  subtitle?: string
  titleWordsGroup: string[][]
  copy?: string
  cta?: CallToActionType
}

const FramedCard = ({ className, subtitle, titleWordsGroup, copy, cta }: FramedCardProps) => {
  return (
    <div className={cn('w-full bg-default-bg-color p-3 sm:w-fit', className)}>
      <CardFrame type="diamond-corners-2" className="[&_path]:fill-default-text-color">
        <div className="flex flex-col p-5">
          {subtitle && <p className="nav-text">{subtitle}</p>}
          <div className="mt-3 md:w-auto md:min-w-[371px]">
            {titleWordsGroup.map((wordPair, index) => (
              <p
                key={index}
                className="flex w-full flex-wrap justify-between text-justify font-sans text-[24px] font-bold uppercase leading-7 tracking-[4.8px] md:text-[30px] md:leading-9 md:tracking-[6px]"
              >
                {wordPair.map((word, index) => (
                  <span key={index} className={`flex ${index % 2 === 0 && 'justify-start pr-4'}`}>
                    {word}
                  </span>
                ))}
              </p>
            ))}
          </div>
          {copy && (
            <div className="relative mb-5 mt-4 h-12">
              <span className="label-1 absolute right-0 top-0 text-right">{copy}</span>
            </div>
          )}
          {cta && (
            <Button variant="tertiary" className="z-[2] w-fit" asChild>
              <CallToAction {...cta} />
            </Button>
          )}
        </div>
      </CardFrame>
    </div>
  )
}

export default FramedCard
