'use client'
import Image from 'next/image'
import React, { useState } from 'react'

import { cn } from '@/lib/utils'
import type { LifestyleShoppingModule } from '@/types'

import Media from '../shared/media'

const LifestyleShoppingModule = ({
  backgroundMedia,
  heading,
  hotspotFour,
  hotspotOne,
  hotspotThree,
  hotspotTwo,
  subHeading,
  numberOfHotspots,
}: LifestyleShoppingModule) => {
  const [visibleHotspot, setVisibleHotspot] = useState<number | null>(null)

  const toggleHotspotVisibility = (hotspotIndex: number) => {
    setVisibleHotspot(visibleHotspot === hotspotIndex ? null : hotspotIndex)
  }

  const renderHotspot = (hotspotData) => {
    if (!hotspotData) return null

    const { store } = hotspotData
    const { title, previewImageUrl, priceRange } = store || {}

    return (
      <div
        className={cn(
          'flex w-full max-w-full flex-row gap-x-[12px] bg-beige p-[8px] md:max-w-[340px] md:flex-col',
        )}
      >
        <div className="relative">
          {previewImageUrl && (
            <Image
              src={previewImageUrl || '#'}
              alt={title || 'Product Image'}
              width={350}
              height={350}
              className="h-[100px] w-full max-w-[100px] object-cover sm:h-[250px] sm:max-w-[250px] md:h-[324px] md:max-w-[324px]"
            />
          )}
          <div className="absolute left-[8px] top-[8px] hidden bg-beige px-[8px] py-[2px] md:block">
            <span className="text-sm !leading-[171%] text-black">Limited availability</span>
          </div>
        </div>
        <div className="mt-[8px] flex flex-col items-start justify-end gap-1 sm:gap-3 md:flex-row md:items-center md:justify-between">
          <span className="text-sm block !leading-[171%] text-black md:hidden">
            Limited availability
          </span>
          <h6 className="text-lg">{title}</h6>
          <p className="text-gray-600">{` $${priceRange.maxVariantPrice}`}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="relative overflow-hidden pb-[50px] sm:pb-[80px]">
      {backgroundMedia?.mediaType === 'image' && backgroundMedia?.image && (
        <Media
          data={backgroundMedia}
          className="h-[580px] w-full object-cover object-top sm:h-[820px]"
        />
      )}
      <div className="grid-container container absolute left-[50%] top-10 translate-x-[-50%]">
        <div className="col-start-1 col-end-7 pl-0 md:col-end-9 md:pl-4">
          {subHeading && (
            <h6 className="text-[15px] font-bold uppercase text-white">{subHeading}</h6>
          )}
          {heading && <h4 className="mt-3 font-bold text-white">{heading}</h4>}
        </div>
      </div>

      <div className="absolute inset-0">
        {numberOfHotspots >= 1 && hotspotOne && (
          <button
            onClick={() => toggleHotspotVisibility(1)}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            <div className="absolute left-[36%] top-[40%] flex items-center justify-center">
              <div
                className={cn(
                  'h-[36px] w-[36px] rotate-[45deg] border-[1px] border-off-white bg-beige-dark/[0.50] transition duration-500 ease-linear',
                  visibleHotspot === 1 ? 'scale-100' : 'scale-0',
                )}
              ></div>
              <div className="absolute h-[20px] w-[20px] rotate-[45deg] bg-off-white"></div>
            </div>
          </button>
        )}
        {numberOfHotspots >= 2 && hotspotTwo && (
          <button
            onClick={() => toggleHotspotVisibility(2)}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            <div className="absolute left-[66%] top-[27.5%] flex items-center justify-center">
              <div
                className={cn(
                  'h-[36px] w-[36px] rotate-[45deg] border-[1px] border-off-white bg-beige-dark/[0.50] transition duration-500 ease-linear',
                  visibleHotspot === 2 ? 'scale-100' : 'scale-0',
                )}
              ></div>
              <div className="absolute h-[20px] w-[20px] rotate-[45deg] bg-off-white"></div>
            </div>
          </button>
        )}
        {numberOfHotspots >= 3 && hotspotThree && (
          <button
            onClick={() => toggleHotspotVisibility(3)}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            <div className="absolute left-[30%] top-[25%] flex items-center justify-center">
              <div
                className={cn(
                  'h-[36px] w-[36px] rotate-[45deg] border-[1px] border-off-white bg-beige-dark/[0.50] transition duration-500 ease-linear',
                  visibleHotspot === 3 ? 'scale-100' : 'scale-0',
                )}
              ></div>
              <div className="absolute h-[20px] w-[20px] rotate-[45deg] bg-off-white"></div>
            </div>
          </button>
        )}
        {numberOfHotspots >= 4 && hotspotFour && (
          <button
            onClick={() => toggleHotspotVisibility(4)}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            <div className="absolute right-[15%] top-[20%] flex items-center justify-center">
              <div
                className={cn(
                  'h-[36px] w-[36px] rotate-[45deg] border-[1px] border-off-white bg-beige-dark/[0.50] transition duration-500 ease-linear',
                  visibleHotspot === 4 ? 'scale-100' : 'scale-0',
                )}
              ></div>
              <div className="absolute h-[20px] w-[20px] rotate-[45deg] bg-off-white"></div>
            </div>
          </button>
        )}
      </div>

      {/* Render hotspots */}
      <div className="max-w-[500px] md:max-w-[340px]">
        <div
          className={cn(
            'absolute bottom-[90px] right-0 w-full max-w-[500px] pl-12 transition duration-300 ease-linear sm:bottom-[133px] md:max-w-[340px]',
            visibleHotspot === 1
              ? 'translate-x-[-28px] sm:translate-x-[-36px]'
              : 'translate-x-[100%]',
          )}
        >
          {renderHotspot(hotspotOne)}
        </div>

        <div
          className={cn(
            'absolute bottom-[90px] right-0 w-full max-w-[500px] pl-12 transition duration-300 ease-linear sm:bottom-[133px] md:max-w-[340px]',
            visibleHotspot === 2
              ? 'translate-x-[-28px] sm:translate-x-[-36px]'
              : 'translate-x-[100%]',
          )}
        >
          {renderHotspot(hotspotTwo)}
        </div>

        <div
          className={cn(
            'absolute bottom-[90px] right-0 w-full max-w-[500px] pl-12 transition duration-300 ease-linear sm:bottom-[133px] md:max-w-[340px]',
            visibleHotspot === 3 ? 'translate-x-[-28px]' : 'translate-x-[100%]',
          )}
        >
          {renderHotspot(hotspotThree)}
        </div>

        <div
          className={cn(
            'absolute bottom-[90px] right-0 w-full max-w-[500px] pl-12 transition duration-300 ease-linear sm:bottom-[133px] md:max-w-[340px]',
            visibleHotspot === 4
              ? 'translate-x-[-28px] sm:translate-x-[-36px]'
              : 'translate-x-[100%]',
          )}
        >
          {renderHotspot(hotspotFour)}
        </div>
      </div>
    </div>
  )
}

export default LifestyleShoppingModule
