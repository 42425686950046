'use client'
import clsx from 'clsx'
import React, { useState } from 'react'

import { CustomPortableText } from '@/components/shared/custom-portable-text'
import Media from '@/components/shared/media'
import OrnamentHeading from '@/components/shared/ornament-heading'
import ProductCard from '@/components/shared/product-card'
import Button from '@/components/ui/button'
import { CollectionPageGrid, Product } from '@/types'

const DesktopCollectionGrid = ({
  breakoutMediaBlocks,
  product,
  breakoutTextBlocks,
  hasLargerProductCardLeft,
  hasLeft,
  hasLoadMoreButton,
}: CollectionPageGrid) => {
  const [seeMore, setSeeMore] = useState(false)
  const gridProducts: Product[] = product?.slice(0, 4)
  const centerProducts: Product[] = product?.slice(4, 6)
  const normalSizeProducts: Product[] = product?.slice(6, 8)
  const largerProducts: Product[] = product?.slice(8, 9)
  const lastProducts: Product[] = product?.slice(9, product?.length)

  return (
    <div className="my-[30px] hidden md:block lg:my-[61px]">
      <div className="grid-container container mb-[60px]">
        {gridProducts?.length > 0 && (
          <>
            {gridProducts?.map((item, index) => (
              <div
                className="group col-span-3 flex cursor-pointer flex-col gap-2 sm:col-span-1 md:col-span-5 lg:col-span-5"
                key={index}
              >
                <ProductCard data={item} key={index} />
              </div>
            ))}
          </>
        )}
      </div>
      <div className="container">
        <div className={clsx('grid-container mb-[75px]')}>
          <div
            className={clsx(
              hasLeft
                ? 'order-2 col-span-9 col-start-12'
                : 'oredr-1 col-start-1 col-end-6 md:col-end-10',
            )}
          >
            {breakoutMediaBlocks?.media && <Media data={breakoutMediaBlocks?.media} />}
          </div>
          <div
            className={clsx(
              'flex flex-col-reverse md:flex-col',
              hasLeft
                ? 'order-1 col-start-1 col-end-11'
                : 'col-span-6 col-start-1 md:col-span-10 md:col-start-11',
            )}
          >
            {centerProducts?.length > 0 && (
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:gap-6 [&>*:nth-child(1)]:col-span-2 md:[&>*:nth-child(1)]:col-span-1">
                {centerProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
              </div>
            )}
            {breakoutTextBlocks && (
              <div className="mt-[30px] flex flex-col items-center justify-between lg:mt-[60px]">
                <OrnamentHeading className="[&>svg]:mb-0" style="style-1" />
                <div className="py-[30px] lg:py-[60px]">
                  {breakoutTextBlocks?.text && (
                    <CustomPortableText
                      value={breakoutTextBlocks?.text}
                      paragraphClasses="pre-header text-center block max-w-[552px] w-full"
                    />
                  )}
                  {breakoutTextBlocks?.quoteSource && (
                    <span className="block pt-8 text-center font-sans text-[0.625rem] font-bold uppercase tracking-10 text-black">
                      {breakoutTextBlocks?.quoteSource}
                    </span>
                  )}
                </div>

                <OrnamentHeading className="[&>svg]:mb-0" style="style-1" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className={clsx(
            'grid-container mb-[75px]',
            hasLargerProductCardLeft ? 'flex-col lg:flex-row-reverse' : 'flex-col lg:flex-row',
          )}
        >
          {normalSizeProducts?.length > 0 && (
            <div
              className={clsx(
                'grid grid-cols-1 gap-5 sm:grid-cols-2 xl:gap-6',
                hasLargerProductCardLeft
                  ? 'order-2 col-span-10 col-start-11'
                  : 'col-span-10 col-start-1',
              )}
            >
              {normalSizeProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
            </div>
          )}
          {largerProducts?.length > 0 && (
            <div
              className={clsx(
                '',
                hasLargerProductCardLeft
                  ? 'order-1 col-span-9 col-start-1'
                  : 'col-span-9 col-start-13',
              )}
            >
              {largerProducts?.map((item, index) => <ProductCard data={item} key={index} />)}
            </div>
          )}
        </div>
      </div>

      <div className="grid-container container">
        {seeMore &&
          lastProducts &&
          lastProducts?.map((item, index) => (
            <div className="group col-span-5 flex cursor-pointer flex-col gap-2" key={index}>
              <ProductCard data={item} />
            </div>
          ))}
      </div>

      {hasLoadMoreButton && (
        <div className="mt-10 flex items-center justify-center pb-[60px]">
          <Button className="mx-auto" variant={'secondary'} onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'Loading less...' : 'Loading more provisions...'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default DesktopCollectionGrid
