'use client'
import { Swiper, SwiperSlide } from 'swiper/react'

import Media from '../shared/media'
import ProductCard from '../shared/product-card'
import { SanityImage } from '../shared/sanity-image'
import Button from '../ui/button'
import { useState } from 'react'
import { cn } from '@/lib/utils'

const CreateAtHomeCallout = ({ title, subtitle, image, isDisabled, productHotspots }) => {
  const [hotspotIndex, setHotspotIndex] = useState(0)
  if (isDisabled) return null
  // console.log('create home callout', data)
  // console.log(data)

  return (
    <section className="relative h-[calc(100vh-var(--header-height))] w-full overflow-hidden after:absolute after:inset-0 after:bg-gradient-to-b after:from-[black]/20 after:to-[black]/20">
      {!!image && <SanityImage data={image} className="absolute h-full w-full object-cover" />}
      {productHotspots.map((hotspot, index) => {
        const { x, y } = hotspot
        return (
          <Button
            onClick={() => setHotspotIndex(index)}
            variant="ghost"
            key={index}
            className="absolute z-[2] hidden md:block"
            style={{ top: y + '%', left: x + '%' }}
          >
            <div
              className={cn(
                'flex h-9 w-9 rotate-45 items-center justify-center transition-colors duration-200',
                {
                  'border border-off-white bg-beige-dark/50': index === hotspotIndex,
                },
              )}
            >
              <div className="h-5 w-5 bg-beige"></div>
            </div>
          </Button>
        )
      })}
      <div className="absolute inset-0 z-[1] overflow-hidden">
        <div className="container mt-10 text-off-white">
          <h3 className="mb-3 font-sans uppercase">{subtitle}</h3>
          <h4>{title}</h4>
        </div>
        <div className="absolute bottom-[53px] right-9 hidden w-[340px] bg-beige p-2 md:block">
          {productHotspots && (
            <ProductCard
              variant="callout"
              data={productHotspots[hotspotIndex]?.productWithVariant?.product}
            />
          )}
        </div>
        <div className="container absolute bottom-4 left-0 w-full md:hidden">
          <Swiper slidesPerView={1.2} loop spaceBetween={24} className="[&&]:overflow-visible">
            {productHotspots.map((hotspot, index) => {
              const { productWithVariant } = hotspot
              const { product } = productWithVariant
              return (
                <SwiperSlide className="h-full bg-beige p-2" key={index}>
                  <ProductCard variant="callout" data={product} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      <div
        className={`md:justify-end' container absolute inset-0 flex h-full w-full items-end justify-center px-5 pb-[78px] md:px-9 md:pb-[60px]`}
      ></div>
    </section>
  )
}

export default CreateAtHomeCallout
