import type { Image } from 'sanity'

import { SanityImage } from '@/components/shared/sanity-image'
import CardFrame from '@/components/ui/card-frame'
import type { Award } from '@/sanity/types'
const AwardsAndAchievementsCard = ({
  title,
  subtitle,
  copy,
  year,
  image,
  style,
  isDisabled,
}: Award) => {
  const style1Jsx = (
    <CardFrame
      type="triple-line"
      className="mx-auto h-fit w-full p-[37px] text-center first:col-span-2 sm:max-w-[440px]"
    >
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center">
          {image ? (
            <>
              <SanityImage data={image as Image} className="mb-4 h-[160px] w-full object-contain" />
            </>
          ) : (
            <>
              {subtitle && (
                <p className="mb-2.5 font-sans text-[0.875rem] font-bold uppercase leading-5 tracking-15">
                  {subtitle}
                </p>
              )}
              {title && <h4>{title}</h4>}
            </>
          )}
        </div>
        {image ? (
          <>{copy}</>
        ) : (
          <>
            <div className="flex w-full items-center justify-center gap-2.5">
              <div className="h-[1px] w-[68px] bg-black"></div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
              <div className="h-[1px] w-[68px] bg-black"></div>
            </div>
            <p>{year}</p>
          </>
        )}
      </div>
    </CardFrame>
  )

  const style3Jsx = (
    <CardFrame
      type="single-line"
      className="mx-auto h-fit w-full items-center p-[33px] first:col-span-2 sm:max-w-[440px]"
    >
      {image ? (
        <SanityImage data={image as Image} className="mb-4 h-[160px] w-full object-contain" />
      ) : (
        <>
          {subtitle && (
            <p className="absolute -top-2 left-6 bg-beige px-2.5 font-sans text-[0.875rem] font-bold uppercase leading-5 tracking-15">
              {subtitle}
            </p>
          )}
          {title && (
            <h2>
              {title} {year && <span> - {year}</span>}
            </h2>
          )}
        </>
      )}

      <div className="flex w-full items-center justify-center gap-2.5">
        <div className="h-[1px] w-[68px] bg-black"></div>

        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33725 1.09136C8.45728 0.575884 9.19116 0.575886 9.31119 1.09136L10.4894 6.15124C10.5457 6.3933 10.7714 6.55722 11.019 6.53602L16.1953 6.09296C16.7226 6.04782 16.9494 6.74579 16.4963 7.01924L12.0481 9.70334C11.8353 9.83175 11.7491 10.097 11.8458 10.3259L13.8668 15.112C14.0727 15.5996 13.4789 16.0309 13.0788 15.6845L9.15154 12.2835C8.96365 12.1208 8.68478 12.1208 8.4969 12.2835L4.56961 15.6845C4.16951 16.0309 3.57578 15.5996 3.78167 15.112L5.80261 10.3259C5.8993 10.097 5.81312 9.83175 5.60032 9.70334L1.15218 7.01923C0.699018 6.74579 0.925803 6.04782 1.45314 6.09296L6.62944 6.53602C6.87708 6.55722 7.10269 6.3933 7.15906 6.15123L8.33725 1.09136Z"
            fill="#353839"
          />
        </svg>
        <div className="h-[1px] w-[68px] bg-black"></div>
      </div>

      <p className={`pt-[20px] ${image ? 'text-center' : 'pl-8 text-right'}`}>{copy}</p>
    </CardFrame>
  )

  const style2Jsx = (
    <CardFrame
      type="double-line"
      className="mx-auto h-fit w-full items-center first:col-span-2 sm:max-w-[422px]"
    >
      <div className="h-full w-full border border-black px-[30px] py-[20px]">
        {image ? (
          <>
            <SanityImage data={image as Image} className="mb-4 h-[160px] w-full object-contain" />
          </>
        ) : (
          title && (
            <>
              <h2 className="mb-4">
                {title} {year && <span> - {year}</span>}
              </h2>
              <div className="mb-[20px] flex min-h-20 grow pl-[50px]">
                <div className="w-[1px] bg-black"></div>
              </div>
            </>
          )
        )}

        <div
          className={`${image ? 'flex items-center justify-center' : 'flex items-center justify-center'}`}
        >
          {copy && (
            <p
              className={`font-sans text-[0.875rem] font-bold uppercase leading-4 tracking-25 ${image ? 'text-center' : ''}`}
            >
              {copy}
            </p>
          )}
        </div>
      </div>
    </CardFrame>
  )

  if (isDisabled) return null

  switch (style) {
    case 'style-1':
      return style1Jsx
    case 'style-2':
      return style2Jsx
    case 'style-3':
      return style3Jsx
    default:
      return null
  }
}

export default AwardsAndAchievementsCard
