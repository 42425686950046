'use client'

import Image from 'next/image'
import { useState, useEffect, useRef } from 'react'

import { cn } from '@/lib/utils'
import { type PullQuoteSlider } from '@/types'

import Divider from '../ui/divider'
import { CustomPortableText } from '../shared/custom-portable-text'

const PullQuoteSlider = ({ quotes, isDisabled }: PullQuoteSlider) => {
  const [currQuote, setCurrQuote] = useState<number>(0)
  const [showQuote, setShowQuote] = useState(true)
  const textContainerRef = useRef<HTMLDivElement>(null)

  const previousQuote = () => {
    if (currQuote === 0) return null
    else {
      setShowQuote(false)

      setTimeout(() => {
        setCurrQuote((prev: number) => (prev === 0 ? prev : prev - 1))
        setShowQuote(true)
      }, 500)
    }
  }

  const nextQuote = () => {
    if (currQuote === quotes?.length! - 1) return null
    else {
      setShowQuote(false)

      setTimeout(() => {
        setCurrQuote((prev: number) => (prev === quotes?.length! - 1 ? prev : prev + 1))
        setShowQuote(true)
      }, 500)
    }
  }

  if (isDisabled) return null

  return (
    quotes && (
      <div className="mb-10 mt-14 md:mb-20 md:mt-10">
        <Divider type="shorter-2-line" className="w-full md:hidden" />
        <div className="grid grid-cols-6 gap-x-4 px-5 md:grid-flow-dense md:grid-cols-20 md:gap-x-6 md:px-0">
          <Divider
            type="shorter-2-line"
            className="col-span-6 col-start-1 hidden md:col-span-16 md:col-start-5 md:block"
          />

          <div className="relative col-span-1 col-start-1 w-[175%] md:col-span-3 md:col-start-5 md:w-full md:pl-0">
            <Image
              src="/assets/icons/quote-arrow.svg"
              width={185}
              height={126}
              className="max-w-full object-contain object-left-top"
              alt=""
            />
          </div>

          <div
            className={cn(
              'col-span-4 col-start-3 mt-5 md:col-span-13 md:col-start-8',
              'w-[115%] -translate-x-[10%] text-24 md:mr-5 md:w-full md:translate-x-0 md:pr-0 md:text-32',
              'max-h-[450px] transition-all duration-500 ease-linear', 
              {
                'opacity-100': showQuote,
                'opacity-0': !showQuote,
                'max-h-[120px]': !showQuote,
              },
            )}
            ref={textContainerRef}
          >
            {quotes && quotes[currQuote].quote && (
              <CustomPortableText
                value={quotes[currQuote].quote}
                paragraphClasses="text-24 md:text-32 tracking-10"
              />
            )}
          </div>

          <div className="col-span-6 col-start-1 mt-12 flex justify-between md:col-span-13 md:col-start-8">
            <div
              className={cn('text-16 transition-opacity duration-500 ease-in-out', {
                'opacity-100': showQuote,
                'opacity-0': !showQuote,
              })}
            >
              {quotes[currQuote]?.author!}
            </div>

            <div className="flex gap-x-4">
              <Image
                width={48}
                height={48}
                alt="previous"
                src="/assets/navigation-arrow-right.svg"
                className={cn(
                  'cursor-pointer',
                  'rotate-180',
                  currQuote === 0 ? 'opacity-50' : 'opacity-100',
                )}
                onClick={previousQuote}
              />
              <Image
                width={48}
                height={48}
                alt="next"
                src="/assets/navigation-arrow-right.svg"
                className={cn(
                  'cursor-pointer',
                  currQuote === quotes?.length! - 1 ? 'opacity-50' : 'opacity-100',
                )}
                onClick={nextQuote}
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default PullQuoteSlider
