import Link from 'next/link'

import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import { Event, Myth, Post } from '@/types'

import { BookmarkButton } from '../ui/button'
import Media, { MediaVariantProps } from './media'

type BlogCardProps = (Post | Myth | Event) & {
  className?: string
  variant?: 'carousel' | 'mobile' | 'horizontal'
  showExcerpt?: boolean
  showTotalTime?: boolean // used on recipes page
  aspectRatio?: MediaVariantProps['aspectRatio']
}

const BlogCard = (props: BlogCardProps) => {
  const {
    featuredMedia,
    title,
    _type,
    slug,
    variant,
    excerpt,
    className,
    showExcerpt,
    showTotalTime,
    aspectRatio,
  } = props
  const category = _type === 'post' ? props.category : undefined
  const subCategory = _type === 'post' ? props.subCategory : undefined
  const times = _type === 'post' ? props.times : undefined
  const totalTime = category === 'recipe' && times?.totalTime ? times.totalTime : null
  const tag =
    _type === 'myth'
      ? 'Founding myth'
      : subCategory
        ? subCategory
        : category === 'dishoom-loves'
          ? 'Dishoom Loves'
          : category

  return (
    <Link
      href={resolveHref({
        _type,
        slug,
        category,
      })}
      className={cn('group block transition-opacity duration-300', className)}
    >
      <div className="relative">
        <Media
          data={featuredMedia}
          aspectRatio={aspectRatio ? aspectRatio : '4/3'}
          className="mb-4"
          sizes="350px"
        />
        {/* <BookmarkButton className="absolute right-[7px] top-2 z-20 bg-default-bg-color opacity-0 transition-opacity duration-200 group-hover:opacity-100" /> */}
      </div>
      {tag && typeof tag === 'string' && <p className="tag mb-2">{tag}</p>}
      <h4 className={cn('paragraph hover-underline group-hover-underline')}>{title}</h4>
      {(showExcerpt || _type === 'event') && excerpt && <p className="body mt-2">{excerpt}</p>}
      {!!(showTotalTime && totalTime) && (
        <p className="caption mt-4 w-full">
          <strong className="[&&]:normal-case">Total:</strong>
          {totalTime}
        </p>
      )}
    </Link>
  )
}

export default BlogCard
