import Image from 'next/image'

import { CallToActionType } from '@/types'

import Divider from '../ui/divider'
import CallToAction from './call-to-action'

type SideLinkProps = {
  title?: string
  subtitle?: string
  cta?: CallToActionType
}

const SideLink = ({ title, subtitle, cta }: SideLinkProps) => {
  if (!cta) return null

  return (
    <CallToAction {...cta} className="relative block h-full w-full" withoutLabel={true}>
      <div className="relative h-full">
        <div className="absolute left-0 top-0 h-full">
          <Divider type="4-line-left" />
        </div>
        <div className="pl-6 text-left">
          {subtitle && (
            <p className="font-sans text-10 font-bold uppercase tracking-12">{subtitle}</p>
          )}
          {title && (
            <p className="truncate font-serif text-18 leading-7 tracking-2 md:text-21">{title}</p>
          )}
          <Image
            className="absolute bottom-1 transition-transform duration-200 group-hover:translate-x-2"
            src="/assets/icons/icon-arrow-scalable.svg"
            width="88"
            height="9"
            alt="Arrow"
          />
        </div>
      </div>
    </CallToAction>
  )
}

export default SideLink
