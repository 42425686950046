'use client'

import Divider from '@/components/ui/divider'
import { cn } from '@/lib/utils'

import NewsletterForm from './newsletter-form'

type NewsletterTrayProps = {
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const NewsletterTray = ({ className, ...props }: NewsletterTrayProps) => {
  return (
    <div
      className={cn(
        'col-span-full col-start-1 row-start-1 ml-auto flex min-h-full items-center transition-transform duration-reveal sm:z-[1] md:z-10 md:col-span-10 md:col-start-11 md:-mr-container md:mr-0 md:w-[50vw] md:translate-x-full',
        className,
      )}
      {...props}
    >
      <div className="relative ml-auto flex self-stretch bg-beige md:max-w-xl">
        <div className="hidden md:block">
          <Divider type="7-line-left" />
        </div>
        <div className="my-0.5 flex grow flex-col gap-y-4 border-y-2 border-black px-5 py-8 md:px-10">
          <div className="font-sans text-15 font-bold uppercase leading-5 tracking-10">
            Newsletter
          </div>
          <div className="font-serif text-30 uppercase leading-9 tracking-[6px]">
            <div className="flex justify-between">
              <span>Subscribe</span>
              <span>to</span>
            </div>
            <div className="flex justify-between">
              <span>the</span>
              <span>Dishoom</span>
            </div>
            <div>Newsletter</div>
          </div>
          {/* <div className={'mx-12 h-10 max-h-10 w-px bg-black'} /> */}
          <p>
            First-dibs on new launches, occasional recipes and other Dishoom goings-on — straight to
            your inbox.
          </p>

          <NewsletterForm isMin />
        </div>
      </div>
    </div>
  )
}

export default NewsletterTray
