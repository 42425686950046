'use client'

import { type QueryResponseInitial } from '@sanity/react-loader'

import { pagesBySlugQuery } from '@/sanity/lib/queries'
import { useQuery } from '@/sanity/loader/useQuery'
import { PagePayload } from '@/types'

import Page from './page'

type Props = {
  params: { slug: string; childSlug?: string }
  initial: QueryResponseInitial<PagePayload | null>
}

export default function PagePreview(props: Props) {
  const { params, initial } = props
  const { slug, childSlug } = params
  const { data } = useQuery<PagePayload | null>(
    pagesBySlugQuery,
    { slug, childSlug: childSlug ?? false },
    {
      initial,
    },
  )

  return <Page data={data} />
}
