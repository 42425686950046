'use client'
import type { CareersModule } from '@/types'

import CareersModuleDesktop from './careers-module-desktop'
import CareersModuleMobile from './careers-module-mobile'

const CareersModule = (props: CareersModule) => {
  return (
    <>
      <CareersModuleDesktop {...props} />
      <CareersModuleMobile {...props} />
    </>
  )
}

export default CareersModule
