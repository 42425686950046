'use client'

import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useRef } from 'react'

import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn, groupWordsInPairs } from '@/lib/utils'
import type { StoreIntro } from '@/types'

import Media from '../shared/media'
import FramedCard from '../ui/framed-card'

gsap.registerPlugin(useGSAP, ScrollTrigger)

const StoreIntro = ({
  contentPosition,
  title,
  subtitle,
  copy,
  cta,
  media,
  isDisabled,
}: StoreIntro) => {
  const containerRef = useRef(null)
  const cardRef = useRef(null)
  const isVisible = useIsOnscreen({
    ref: cardRef,
  })

  // split phrase by words and group 2 words per line
  const titleWordsGroup = groupWordsInPairs(title)
  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      })

      tl.to('.background-media', {
        yPercent: `-${100 / 6}`,
        duration: 1,
        ease: 'none',
      })
    },
    { scope: containerRef },
  )

  if (isDisabled) return null

  return (
    <section
      className="relative h-[calc(100vh-var(--header-height))] w-full justify-items-center overflow-hidden"
      ref={containerRef}
    >
      {!!media && (
        <Media
          data={media}
          className="background-media absolute [&&]:inset-x-0 [&&]:bottom-auto [&&]:top-0 [&&]:h-[120%]"
        />
      )}
      <div
        className={`container absolute inset-0 flex h-full w-full items-end justify-center px-5 pb-[78px] ${contentPosition === 'right' ? 'md:justify-end' : 'md:justify-start'} md:px-9 md:pb-[60px]`}
      >
        <div
          className={cn('-translate-x-full opacity-0 transition-all duration-500', {
            'opacity-1 translate-x-0': isVisible,
          })}
          ref={cardRef}
        >
          <FramedCard subtitle={subtitle} titleWordsGroup={titleWordsGroup} copy={copy} cta={cta} />
        </div>
      </div>
    </section>
  )
}

export default StoreIntro
