import { CSSProperties } from 'styled-components'

import { cn } from '@/lib/utils'

import BookmarkShare from '../shared/bookmark-share'
import Media from '../shared/media'
import Divider from '../ui/divider'
import { BlogHeaderProps } from './blog-header'

const DlAlternativeHeader = ({ title, category, heroMedia, excerpt, color }: BlogHeaderProps) => {
  const headerInlineStyle = { '--bg-color': color?.hex } as CSSProperties
  return (
    <div className="h-screen">
      <div
        className="relative grid h-[calc(100vh-24px)] grid-cols-20 bg-[var(--bg-color)]"
        style={headerInlineStyle}
      >
        <div className={cn('col-span-20 lg:col-span-10')}>
          {heroMedia && (
            <Media data={heroMedia} className="h-[60vh] w-full object-cover lg:h-full" />
          )}
        </div>

        <div
          className={cn(
            'z-0 col-span-20 w-full lg:col-span-10',
            'flex flex-col items-start justify-center gap-4 lg:justify-end lg:gap-6',
            'border-white',
            'px-5 pb-4 pt-4 text-black sm:pb-10 md:px-12 md:pb-14 md:pt-16 xl:px-20',
          )}
        >
          <div className="tag">{category?.split('-').join(' ')}</div>
          <h1>{title}</h1>
          <p>{excerpt}</p>

          <div className="flex w-full flex-col font-serif"></div>

          <div className="flex w-full justify-center md:mt-11 md:justify-start">
            <BookmarkShare
              showBtnTextOnMobile
              isBookmarkVisible={false}
              socialClassName="md:bottom-8 md:-right-4"
              className="justify-center"
            />
          </div>
        </div>
      </div>
      <Divider type="7-line" />
    </div>
  )
}

export default DlAlternativeHeader
