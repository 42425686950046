'use client'
import React, { useRef } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import SliderNavigation from '@/components/ui/slider-navigation'
import { urlForImage } from '@/sanity/lib/utils'
import type { PartnerCollection } from '@/types'

import CallToAction from '../shared/call-to-action'
import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'
import ProductCard from '../shared/product-card'
import Button from '../ui/button'
import Divider from '../ui/divider'

const PartnerCollection = ({
  variant,
  bodyCopy,
  branding,
  color,
  cta,
  heading,
  intro,
  leadImage,
  mainMedia,
  primaryCta,
  productsSlider,
  secondaryCta,
  secondaryHeading,
  subtitle,
  isDisabled,
}: PartnerCollection) => {
  const sliderRef = useRef(null)
  const imageUrl = (leadImage?.image && urlForImage(leadImage?.image)?.url()) || ''

  if (isDisabled) return null

  return (
    <>
      {variant === 'primary' && (
        <section className="h-full w-full" style={{ backgroundColor: color?.hex }}>
          <div className="group relative w-full bg-beige pb-[200px]">
            <div className="sticky top-0 h-[1000px]">
              {intro?.backgroundMedia && (
                <Media
                  data={intro?.backgroundMedia}
                  className="h-fit w-full scale-0 object-cover opacity-0 transition duration-700 ease-linear group-hover:scale-100 group-hover:opacity-100 [&>div>div>img]:h-[1000px]"
                />
              )}
            </div>
            <div className="absolute left-[50%] top-[25%] w-full max-w-[265px] translate-x-[-50%] sm:max-w-[340px] md:max-w-[395px]">
              {intro?.collectionBranding && (
                <Media
                  data={intro?.collectionBranding}
                  className="[&>div>div>img]:h-[80px] [&>div>div>img]:brightness-0 [&>div>div>img]:ease-linear group-hover:[&>div>div>img]:brightness-200 md:[&>div>div>img]:h-[140px]"
                />
              )}
            </div>
            <section className="relative mb-[-200px] flex flex-col items-center md:gap-y-16 xl:gap-y-24">
              <div className="container mx-2 mt-24 w-full overflow-hidden bg-orange p-1 px-[31px] pb-8 pt-11 outline outline-offset-[-6px] sm:px-10 sm:pt-14 md:pb-16 lg:px-0 lg:pt-[88px]">
                <div className="grid-container">
                  <div className="col-span-7 col-start-1 md:mt-16 lg:col-span-6 lg:col-start-2">
                    <Divider type="shorter-solid-diamond" className="justify-start" />

                    {heading && <p className="h3 mb-2 pb-2 pt-4">{heading}</p>}

                    {subtitle && (
                      <CustomPortableText
                        value={subtitle}
                        paragraphClasses=""
                        className="[&>p]:h4 [&>p]:mt-[8px] [&>p]:tracking-[0.64px]"
                      />
                    )}
                    <div className="block md:hidden">
                      <div className="mt-11">
                        <Media
                          data={mainMedia}
                          className="[&>div>div>]:h-[350px] [&>div>div>]:w-full [&>div>div>]:max-w-full [&>div>div>]:object-cover sm:[&>div>div>]:h-[400px]"
                        />
                      </div>
                    </div>
                    <div className="pt-6 md:pt-0">
                      {bodyCopy && (
                        <CustomPortableText
                          value={bodyCopy}
                          paragraphClasses="text-16 sm:text-18 !leading-[133%] my-9"
                        />
                      )}
                    </div>

                    <div className="mt-9 flex w-full flex-col gap-3 sm:flex-row md:flex-col lg:flex-row">
                      {primaryCta && (
                        <Button
                          asChild
                          variant="primary"
                          className="w-full max-w-full whitespace-nowrap sm:max-w-[230px]"
                        >
                          <CallToAction {...primaryCta} />
                        </Button>
                      )}

                      {secondaryCta && (
                        <Button
                          asChild
                          variant="secondary"
                          className="cta w-full max-w-full self-start whitespace-nowrap bg-transparent after:border-black sm:max-w-[160px]"
                        >
                          <CallToAction {...secondaryCta} />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="col-span-11 col-start-10 mt-10 hidden md:block lg:col-span-9 lg:col-start-11 lg:mt-0">
                    <Media
                      data={mainMedia}
                      className="w-full [&>div]:h-[350px] [&>div]:max-w-full [&>div]:object-cover sm:[&>div]:h-[400px] md:[&>div]:h-[590px]"
                    />
                  </div>
                </div>
                <div className="em:mt-[60px] ml-auto mr-2 mt-5 w-full max-w-full md:max-w-[590px]">
                  <Swiper
                    slidesPerView={1.4}
                    ref={sliderRef}
                    modules={[Navigation]}
                    spaceBetween={20}
                    speed={500}
                    breakpoints={{
                      480: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                      },
                      1280: {
                        slidesPerView: 2.4,
                      },
                    }}
                    className={`!em:pt-10 !overflow-visible !pt-[20px] md:!pt-[60px]`}
                  >
                    {productsSlider &&
                      productsSlider?.map((item, index) => (
                        <SwiperSlide key={index}>{item && <ProductCard data={item} />}</SwiperSlide>
                      ))}
                    <span slot="container-end">
                      <SliderNavigation
                        type="next"
                        className="right-[20%] top-[45%] translate-x-full bg-orange"
                        hideDisabled
                      />
                    </span>
                  </Swiper>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}

      {variant === 'secondary' && (
        <div className="">
          <section className="bg-beige py-[70px] md:py-[80px] lg:py-[103px]">
            <div className="grid-container group container">
              <div className="col-span-4 col-start-2 h-[227px] w-full overflow-hidden border-[3px] border-black sm:h-[400px] md:col-span-10 md:col-start-1 md:h-[615px] lg:col-span-9 lg:col-start-2">
                <div
                  className="h-[240px] origin-bottom overflow-hidden bg-cover bg-no-repeat object-cover transition duration-700 ease-out group-hover:origin-top group-hover:translate-y-[-10px] sm:h-[420px] md:h-[630px] md:group-hover:translate-y-[-20px]"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                ></div>
              </div>
              <div className="col-span-6 col-start-1 mt-8 flex h-full flex-col items-center justify-center md:col-span-7 md:col-start-13 md:mt-0 md:items-start lg:col-span-6 lg:col-start-13">
                {branding && (
                  <Media
                    data={branding}
                    className="h-[100px] w-full max-w-[200px] invert sm:h-[134px] sm:max-w-[253px]"
                  />
                )}
                {secondaryHeading && (
                  <CustomPortableText
                    value={secondaryHeading}
                    paragraphClasses=""
                    className="prose-text [&>p]:h4 text-center md:text-left [&>p]:mt-8 md:[&>p]:mt-[54px]"
                  />
                )}
                <div className="mt-[30px]">
                  {cta && (
                    <Button variant="primary" className="" asChild>
                      <CallToAction {...cta} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </section>

          <Divider type="outline-diamond" />
        </div>
      )}
    </>
  )
}

export default PartnerCollection
