'use client'

import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import type { Resources } from '@/types'

import Media from '../shared/media'
import Button from '../ui/button'

const Resources = ({ title, copy, files, backgroundMedia, style, isDisabled }: Resources) => {
  const downloadAllFiles = async () => {
    const zip = new JSZip()

    if (isDisabled) return null

    if (!files || files.length === 0) {
      console.error('No files available for download.')
      return
    }

    await Promise.all(
      files.map(async (item) => {
        const response = await fetch(item.file.url)
        const blob = await response.blob()
        zip.file(item.file.filename, blob)
      }),
    )

    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, title + '.zip')
  }

  const fullBannerStyle = (
    <div className="relative h-[667px] w-full md:h-[720px]">
      <Media data={backgroundMedia} className="h-full w-full" />
      <div className="container absolute inset-0 grid grid-flow-dense grid-cols-6 items-end p-5 md:grid-cols-20 md:items-center">
        <div className="relative col-span-6 col-start-1 grid min-h-[391px] grid-flow-dense border-[3px] border-black bg-beige p-[5px] before:absolute before:inset-[5px] before:border before:border-black before:content-[''] md:col-span-8 md:col-start-12 md:min-h-[446px] md:grid-cols-8">
          <div className="flex flex-col justify-between p-6 md:col-span-6 md:col-start-2 md:px-0 md:py-12">
            <div>
              {title && <h3 className="">{title}</h3>}
              {copy && <p>{copy}</p>}
              {!!files?.length && (
                <div className="mt-7">
                  {files.map((item) => {
                    if (!item.file) return null

                    return (
                      <div
                        key={item.file.url}
                        className="flex items-center justify-between border-t border-beige-dark py-2 md:py-2.5"
                      >
                        <a href={item.file.url} target="_blank" rel="noreferrer" className="labels">
                          {item.name}
                        </a>
                        <a
                          href={item.file.url}
                          download={item.file.filename}
                          rel="noreferrer"
                          className="text-[#000000]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <rect width="25" height="25" fill="#F0ECE0" />
                            <path
                              d="M12 5.5C12 5.22386 12.2239 5 12.5 5C12.7761 5 13 5.22386 13 5.5V15H12V5.5Z"
                              fill="#353839"
                            />
                            <path
                              d="M16 12L12.5 15.5L9 12"
                              stroke="#353839"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.5 14V19C20.5 19.2761 20.2761 19.5 20 19.5H5C4.72386 19.5 4.5 19.2761 4.5 19V14"
                              stroke="#353839"
                              strokeLinecap="round"
                            />
                          </svg>
                        </a>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            <Button
              onClick={downloadAllFiles}
              variant="secondary"
              className="h-[49px] w-fit font-sans text-[15px] font-bold"
            >
              Download all
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  const cardStyle = (
    <div className="blog-module-spacing grid-container container">
      <div className="col-span-6 col-start-1 shrink-0 border-[3px] border-black bg-beige p-[5px] md:col-span-16 md:col-start-3">
        <div className="flex h-full w-full flex-col bg-beige md:flex-row">
          {backgroundMedia && (
            <Media
              data={backgroundMedia}
              className="h-[262px] w-full shrink-0 overflow-hidden md:h-full md:w-1/2"
            />
          )}
          <div className="flex w-full justify-center bg-beige py-[30px] pr-[3px] md:px-5 md:py-10 lg:px-16">
            <div className="flex w-full flex-col px-[20px] md:px-0">
              <div>
                {title && <h3 className="mb-[10px] md:mb-3">{title}</h3>}
                {copy && <p>{copy}</p>}
                {!!files?.length && (
                  <div className="mt-7">
                    {files.map((item) => {
                      if (!item.file) return null

                      return (
                        <div
                          key={item.file.url}
                          className="flex items-center justify-between border-t border-beige-dark py-2 md:py-2.5"
                        >
                          <a
                            href={item.file.url}
                            target="_blank"
                            rel="noreferrer"
                            className="labels"
                          >
                            {item.name}
                          </a>
                          <a
                            href={item.file.url}
                            download={item.file.filename}
                            rel="noreferrer"
                            className="text-[#000000]"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <rect width="25" height="25" fill="#F0ECE0" />
                              <path
                                d="M12 5.5C12 5.22386 12.2239 5 12.5 5C12.7761 5 13 5.22386 13 5.5V15H12V5.5Z"
                                fill="#353839"
                              />
                              <path
                                d="M16 12L12.5 15.5L9 12"
                                stroke="#353839"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.5 14V19C20.5 19.2761 20.2761 19.5 20 19.5H5C4.72386 19.5 4.5 19.2761 4.5 19V14"
                                stroke="#353839"
                                strokeLinecap="round"
                              />
                            </svg>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <Button
                onClick={downloadAllFiles}
                variant="secondary"
                className="mt-5 h-[49px] w-fit font-sans text-[15px] font-bold"
              >
                Download all
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  if (!files || files.length === 0) {
    return null
  }

  return style === 'card' ? cardStyle : fullBannerStyle
}

export default Resources
