'use client'
import React, { useRef } from 'react'

import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import type { FeatureProductsBlock } from '@/types'

import CallToAction from '../shared/call-to-action'
import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'
import ProductCard from '../shared/product-card'
import Button from '../ui/button'

interface FeatureProductsBlockProps extends FeatureProductsBlock {
  className?: string
}

const FeatureProductsBlock = ({
  title,
  backgroundMedia,
  slideOutColor,
  slideOutCopy,
  slideOutTitle,
  slideOutCta,
  slideOutProducts,
  isDisabled,
  slideOutTextColor,
  className,
}: FeatureProductsBlockProps) => {
  const ref = useRef(null)
  const isVisible = useIsOnscreen({ ref, once: false })

  if (isDisabled || !backgroundMedia) return null

  return (
    <section
      className={cn(
        'group/featured-products relative mb-7 md:mb-20 md:overflow-hidden lg:mb-28 md:portrait:h-[700px] md:landscape:h-[820px] md:landscape:max-h-screen',
        className,
      )}
      ref={ref}
    >
      {backgroundMedia && (
        <div className="sticky top-header-height h-[500px] text-off-white md:absolute md:inset-0 md:h-auto">
          <Media data={backgroundMedia} sizes="100vw" />
          <div className="grid-container container absolute bottom-7 left-0 right-0 md:bottom-14">
            {title && (
              <h2 className="prose-text col-span-full normal-case md:col-span-10">{title}</h2>
            )}
          </div>
        </div>
      )}
      {!!slideOutProducts?.length && (
        <div
          className={cn(
            'relative z-10 border-l-0 border-t-2 pt-1.5 transition duration-500 ease-linear md:ml-auto h-full md:max-w-md md:translate-x-full md:overflow-y-scroll md:border-l-2 md:border-t-0 md:pl-1 md:pt-0 md:scrollbar-hide group-hover/featured-products:md:translate-x-0',
            {
              'border-beige text-off-white': slideOutTextColor === 'light',
              'border-beige-dark text-black': slideOutTextColor === 'dark' || !slideOutTextColor,
              'md:translate-x-0': isVisible,
            },
          )}
          style={{ background: slideOutColor?.hex }}
        >
          <div className="border-t-2 min-h-full border-beige px-5 pb-7 pt-10 md:border-l-2 md:border-t-0 md:px-9 md:pb-14 ">
            {slideOutTitle && <h3 className="h3 mb-2 md:mb-4">{slideOutTitle}</h3>}
            {slideOutCopy && <CustomPortableText value={slideOutCopy} className="mb-7 md:mb-10" />}
            <ul className="ml-0 flex flex-shrink-0 flex-col gap-y-7 md:gap-y-14">
              {slideOutProducts?.map((item, index) => (
                <ProductCard data={item} className="max-w-lg" key={index} />
              ))}
            </ul>

            {slideOutCta && (
              <Button asChild variant="transparent">
                <CallToAction {...slideOutCta} className="mt-8 md:mt-10" />
              </Button>
            )}
          </div>
        </div>
      )}
    </section>
  )
}

export default FeatureProductsBlock