'use client'
import Image from 'next/image'
import { useRef } from 'react'

import CallToAction from '@/components/shared/call-to-action'
import MuxVideo from '@/components/shared/mux-video'
import { SanityImage } from '@/components/shared/sanity-image'
import { cn } from '@/lib/utils'
import type { KeyPropositionCard } from '@/types'

interface KeyPropositionCardProps extends KeyPropositionCard {
  className?: string
  style?: object
  variant: 'portrait' | 'landscape'
}

const KeyPropositionsCard = ({
  title,
  excerpt,
  previewImage,
  hoverVideo,
  cta,
  className,
  style,
  variant,
}: KeyPropositionCardProps) => {
  const ref = useRef(null)

  const onMouse = (e) => {
    if (!ref.current) return
    const videoEl = ref.current as HTMLVideoElement

    if (e.type === 'mouseenter') {
      playVideo(videoEl)
    } else if (e.type === 'mouseleave') {
      videoEl.pause()
    }
  }

  const playVideo = async (video: HTMLVideoElement) => {
    video.muted = true // needed only for Lazy MuxPlayer
    video.loop = true // needed only for Lazy MuxPlayer
    video.currentTime = 0

    try {
      await video.play()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <li
      className={cn('card relative w-full', className)}
      onMouseEnter={onMouse}
      onMouseLeave={onMouse}
      style={style}
    >
      <CallToAction
        {...cta}
        className={cn('group aspect-h-3 aspect-w-5 flex', {
          'lg:aspect-h-5 lg:aspect-w-4': variant === 'portrait',
          'lg:aspect-h-4 lg:aspect-w-6': variant === 'landscape',
          'h-full w-full border-none text-black after:border-none': cta?.linkType === 'bookTable',
        })}
        withoutLabel
      >
        {hoverVideo && (
          <div className="absolute inset-0 z-0 object-cover">
            <MuxVideo
              ref={ref}
              autoPlay={false}
              playbackId={hoverVideo.asset.playbackId}
              className="h-full w-full"
            />
          </div>
        )}
        <SanityImage
          data={previewImage}
          className={cn('object-cover transition-opacity duration-300', {
            'lg:group-hover:opacity-0': hoverVideo,
          })}
        />
        <div
          className={cn(
            `bottom-5 left-20 top-auto -mr-7 flex h-auto w-auto flex-col items-center border-2 border-black bg-beige p-5 pr-6 pt-4 text-left before:pointer-events-none before:absolute before:inset-1 before:border before:border-black sm:inset-x-2 sm:bottom-3 sm:top-auto sm:mr-0 sm:h-auto sm:w-auto sm:text-center md:mx-auto md:max-w-sm md:px-4 md:pb-5 md:pt-3`,
            {
              'lg:inset-x-6 lg:bottom-9': variant === 'portrait',
              'lg:inset-x-0 lg:bottom-auto lg:top-1/2 lg:mx-auto lg:-translate-y-1/2 lg:px-8 lg:pb-5 lg:pt-3':
                variant === 'landscape',
            },
            className,
          )}
          style={style}
        >
          <h3 className="h6 text-[1.3125rem] uppercase tracking-20">{title}</h3>
          {excerpt && <p className="small-text">{excerpt}</p>}
          <Image
            className="ml-auto mt-3 transition-all duration-300 md:mx-auto lg:mt-0 lg:h-0 lg:w-auto lg:-translate-x-full lg:-rotate-[22.5deg] lg:opacity-0 lg:group-hover:mt-5 lg:group-hover:h-2 lg:group-hover:translate-x-0 lg:group-hover:rotate-0 lg:group-hover:opacity-100"
            src="/assets/icons/icon-arrow-scalable.svg"
            width="64"
            height="9"
            alt="Arrow"
          />
        </div>
      </CallToAction>
    </li>
  )
}

export default KeyPropositionsCard
