'use client'

import { useState } from 'react'

import CursorTooltip from './cursor-tooltip'

type CursorTooltipWrapperProps = {
  isVisible: boolean
  text?: 'read' | 'watch' | 'listen' | 'drag'
  children?: React.ReactNode
}

const CursorTooltipWrapper = ({
  isVisible,
  text = 'read',
  children,
}: CursorTooltipWrapperProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <CursorTooltip text={text} isVisible={showTooltip && isVisible} />
      {children}
    </div>
  )
}

export default CursorTooltipWrapper
