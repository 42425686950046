'use client'

import { PortableTextBlock } from 'next-sanity'
import { useEffect, useState } from 'react'

import { CustomPortableText } from '@/components/shared/custom-portable-text'
import Media from '@/components/shared/media'
import TransparentHeaderContainer from '@/components/shared/transparent-header-container'
import Button from '@/components/ui/button'
import CardFrame from '@/components/ui/card-frame'
import { MediaOnly } from '@/types'

type WithPasswordProtectionProps = {
  password?: string
  passwordProtected?: boolean
  slug?: string
  unprotectedContent?: {
    subtitle?: string
    title?: string
    copy?: PortableTextBlock[]
    media?: MediaOnly
  }
  children: React.ReactNode
}

const WithPasswordProtection = ({
  password,
  passwordProtected,
  slug = 'default', // Use a default slug if none is provided
  unprotectedContent,
  children,
}: WithPasswordProtectionProps) => {
  const [enteredPassword, setEnteredPassword] = useState<string>('')
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const correctPassword = password
  const localStorageKey = `hasAccess_${slug}`

  useEffect(() => {
    const access = localStorage.getItem(localStorageKey)
    if (access === correctPassword) {
      setHasAccess(true)
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [localStorageKey, correctPassword])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (enteredPassword === correctPassword) {
      localStorage.setItem(localStorageKey, enteredPassword)
      setHasAccess(true)
      setErrorMessage('')
    } else {
      localStorage.removeItem(localStorageKey)
      setErrorMessage('Incorrect password. Please try again.')
    }
  }

  if (!passwordProtected || hasAccess) {
    return children
  }

  return (
    <div className="relative py-80">
      <TransparentHeaderContainer rootMargin="0px 0px 90% 0px">
        {unprotectedContent?.media && (
          <div className="absolute inset-x-0 top-0 -z-10 h-screen">
            <Media data={unprotectedContent?.media} />
          </div>
        )}
      </TransparentHeaderContainer>
      <div className="grid-container container items-center">
        <div className="col-span-full mx-auto lg:col-span-10 lg:col-start-6">
          <CardFrame type="double-line">
            <div className="bg-beige p-8">
              <h1 className="pre-header mb-3 text-center">{unprotectedContent?.subtitle}</h1>
              <h2 className="h1 mb-12 text-center">{unprotectedContent?.title}</h2>
              {unprotectedContent?.copy && (
                <CustomPortableText value={unprotectedContent?.copy} className="body mb-3" />
              )}
              <div>
                <form onSubmit={handleSubmit} className="bg-gray-100 rounded-lg p-6">
                  <input
                    type="password"
                    value={enteredPassword}
                    onChange={(e) => setEnteredPassword(e.target.value)}
                    className="mb-4 w-full rounded border p-2"
                    placeholder="Password"
                  />
                  {errorMessage && <p className="mb-4 text-red">{errorMessage}</p>}
                  <Button className="mt-4 w-full" variant="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </CardFrame>
        </div>
      </div>
    </div>
  )
}

export default WithPasswordProtection
