import { cn } from '@/lib/utils'
import type { SharedCopyBlock } from '@/types'

import Button, { ButtonProps } from '../ui/button'
import CallToAction from './call-to-action'
import { CustomPortableText } from './custom-portable-text'
import OrnamentHeading from './ornament-heading'

interface SharedCopyBlockProps extends SharedCopyBlock {
  className?: string
  ornamentClassName?: string
  subtitleVariant?: 'h3' | 'pre-header'
  buttonVariant?: ButtonProps['variant']
  textAlign?: 'justify' | 'center' | 'left' | 'right'
  headerAlign?: 'center' | 'left' | 'right'
  textPadding?: string
  buttonClassName?: string
}

const SharedCopyBlock = ({
  title,
  subtitle,
  copy,
  cta,
  className,
  ornamentClassName,
  buttonVariant = 'secondary',
  textAlign = 'justify',
  headerAlign = 'center',
  subtitleVariant,
  textPadding = '',
  buttonClassName,
}: SharedCopyBlockProps) => {
  return (
    <div className={cn('mx-auto flex max-w-md flex-col lg:max-w-lg', className)}>
      <OrnamentHeading
        title={title}
        subtitle={subtitle}
        style="style-1"
        textAlign={headerAlign}
        ornamentClassName={ornamentClassName}
        subtitleVariant={subtitleVariant}
      />
      {copy && (
        <CustomPortableText
          paragraphClasses={`body [&:not(:last-child)]:mb-1em text-${textAlign} ${textPadding}`}
          value={copy}
        />
      )}

      {!!cta && (
        <Button
          variant={buttonVariant}
          asChild
          className={cn('mx-auto mt-[1.875rem] md:mt-6', buttonClassName)}
        >
          <CallToAction {...cta} />
        </Button>
      )}
    </div>
  )
}

export default SharedCopyBlock
