'use client'
import 'swiper/css'

import React from 'react'
import { Image } from 'sanity'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SanityImage } from '@/components/shared/sanity-image'
import SliderNavigation from '@/components/ui/slider-navigation'
import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'
import type { ContentBucket } from '@/types'

import BlogCard from '../shared/blog-card'
import CallToAction from '../shared/call-to-action'
import Button from '../ui/button'
import Divider from '../ui/divider'

const ContentBucket = ({
  description,
  logo,
  featuredPosts,
  posts,
  link,
  layout,
  seoTitle,
  variant,
  isDisabled,
}: ContentBucket) => {
  const isDesktop = useTailwindBreakpoint('md')
  let actualPosts = variant === 'manual' ? featuredPosts : posts

  if (!actualPosts?.length) return null

  const excludedFirstPost = actualPosts.slice(1)
  const firstPost = actualPosts[0]
  const secondPost = actualPosts[1]
  const thirdPost = actualPosts[2]
  const carouselPosts = !isDesktop ? excludedFirstPost : actualPosts

  if (isDisabled) return null

  return (
    <div className="content-bucket py-20">
      {seoTitle && <h2 className="sr-only">{seoTitle}</h2>}
      {layout === '3-card' && (
        <div className="grid-container container overflow-hidden">
          <div className="relative col-span-20 md:sticky md:top-[calc(var(--header-height)+20px)] md:col-span-6 md:col-start-2 md:self-start">
            {logo && (
              <SanityImage data={logo as Image} className="h-auto w-[255px] object-contain" />
            )}
            {description && (
              <h4 className="leading-21 mb-2 mt-[30px] font-serif text-[21px]">{description}</h4>
            )}
            {link && (
              <Button variant="secondary" className="mt-12 hidden md:inline-block" asChild>
                <CallToAction {...link} />
              </Button>
            )}
          </div>

          <Divider
            type="scalable-line-diamonds-vertical"
            className="relative hidden h-full md:col-span-2 md:col-start-8 md:flex"
          />
          <Divider
            type="scalable-line-diamonds"
            className="col-span-20 mb-[39px] mt-[54px] flex w-full md:hidden"
          />

          <div className="col-span-20 md:col-span-10 md:col-start-10">
            {firstPost?._type === 'post' && (
              <BlogCard {...firstPost} showExcerpt={true} className="md:mb-10" />
            )}
            {/* <div className="md:hidden">
              {secondPost?._type === 'post' && (
                <BlogCard {...secondPost} showExcerpt={true} className="mb-10" />
              )}
            </div>
            <div className="md:hidden">
              {thirdPost?._type === 'post' && (
                <BlogCard {...thirdPost} showExcerpt={true} className="mb-10" />
              )}
            </div> */}
            <div className="md:hidden">
              <Divider type="scalable-line-diamonds" className="my-6 flex w-full" />
              <Swiper
                modules={[Navigation]}
                slidesPerView={1.2}
                spaceBetween={24}
                loop
                className="[&&]:overflow-visible"
              >
                {excludedFirstPost.map((post, index) => (
                  <SwiperSlide key={index}>
                    <BlogCard {...post} showExcerpt={true} className="max-w-sm md:max-w-none" />
                  </SwiperSlide>
                ))}
                <SliderNavigation type="prev" className="xl:-translate-x-full" />
                <SliderNavigation type="next" className="xl:translate-x-full" />
              </Swiper>
            </div>
            {link && (
              <Button variant="secondary" className="mb-[30px] mt-7 inline-block md:hidden" asChild>
                <CallToAction {...link} />
              </Button>
            )}
            {/* ToDo: backend field */}
            <div className="hidden grid-cols-10 gap-x-10 md:grid">
              <div className="col-span-6">
                {secondPost?._type === 'post' && (
                  <BlogCard {...secondPost} showExcerpt={true} className="mb-10" />
                )}
              </div>
              <div className="col-span-4 col-start-7">
                {thirdPost?._type === 'post' && (
                  <BlogCard {...thirdPost} showExcerpt={true} className="mb-10" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {layout === 'carousel' && (
        <div className="container mb-[61px] xl:grid xl:grid-cols-20">
          <div className="col-span-4 col-start-2">
            <SanityImage data={logo as Image} className="h-auto w-[255px] object-contain" />
          </div>

          <div className="mb-12 xl:col-span-10 xl:col-start-7">
            <h4 className="leading-21 mb-2 mt-[30px] font-serif text-[21px] md:mt-0">
              {description}
            </h4>
            {link && (
              <Button variant="secondary" className="mt-9" asChild>
                <CallToAction {...link} />
              </Button>
            )}
            {/* ToDo: backend field */}
            <div className="relative mb-[39px] mt-[54px] w-full">
              <Divider
                type="scalable-line-diamonds"
                className="absolute left-0 top-0 w-[150%] -translate-y-[50%]"
              />
            </div>
          </div>

          <div className="mb-5 block sm:hidden">
            {firstPost?._type === 'post' && (
              <BlogCard {...firstPost} variant="carousel" className="mb-10" />
            )}
            <Divider type="scalable-line-diamonds" />
          </div>
          <Swiper
            slidesPerView={1.2}
            breakpoints={
              {
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 4,
                },
              } as any
            }
            modules={[Navigation]}
            spaceBetween={24}
            speed={500}
            loop
            className={`group container relative w-full xl:col-span-16 xl:col-start-3 [&&]:overflow-visible xl:[&_.swiper-slide-active+.swiper-slide+.swiper-slide+.swiper-slide>*]:opacity-100 lg:[&_.swiper-slide-active+.swiper-slide+.swiper-slide>*]:opacity-100 md:[&_.swiper-slide-active+.swiper-slide>*]:opacity-100`}
          >
            {carouselPosts.map((post, i) => {
              return (
                <SwiperSlide key={post._id + '-' + i}>
                  {({ isActive }) => (
                    <BlogCard
                      {...post}
                      variant="carousel"
                      showExcerpt={true}
                      className={isActive ? 'opacity-100' : 'opacity-30'}
                    />
                  )}
                </SwiperSlide>
              )
            })}
            <span slot="container-end">
              <SliderNavigation type="prev" className="xl:-translate-x-full" />
              <SliderNavigation type="next" className="xl:translate-x-full" />
            </span>
          </Swiper>
        </div>
      )}
    </div>
  )
}

export default ContentBucket
