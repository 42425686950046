'use client'
import 'swiper/css'

import React from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import SliderNavigation from '@/components/ui/slider-navigation'
import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'
import { cn } from '@/lib/utils'
import type { CuratedRow, ManualCard, Myth, Post, Product } from '@/types'

import { BenefitsCard } from '../shared/benefits-card'
import BlogCard from '../shared/blog-card'
import CallToAction from '../shared/call-to-action'
import ProductCard from '../shared/product-card'
import Button from '../ui/button'
import Divider from '../ui/divider'

const CuratedRow = ({
  title,
  subtitle,
  cta,
  featuredPosts = [],
  featuredProducts = [],
  patternBorderTop,
  postType = 'posts',
  variant,
  posts,
  products,
  manualCards,
  isDisabled,
}: CuratedRow) => {
  const isDesktop = useTailwindBreakpoint('md')
  // console.log()
  let actualPosts: (Post | Myth | Product | ManualCard)[] = []

  switch (variant) {
    case 'smart-tag':
      if (postType === 'products') {
        actualPosts = products || []
      } else {
        actualPosts = posts || []
      }
      break
    case 'manual':
      actualPosts = manualCards || []
      break
    case 'featured':
      if (postType === 'products') {
        actualPosts = featuredProducts
      } else {
        actualPosts = featuredPosts
      }
      break
    default:
      break
  }

  if (!actualPosts?.length) return null

  const firstPost = actualPosts[0]
  const excludedFirstPost = actualPosts.slice(1)
  const carouselPosts = !isDesktop ? excludedFirstPost : actualPosts

  const isProduct = (post: any): post is Product => post._type === 'product'
  const isManualCard = (post: any): post is ManualCard => variant === 'manual'

  if (isDisabled) return null

  return (
    <div className="relative overflow-hidden py-10 lg:py-20">
      {patternBorderTop && <Divider type="solid-diamond" className="mb-20" />}
      <div className="grid-container container">
        {(title || subtitle) && (
          <div className="col-span-full mb-12 xl:col-start-3 xl:-col-end-1">
            {subtitle && <p className="h3 mb-2">{subtitle}</p>}
            {title && <h2 className="h5">{title}</h2>}
          </div>
        )}
        <div className="col-span-full mb-5 block sm:hidden">
          {isManualCard(firstPost) ? (
            <BenefitsCard {...(firstPost as ManualCard)} />
          ) : isProduct(firstPost) ? (
            <ProductCard data={firstPost as Product} variant="full" className="mb-5" />
          ) : (
            <BlogCard {...(firstPost as Post | Myth)} variant="carousel" className="mb-5" />
          )}

          <Divider type="scalable-line-diamonds" />
        </div>
        <Swiper
          slidesPerView={1.2}
          breakpoints={
            {
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 4,
              },
            } as any
          }
          modules={[Navigation]}
          spaceBetween={30}
          speed={500}
          loop
          className={`container relative col-span-full w-full xl:col-span-16 xl:col-start-3 [&&]:overflow-visible xl:[&_.swiper-slide-active+.swiper-slide+.swiper-slide+.swiper-slide>*]:opacity-100 lg:[&_.swiper-slide-active+.swiper-slide+.swiper-slide>*]:opacity-100 md:[&_.swiper-slide-active+.swiper-slide>*]:opacity-100`}
        >
          {[...carouselPosts, ...carouselPosts, ...carouselPosts, ...carouselPosts].map(
            (post, i) => {
              return (
                <SwiperSlide key={i}>
                  {({ isActive }) =>
                    isManualCard(firstPost) ? (
                      <BenefitsCard {...post} className={isActive ? 'opacity-100' : 'opacity-30'} />
                    ) : isProduct(firstPost) ? (
                      <ProductCard
                        data={post as Product}
                        variant="full"
                        className={isActive ? 'opacity-100' : 'opacity-30'}
                      />
                    ) : (
                      <BlogCard
                        {...(post as Post | Myth)}
                        variant="carousel"
                        className={isActive ? 'opacity-100' : 'opacity-30'}
                      />
                    )
                  }
                </SwiperSlide>
              )
            },
          )}
          <span
            slot="container-end"
            className={cn({
              'md:hidden': carouselPosts.length < 3,
              'lg:hidden': carouselPosts.length < 4,
              'xl:hidden': carouselPosts.length < 5,
            })}
          >
            <SliderNavigation type="prev" />
            <SliderNavigation type="next" />
          </span>
        </Swiper>
        {cta && cta.label && (
          <Button
            variant="secondary"
            className="col-span-full mt-9 justify-self-start xl:col-start-3 xl:col-end-[-1]"
            asChild
          >
            <CallToAction {...cta} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default CuratedRow
