import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '@/tailwind.config.js'

import useMediaQuery from './use-media-query'

const {
  theme: { screens },
} = resolveConfig(tailwindConfig)

const useTailwindBreakpoint = (breakpoint: keyof typeof screens) => {
  const isMatched = useMediaQuery(`(min-width: ${screens[breakpoint]})`)
  return isMatched
}

export default useTailwindBreakpoint
