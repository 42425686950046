'use client'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import SliderNavigation from '@/components/ui/slider-navigation'
import type { RelatedGuideContent } from '@/types'

import CallToAction from '../shared/call-to-action'
import Media from '../shared/media'
import Button from '../ui/button'

function RelatedGuideContent({ backgroundMedia, tag, links, cta }: RelatedGuideContent) {
  return (
    <section className="relative grid h-full max-h-[45rem] min-h-[33.75rem] grid-cols-6 content-center overflow-hidden md:grid-cols-20">
      {!!backgroundMedia && (
        <div className="aspect-h-16 aspect-w-9 col-span-full md:aspect-w-16 lg:aspect-h-9 after:absolute after:inset-0 after:block after:bg-[#000000]/35">
          <Media data={backgroundMedia} cover className="absolute" />
        </div>
      )}
      <div className={`absolute inset-0 flex items-center justify-center py-6 md:py-10`}>
        <div className="container flex h-full flex-col items-center">
          {tag && <p className="tag mx-auto mt-auto text-white md:mb-11">{tag}</p>}
          <Swiper
            slidesPerView={1}
            breakpoints={
              {
                768: {
                  slidesPerView: 3,
                  spaceBetween: 80,
                },
              } as any
            }
            modules={[Navigation]}
            speed={500}
            loop
            className={`mb-auto w-full min-w-0 max-w-[50rem] !pt-4`}
          >
            {links &&
              links.map((link, i) => (
                <SwiperSlide key={link._type + '-' + i} className="text-center">
                  <CallToAction
                    {...link}
                    className="xl-text relative text-white after:absolute after:inset-x-0 after:bottom-1 after:h-[3px] after:bg-white after:opacity-0 after:transition-opacity after:duration-300 hover:after:opacity-100"
                  ></CallToAction>
                </SwiperSlide>
              ))}
            <span slot="container-end">
              <SliderNavigation
                shape="round"
                type="prev"
                className="ml-5 flex -translate-y-8 md:hidden"
              />
              <SliderNavigation
                shape="round"
                type="next"
                className="mr-5 flex -translate-y-8 md:hidden"
              />
            </span>
          </Swiper>
          {cta && (
            <Button variant="transparent" asChild>
              <CallToAction {...cta} />
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}

export default RelatedGuideContent
