'use client'
import { useState } from 'react'

import type { AwardsAndAchievements } from '@/types'

import { CustomPortableText } from '../../shared/custom-portable-text'
import Button from '../../ui/button'
import AwardsAndAchievementsCard from './awards-and-achievements-card'

const AwardsAndAchievements = ({
  copyBlock,
  featuredAwards,
  awards,
  isDisabled,
}: AwardsAndAchievements) => {
  const [awardsToShow, setAwardsToShow] = useState(featuredAwards)

  const handleClick = () => {
    const remainingAwards = awards.filter((award) => award._id !== awardsToShow[0]._id)
    const shuffledAwards = remainingAwards.sort(() => 0.5 - Math.random())
    const newFirstAward = shuffledAwards[0]
    const newAwardsToShow = [newFirstAward, ...shuffledAwards.slice(1, 2), awardsToShow[0]]
    setAwardsToShow(newAwardsToShow)
  }

  if (isDisabled) return null

  return (
    <section className="container mb-[40px] flex h-full gap-4 py-[47px] md:mb-[0] lg:py-[83px]">
      <div className="flex min-h-[500px] flex-col justify-between gap-6 lg:h-full lg:gap-9">
        <div className="w-full lg:w-[394px]">
          {copyBlock?.subtitle && <h3 className="h3 mb-2">{copyBlock.subtitle}</h3>}
          {copyBlock?.title && <h4 className="h4">{copyBlock.title}</h4>}
          {copyBlock?.copy && (
            <div className="mt-6">
              <CustomPortableText value={copyBlock.copy} />
            </div>
          )}
        </div>

        <div className="block w-fit lg:hidden">
          <AwardsAndAchievementsCard {...awardsToShow[0]} />
        </div>

        <Button variant="secondary" className="mb-6 self-start" onClick={handleClick}>
          {copyBlock?.cta?.label ? copyBlock?.cta?.label : 'See More'}
        </Button>
      </div>
      <div className="ml-[-100px] hidden w-full grid-cols-1 gap-4 gap-x-[30px] gap-y-[42px] lg:grid lg:grid-cols-2">
        {awardsToShow.map((award) => (
          <AwardsAndAchievementsCard key={award._id} {...award} />
        ))}
      </div>
    </section>
  )
}

export default AwardsAndAchievements
