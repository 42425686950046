'use client'
import 'swiper/css'
import 'swiper/css/navigation'

import { useState } from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { cn } from '@/lib/utils'
import type { GallerySlider } from '@/types'

import Media from '../shared/media'
import MediaCaption from '../shared/media-caption'
import SliderNavigation from '../ui/slider-navigation'

interface GallerySliderProps extends GallerySlider {
  isMyth?: boolean
}

const GallerySlider = ({ medias, isMyth, isDisabled }: GallerySliderProps) => {
  const [activeIdx, setActiveIdx] = useState<number>(0)

  if (!medias?.length) return null

  if (isDisabled) return null

  return (
    <div
      className={cn('relative overflow-hidden bg-beige', {
        'blog-module-spacing': isMyth,
      })}
    >
      <Swiper
        spaceBetween={25}
        slidesPerView="auto"
        centeredSlides={true}
        breakpoints={{
          768: {
            spaceBetween: 120,
          },
        }}
        wrapperClass="py-10 lg:py-20"
        speed={500}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        loop
        onSlideChange={(swiper) => setActiveIdx(swiper.realIndex)}
      >
        {[...medias, ...medias].map((media, index) => (
          <SwiperSlide
            key={index}
            className="[&&]:flex [&&]:h-auto [&&]:w-auto [&&]:items-center [&&]:overflow-visible"
          >
            {({ isActive }) => {
              return (
                <Media
                  data={media}
                  className={cn(
                    'h-auto w-[50vh] max-w-[80vw] transition-opacity duration-500 ease-in-out',
                    isActive ? 'opacity-100' : 'opacity-40',
                  )}
                  captionClassName="hidden"
                />
              )
            }}
          </SwiperSlide>
        ))}

        {medias.map(
          (media, index) =>
            media.caption && (
              <MediaCaption
                key={index}
                {...media.caption}
                className={cn(
                  'bottom-0 left-0 right-0 mx-auto w-[50vh] max-w-[80vw] pb-4 text-center transition-opacity duration-500 ease-in-out lg:pb-8',
                  activeIdx === index ? 'opacity-1' : 'opacity-0',
                )}
              />
            ),
        )}

        <span slot="container-end">
          <SliderNavigation type="prev" />
          <SliderNavigation type="next" />
        </span>
      </Swiper>
    </div>
  )
}

export default GallerySlider
