'use client'

import { useMemo, useRef } from 'react'

import Media from '@/components/shared/media'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'

import Divider from '../ui/divider'
import { BlogHeaderProps } from './blog-header'

const GiftingHeader = ({ title, category, heroMedia }: BlogHeaderProps) => {
  const ref = useRef(null)
  const isVisible = useIsOnscreen({ ref })

  const mediaClassName = useMemo(
    () =>
      cn(
        'transition-opacity delay-200 duration-500 after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:to-[black]/60',
        {
          'opacity-0': !isVisible,
        },
      ),
    [isVisible],
  )

  return (
    <div>
      <div className="relative h-[620px] md:h-[760px] small-phone:h-[calc(100vh-28px)]" ref={ref}>
        {!!heroMedia && <Media data={heroMedia} cover className={mediaClassName} />}
        <div className="absolute bottom-5 w-full text-off-white md:bottom-[79px]">
          <div className="grid-container container">
            <div className="col-span-full col-start-1 md:col-span-12 md:col-start-2">
              <p className="pre-header mb-3 md:mb-4">{category}</p>
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <Divider type="5-line-thickness-increase" />
    </div>
  )
}

export default GiftingHeader
