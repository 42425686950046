'use client'

import { useEffect, useRef, useState } from 'react'

import Icon from '@/components/ui/icon'
import { cn } from '@/lib/utils'

import SocialButtons from './social-buttons'

interface BookmarkShareProps {
  className?: string
  iconClassName?: string
  buttonClassName?: string
  isBookmarkVisible?: boolean
  socialClassName?: string
  showBtnTextOnMobile?: boolean
}

const BookmarkShare = ({
  className,
  iconClassName,
  buttonClassName,
  socialClassName,
  showBtnTextOnMobile,
  isBookmarkVisible = true,
}: BookmarkShareProps) => {
  const [isSocialButtonsVisible, setIsSocialButtonsVisible] = useState<boolean>(false)
  const shareContainerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (shareContainerRef.current && !shareContainerRef.current.contains(event.target as Node)) {
      setIsSocialButtonsVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={cn('text-sm flex space-x-[23px] md:space-x-[27px]', className)}>
      {isBookmarkVisible && (
        <button
          className={cn('text-gray-400 hover:text-gray-300 flex items-center', buttonClassName)}
        >
          <Icon variant="bookmark" className={`h-[16px] w-[12px] ${iconClassName}`} />
          <span
            className={cn('nav-text-small ml-3 md:ml-3', {
              hidden: !showBtnTextOnMobile,
            })}
          >
            BOOKMARK
          </span>
        </button>
      )}
      <div ref={shareContainerRef} className="relative">
        {isSocialButtonsVisible && (
          <div
            className={cn('absolute -bottom-10 right-14 md:bottom-14 md:right-5', socialClassName)}
          >
            <SocialButtons />
          </div>
        )}
        <button
          onClick={() => setIsSocialButtonsVisible(!isSocialButtonsVisible)}
          className={cn('text-gray-400 hover:text-gray-300 flex items-center', buttonClassName)}
        >
          <Icon variant="share" className={cn('h-[14px] w-[11px]', iconClassName)} />
          <span
            className={cn('nav-text-small ml-3 md:ml-3 md:inline', {
              hidden: !showBtnTextOnMobile,
            })}
          >
            SHARE
          </span>
        </button>
      </div>
    </div>
  )
}

export default BookmarkShare
