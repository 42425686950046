'use client'

import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxgl from 'mapbox-gl'
import Image from 'next/image'
import React, { useEffect, useRef, useState } from 'react'

// import { Geopoint } from '@/sanity/types'

type MapProps = {
  centerLatitude: number
  centerLongitude: number
  pinText: string
  locations?: { lng: number | undefined; lat: number | undefined }[]
  isGuide?: boolean
  overrideZoom?: number
}

mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN || ''

const getStaticMapUrl = (
  centerLongitude: number,
  centerLatitude: number,
  zoom: number,
  width: number,
  height: number,
) => {
  const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN
  const styleId = 'forty-eight-point-one/clwt4tq5o013901qx6gkw4906'

  return `https://api.mapbox.com/styles/v1/${styleId}/static/${centerLongitude},${centerLatitude},${zoom}/${width}x${height}?access_token=${mapboxToken}`
}

const Map: React.FC<MapProps> = ({
  centerLongitude,
  centerLatitude,
  pinText,
  locations,
  isGuide,
  overrideZoom,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const [showMap, setShowMap] = useState(isGuide)

  useEffect(() => {
    if (!mapContainer.current || !showMap) return

    const map = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: 'mapbox://styles/forty-eight-point-one/clwt4tq5o013901qx6gkw4906',
      center: [centerLongitude, centerLatitude],
      zoom: overrideZoom ? overrideZoom : 15,
      attributionControl: false,
      cooperativeGestures: true
    })

    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
    
    const isMobile = window.innerWidth <= 768

    if (isMobile) {
      map.scrollZoom.disable()
      map.touchZoomRotate.enable({ around: 'center' })
    } else {
      // Enable normal dragging on desktop
      map.dragPan.enable()
    }

    if (locations?.length) {
      locations?.forEach((location, index) => {
        const markerEl = document.createElement('div')
        const markerText = document.createElement('div')

        markerEl.className = 'relative flex flex-col items-center'
        markerText.className = 'text-black font-sans translate-y-[34px]'

        markerText.textContent = (index + 1).toString()
        markerText.style.fontSize = '12px'
        markerText.style.color = 'white'
        markerText.style.zIndex = '2'

        const markerIcon = document.createElement('div')
        markerIcon.className = 'w-6 h-12'
        markerIcon.style.backgroundImage = "url('/assets/icons/map-pin.svg')"

        markerEl.appendChild(markerText)
        markerEl.appendChild(markerIcon)

        new mapboxgl.Marker(markerEl).setLngLat([location.lng!, location.lat!]).addTo(map)
      })
    } else {
      const markerEl = document.createElement('div')
      const markerText = document.createElement('div')

      markerEl.className = 'relative flex flex-col items-center'
      markerText.className = 'text-black p-1 mb-4 font-futuracond h-[20px]'

      markerText.textContent = pinText
      markerText.style.fontSize = '28px'

      const markerIcon = document.createElement('div')
      markerIcon.className = 'w-6 h-12 -translate-y-2'
      markerIcon.style.backgroundImage = "url('/assets/icons/map-pin.svg')"

      markerEl.appendChild(markerText)
      markerEl.appendChild(markerIcon)

      new mapboxgl.Marker(markerEl).setLngLat([centerLongitude, centerLatitude]).addTo(map)
    }

    return () => map.remove()
  }, [centerLongitude, centerLatitude, pinText, showMap, overrideZoom, locations])

  const staticMapUrl = getStaticMapUrl(centerLongitude, centerLatitude, 15, 532, 532)

  return (
    <div className="relative">
      {showMap ? (
        isGuide ? (
          <div className="h-[calc(100vh-1px-var(--header-height))] w-full" ref={mapContainer} />
        ) : (
          <div className="aspect-square" ref={mapContainer} />
        )
      ) : (
        <div className="relative cursor-pointer" onClick={() => setShowMap(true)}>
          <Image
            src="/assets/icons/map-pin.png"
            width={24}
            height={48}
            alt=""
            className="absolute left-[calc(50%-15px)] top-1/2 z-10"
          />
          <div className="absolute top-[calc(50%-35px)] z-10 w-full text-center font-futuracond text-[28px] text-black">
            {pinText}
          </div>
          <Image src={staticMapUrl} alt="Static Map" width={1024} height={1024} />
        </div>
      )}
    </div>
  )
}

export default Map
