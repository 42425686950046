"use client"

import { slugifyTitle } from '@/lib/utils'
export function ContactSkipTo({ enquiries, contactCafe, chitChat, followUsLinks }) {
  const handleScroll = (event, href) => {
    event.preventDefault()
    const targetElement = document.querySelector(href)
    const headerOffset = 86

    if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <ul className="flex flex-row gap-10 pb-2 lg:flex-col lg:gap-0 lg:pb-0">
      <h3 className="tag hidden lg:block">skip to</h3>
      <li className="list-none pt-2">
        <a
          className="pr-body-2 text-18 font-serif"
          href={`#${slugifyTitle(chitChat)}`}
          onClick={(e) => handleScroll(e, `#${slugifyTitle(chitChat)}`)}
        >
          {chitChat}
        </a>
      </li>
      <li className="list-none pt-2">
        <a
          className="pr-body-2 text-18 font-serif"
          href={`#${slugifyTitle(enquiries)}`}
          onClick={(e) => handleScroll(e, `#${slugifyTitle(enquiries)}`)}
        >
          {enquiries}
        </a>
      </li>
      <li className="list-none pt-2">
        <a
          className="pr-body-2 text-18 font-serif"
          href={`#${slugifyTitle(contactCafe)}`}
          onClick={(e) => handleScroll(e, `#${slugifyTitle(contactCafe)}`)}
        >
          {contactCafe}
        </a>
      </li>
      <li className="mr-2 list-none pt-2">
        <a
          className="pr-body-2 mr-4 text-18 font-serif"
          href={`#${slugifyTitle(followUsLinks)}`}
          onClick={(e) => handleScroll(e, `#${slugifyTitle(followUsLinks)}`)}
        >
          {followUsLinks}
        </a>
      </li>
    </ul>
  )
}
