'use client'

import { useMemo, useRef } from 'react'

import Media from '@/components/shared/media'
import OrnamentHeading from '@/components/shared/ornament-heading'
import ReadAndListen from '@/components/shared/read-and-listen-box'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'

import { BlogHeaderProps } from './blog-header'

const BlogHeaderBackgroundMedia = ({
  title,
  category,
  excerpt,
  heroMedia,
  readingTime,
  storyAudio,
}: BlogHeaderProps) => {
  const ref = useRef(null)
  const isVisible = useIsOnscreen({ ref })

  const mediaClassName = useMemo(
    () =>
      cn(
        'transition-opacity delay-200 duration-500 after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:to-[black]/60',
        {
          'opacity-0': !isVisible,
        },
      ),
    [isVisible],
  )

  return (
    <div className="relative h-800 max-h-screen small-phone:h-screen" ref={ref}>
      {!!heroMedia && <Media data={heroMedia} cover className={mediaClassName} />}

      <div className="absolute left-0 top-0 h-full w-full">
        <div className="container grid h-full w-full grid-cols-20 gap-3">
          <div className="col-span-20 h-full p-6 text-white lg:col-span-6 lg:col-start-2">
            <div className="flex h-full flex-col items-center justify-end py-10 lg:justify-center">
              <OrnamentHeading
                className="text-white"
                title={title}
                style="style-4"
                excerpt={excerpt}
                subtitle={category}
              />
              <ReadAndListen
                readingTime={readingTime}
                storyAudio={storyAudio}
                className="mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogHeaderBackgroundMedia
