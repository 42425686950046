'use client'

import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { useRef } from 'react'

import { theme } from '@/tailwind.config.js'
import type { Intro } from '@/types'

import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'
import Divider from '../ui/divider'

const Intro = ({ title, copy, medias, isDisabled }: Intro) => {
  const [line1, line2] = title ? title.split('\n') : ''
  const line1Words = line1 ? line1.split(' ') : []
  const line2Words = line2 ? line2.split(' ') : []
  const titleRef = useRef(null)
  const activeColor = (theme?.colors?.['default-text-color'] as string) ?? 'black'
  const [media1, media2] = medias ?? []

  useGSAP(
    () => {
      if (!titleRef.current) return

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: titleRef.current,
          start: 'top 67%',
          end: 'bottom 30%',
          scrub: true,
        },
      })
      tl.to('.title-line span', { color: activeColor, stagger: 0.2 })
    },
    { scope: titleRef, dependencies: [activeColor] },
  )

  if (isDisabled) return null

  return (
    <>
      <Divider type="7-line" />
      <section id="intro" className="container relative pb-12 pt-20 md:pb-16 md:pt-24 lg:px-20">
        <div className="mobile-container">
          {title && (
            <h2
              className="intro-title prose-text normal-case text-default-intro-color xl:mx-16"
              ref={titleRef}
            >
              {line1Words.length && (
                <span className="title-line md:block md:pb-2.5">
                  {line1Words.map((word, i) => (
                    <span key={i}>{word} </span>
                  ))}
                </span>
              )}
              {line2Words && (
                <span className="title-line md:block md:pb-2.5 md:pl-36 xl:mx-16">
                  {line2Words.map((word, i) => (
                    <span key={i}>{word} </span>
                  ))}
                </span>
              )}
            </h2>
          )}
          <div className="mt-14 grid grid-cols-6 md:mt-20 md:grid-cols-20 md:gap-y-24">
            {!!media1 && (
              <Media
                data={media1}
                className="col-span-5 col-start-2 md:col-span-9 md:col-start-1 md:row-span-2 md:self-center xl:col-span-8 xl:col-start-2 xl:self-end"
                captionClassName="-translate-y-full -rotate-90 origin-bottom-left md:translate-y-0 md:rotate-0 md:origin-center "
              />
            )}

            {copy && (
              <CustomPortableText
                value={copy}
                paragraphClasses="paragraph [&:not(:last-child)]:mb-1em"
                className="mobile-container col-span-full mt-10 text-justify md:col-span-10 md:col-start-11 md:mt-0 xl:col-span-7 xl:col-start-12"
              />
            )}
            {!!media2 && (
              <Media data={media2} className="hidden md:col-span-5 md:col-start-15 md:block" />
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Intro
