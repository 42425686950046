import Image from 'next/image'
import Link from 'next/link'

import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import type { Product } from '@/types'

// import { AddToBasket, WishlistButton } from '../ui/button'
import TagLabel from '../ui/tag-label'

interface ProductCardProps {
  data: Product
  className?: string
  variant?: 'full' | 'condensed' | 'callout' // full has price and smaller font size
  showLabel?: boolean
}

const ProductCard = ({ data, className, variant = 'full', showLabel = true }: ProductCardProps) => {
  const { slug, store, title, flashTag } = data
  const { priceRange, previewImageUrl } = store
  const { minVariantPrice, maxVariantPrice } = priceRange

  const price =
    minVariantPrice === maxVariantPrice
      ? `£${Number(minVariantPrice).toFixed(2)}`
      : `£${Number(minVariantPrice).toFixed(2)} - £${Number(maxVariantPrice).toFixed(2)}`

  return (
    <Link
      href={resolveHref({
        _type: 'product',
        slug: slug,
      })}
      className={cn(
        'group relative block transition-opacity duration-300',
        {
          'flex gap-2 md:flex-col': variant === 'callout',
        },
        className,
      )}
      target="_blank"
    >
      <div
        className={cn('relative mb-3 aspect-square', {
          'mb-0 w-[100px] shrink-0 md:w-auto': variant === 'callout',
        })}
      >
        {showLabel && flashTag?.title && (
          <TagLabel className="absolute -left-1 top-2 z-20 bg-turquoise">
            {flashTag?.title}
          </TagLabel>
        )}
        {/* <AddToBasket
          variant="ghost"
          className="cta absolute bottom-[7px] left-2 z-20 whitespace-nowrap border-none bg-beige bg-none px-4 py-2.5 no-underline opacity-0 transition-opacity group-hover:opacity-100"
        /> */}
        {/* <WishlistButton className="absolute right-[7px] top-2 z-20 bg-default-bg-color opacity-0 transition-opacity duration-200 group-hover:opacity-100" /> */}
        <Image
          src={previewImageUrl}
          alt="product image"
          className="absolute inset-0 object-cover"
          fill={true}
        />
      </div>
      <div
        className={cn('', {
          'flex flex-col md:flex-row md:justify-between': variant === 'callout',
        })}
      >
        <h4
          className={cn('hover-underline group-hover-underline normal-case', {
            paragraph: variant === 'condensed',
            body: variant === 'full' || variant === 'callout',
            'leading-4 md:leading-normal': variant === 'callout',
          })}
        >
          {title}
        </h4>
        {(variant === 'full' || variant === 'callout') && price && (
          <p className="label-1">{price}</p>
        )}
      </div>
    </Link>
  )
}

export default ProductCard
