import { forwardRef } from 'react'

import { useSwiperReactive } from '@/hooks/use-swiper-reactive'
import { cn } from '@/lib/utils'

type SliderNavigationProps = {
  type: 'next' | 'prev'
  className?: string
  hideDisabled?: boolean
  variant?: 'light' | 'dark'
  isDisabled?: boolean
  shape?: 'rectangle' | 'round'
  isNavigation?: boolean
  size?: 'small'
}

type Ref = HTMLButtonElement

const SliderNavigation = forwardRef<Ref, SliderNavigationProps>(
  (
    {
      type,
      className,
      hideDisabled,
      isDisabled,
      variant,
      shape = 'rectangle',
      isNavigation = false,
      size,
    },
    ref,
  ) => {
    SliderNavigation.displayName = 'SliderNavigation'
    const swiper = useSwiperReactive()
    // const isDisabled = !swiper.loopedSlides && type === 'next' ? swiper.isEnd : swiper.isBeginning
    const hide = hideDisabled && isDisabled

    const handleClick = () => {
      if (type === 'next') {
        swiper.slideNext()
      } else {
        swiper.slidePrev()
      }
    }

    const buttonClasses = cn(
      'absolute top-1/2 z-50 hidden -translate-y-1/2 transform cursor-pointer items-center justify-center border border-solid border-beige-dark px-5 py-4 transition-opacity duration-300 before:absolute before:top-1/2 before:h-[30vh] before:w-[12vw] before:-translate-y-1/2 hover:opacity-100 md:flex',
      {
        'cursor-default opacity-0 hover:opacity-0': isDisabled,
        'border-dark-beige': !isDisabled,
        'left-[calc(50%-50vw)] -translate-x-0 before:left-0': type === 'prev' && !isNavigation,
        'right-[calc(50%-50vw)] translate-x-0 before:right-0': type === 'next' && !isNavigation,
        'left-0 before:hidden': type === 'prev' && isNavigation,
        'right-0 before:hidden': type === 'next' && isNavigation,
        // 'opacity-0': hide,
        'bg-beige text-black [&_path]:fill-black [&_rect]:fill-black': variant === 'light',
        'border border-beige-dark bg-black text-white [&_path]:fill-white [&_rect]:fill-white':
          variant === 'dark',
        'border-default-text-color bg-default-bg-color text-default-text-color [&_path]:fill-default-text-color [&_rect]:fill-default-text-color':
          !variant,
      },
      className,
    )
    const iconClasses = type === 'prev' ? 'rotate-180' : ''

    return (
      <button
        ref={ref}
        onClick={handleClick}
        className={shape === 'rectangle' ? buttonClasses : cn(buttonClasses, 'border-0 bg-red p-0')}
        aria-label={type}
        disabled={isDisabled}
      >
        {shape === 'rectangle' ? (
          size === 'small' ? (
            <svg
              width="40"
              height="10"
              viewBox="0 0 40 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${iconClasses}`}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 4.49225V4.50024H5V5.50024H21.9881L19 7.5L16 9.50031L0 9.50055L4 6H2L3 5L2 4H4L0 0.500244L16 0.5L19 2.5L22 4.49225Z"
                fill="#353839"
              />
              <path
                d="M29 0.499817L32 4.49188H22V5.49188H32L29 9.5L40 5L29 0.499817Z"
                fill="#353839"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="9"
              viewBox="0 0 88 9"
              fill="none"
              className={`h-6 w-20 ${iconClasses}`}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 3.99225V4.00024H5V5.00024H21.9881L19 7L16 9.00031L0 9.00055L4 5.5H2L3 4.5L2 3.5H4L0 0.000244141L16 0L19 2L22 3.99225Z"
                fill="#353839"
              />
              <rect x="22" y="4.00024" width="24" height="1" fill="#353839" />
              <rect x="46" y="4.00024" width="24" height="1" fill="#353839" />
              <path d="M77 0L80 3.99207H70V4.99207H80L77 9.00018L88 4.50018L77 0Z" fill="#353839" />
            </svg>
          )
        ) : variant === 'dark' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="9"
            viewBox="0 0 88 9"
            fill="none"
            className={`h-12 w-12 ${iconClasses}`}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 3.99225V4.00024H5V5.00024H21.9881L19 7L16 9.00031L0 9.00055L4 5.5H2L3 4.5L2 3.5H4L0 0.000244141L16 0L19 2L22 3.99225Z"
              fill="#353839"
            />
            <rect x="22" y="4.00024" width="24" height="1" fill="#353839" />
            <rect x="46" y="4.00024" width="24" height="1" fill="#353839" />
            <path d="M77 0L80 3.99207H70V4.99207H80L77 9.00018L88 4.50018L77 0Z" fill="#353839" />
          </svg>
        ) : (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`h-12 w-12 ${iconClasses}`}
          >
            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#C4BFAF" />
            <path
              d="M19.1717 18.343L30.4853 23.9999L19.1717 29.6563L22 23.9999L19.1717 18.343Z"
              fill="#F0ECE0"
              stroke="#F0ECE0"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </button>
    )
  },
)

SliderNavigation.displayName = 'SliderNavigation'

export default SliderNavigation
