import BlogHeader from '@/components/modules/blog-header'
import Content from '@/components/modules/content'
import ContentContainer from '@/components/shared/content-container'
import TransparentHeaderContainer from '@/components/shared/transparent-header-container'
import type { PagePayload } from '@/types'

import WithPasswordProtection from './page-password-protected'

export interface PageProps {
  data: PagePayload | null
}

export function Page({ data }: PageProps) {
  const isGifting = data?.slug === 'gifting'
  // Default to an empty object to allow previews on non-existent documents
  const {
    password,
    passwordProtected,
    unprotectedContent,
    hero,
    content,
    textColor,
    color,
    contentWithoutBackgroundColor,
  } = data ?? {}

  const { media, title, subtitle, variant, copy, hideNavigationDropdown, navigationDropdownLinks } =
    hero ?? {}

  return (
    <WithPasswordProtection
      slug={data?.slug}
      password={password!}
      passwordProtected={passwordProtected}
      unprotectedContent={unprotectedContent}
    >
      {variant !== 'faq-header' ? (
        <TransparentHeaderContainer>
          <BlogHeader
            title={title}
            subtitle={subtitle}
            heroMedia={media}
            category={subtitle}
            excerpt={copy}
            heroVariant={variant}
            isGifting={isGifting}
            hideNavigationDropdown={hideNavigationDropdown}
            navigationDropdownLinks={navigationDropdownLinks}
          />
        </TransparentHeaderContainer>
      ) : (
        <BlogHeader
          title={title}
          subtitle={subtitle}
          heroMedia={media}
          category={subtitle}
          excerpt={copy}
          heroVariant={variant}
          isGifting={isGifting}
          hideNavigationDropdown={hideNavigationDropdown}
          navigationDropdownLinks={navigationDropdownLinks}
        />
      )}
      <ContentContainer className="bg-beige py-[1px]">
        <div
          className="page-colors bg-default-bg-color text-default-text-color"
          style={{
            ...(color?.hex && {
              ['--default-bg-color' as string]: color?.hex,

              // primary button
              ['--default-primary-button-bg' as string]: color?.hex,

              // secondary button
              ['--default-secondary-button-bg' as string]: color.hex,
            }),

            ...(textColor && {
              ['--default-text-color' as string]: textColor === 'light' ? '#fffdf9' : '#353839',
              ['--default-intro-color' as string]: textColor === 'light' ? '#c4bfaf80' : '#c4bfaf',

              // primary button
              ['--default-primary-button-border' as string]:
                textColor === 'light' ? '#fffdf9' : '#353839',
              ['--default-primary-button-text' as string]:
                color?.hex && textColor === 'dark' ? '#353839' : '#fffdf9',

              // secondary button
              ['--default-secondary-button-border' as string]:
                textColor === 'light' ? '#fffdf9' : '#353839',
              ['--default-secondary-button-hover-border' as string]:
                textColor === 'light' ? '#fffdf9' : '#353839',
              ['--default-secondary-button-text' as string]:
                textColor === 'light' ? '#fffdf9' : '#353839',
              ['--default-secondary-button-after-hover-border' as string]: color?.hex
                ? '#f0ece0'
                : '#353839',
            }),
          }}
        >
          {content && <Content data={content} isHub={true} isGifting={isGifting} />}
        </div>
        {contentWithoutBackgroundColor && (
          <Content data={contentWithoutBackgroundColor} isHub={true} isGifting={isGifting} />
        )}
      </ContentContainer>
    </WithPasswordProtection>
  )
}

export default Page
