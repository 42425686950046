'use client'

import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SocialCarousel as SocialCarouselType } from '@/sanity/types'
import { CallToActionType, Media as MediaType } from '@/types'

import CallToAction from '../shared/call-to-action'
import Media from '../shared/media'
import Button from '../ui/button'
import Divider from '../ui/divider'
import SliderNavigation from '../ui/slider-navigation'

const SocialCarousel = ({
  feeds,
  heading,
  cta,
  isDisabled,
}: Omit<SocialCarouselType, 'cta'> & {
  cta: CallToActionType
}) => {
  const [isUnMuted, setIsUnMuted] = useState(false)

  if (!feeds?.length) return null

  if (isDisabled) return null

  return (
    <>
      <Divider type="outline-diamond" />
      <section className="grid-container container overflow-hidden pb-16 pt-10 sm:pb-20 md:pb-32 lg:pb-40">
        <div className="col-span-4 col-start-1 mb-10 flex flex-col items-start gap-6 sm:mb-14 md:col-span-12 md:flex-row md:items-center lg:col-span-8">
          {heading && <h4>{heading}</h4>}
          {cta && (
            <Button variant="loves" asChild>
              <CallToAction {...cta} />
            </Button>
          )}
        </div>
        <div className="col-span-full">
          <Swiper
            spaceBetween={20}
            slidesPerView={1.5}
            breakpoints={{
              480: {
                slidesPerView: 2.5,
                spaceBetween: 24,
              },
              767: {
                slidesPerView: 3.5,
              },
              1024: {
                slidesPerView: 4.5,
              },
              1280: {
                slidesPerView: 5.5,
              },
            }}
            className="max-w-full [&&]:overflow-visible"
          >
            {feeds.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  {item && (
                    <div className="h-full p-1" onMouseEnter={() => setIsUnMuted(true)}>
                      <Media data={item as MediaType} className="w-full" />
                    </div>
                  )}
                </SwiperSlide>
              )
            })}
            <span slot="container-end">
              <SliderNavigation type="prev" className="left-0" />
              <SliderNavigation type="next" className="right-0" />
            </span>
          </Swiper>
        </div>
      </section>
    </>
  )
}

export default SocialCarousel
