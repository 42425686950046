'use client'
import { useRef } from 'react'

import Divider from '@/components/ui/divider'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import type { KeyPropositions } from '@/types'

import KeyPropositionsCard from './key-propositions-card'

interface KeyPropositionsProps extends KeyPropositions {
  className?: string
}

const KeyPropositions = ({
  subtitle,
  title,
  cards,
  className,
  isDisabled,
}: KeyPropositionsProps) => {
  const ref = useRef(null)
  const isVisible = useIsOnscreen({
    ref,
  })

  const cardClasses = cn(
    'transition-transform duration-1000 sm:transition-opacity sm:duration-1000',
    {
      'translate-x-[calc(100%+1.25rem)] sm:translate-x-0 sm:opacity-0': !isVisible,
      'sm:opacity-1 translate-x-0': isVisible,
    },
  )

  if (isDisabled) return null

  return (
    <section
      className={cn('container overflow-hidden py-10 text-center md:py-[4.5rem]', className)}
    >
      <Divider type="shorter-solid-diamond" className="mx-auto mb-10 md:mb-[4.5rem]" />
      {(title || subtitle) && (
        <div className="mb-10 md:mb-[4.5rem]">
          {subtitle && <p className="pre-header mb-2 md:mb-3">{subtitle}</p>}
          {title && <h2 className="h2 mx-auto text-balance md:max-w-2xl">{title}</h2>}
        </div>
      )}
      <ul
        className="mobile-container grid gap-5 md:max-w-lg lg:flex lg:max-w-full lg:gap-6"
        ref={ref}
      >
        {!!cards?.length &&
          cards.map((card, key) => (
            <KeyPropositionsCard
              key={key}
              {...card}
              className={cardClasses}
              variant={cards?.length < 3 ? 'landscape' : 'portrait'}
              style={{
                transitionDelay: `${key * 200}ms`,
              }}
            />
          ))}
      </ul>
    </section>
  )
}

export default KeyPropositions
