'use client'

import 'swiper/css'

import { PortableTextBlock } from 'next-sanity'
import React, { useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CustomPortableText } from '@/components/shared/custom-portable-text'
import SliderNavigation from '@/components/ui/slider-navigation'
import { cn } from '@/lib/utils'
import type { CollectionSlider } from '@/sanity/types'

import Media from '../shared/media'

const CollectionSlider = ({ collectionCards, preHeader, heading }: CollectionSlider) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [slidesPerView, setSlidesPerView] = useState(1.2)

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex)
  }

  const handleSwiperInit = (swiper) => {
    setSlidesPerView(swiper.params?.slidesPerView)
  }
  return (
    <div className="my-10 md:my-20">
      <div className="grid-container container">
        <div className="col-start-1 col-end-7 mb-7 md:col-end-8">
          {preHeader && <h2 className="h3 mb-2">{preHeader}</h2>}
          {heading && (
            <CustomPortableText
              value={heading as PortableTextBlock[]}
              className="[&>p]:h4 [&>p]:mt-0"
            />
          )}
        </div>
      </div>
      <div className="grid-container container w-full overflow-hidden pr-0">
        <Swiper
          onInit={handleSwiperInit}
          onSlideChange={handleSlideChange}
          slidesPerView={1.2}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
            1280: {
              slidesPerView: 6,
            },
          }}
          modules={[Navigation]}
          spaceBetween={24}
          speed={500}
          className="group col-span-full col-start-1 ml-auto w-full [&&]:overflow-visible"
        >
          {collectionCards &&
            collectionCards?.map((item, index) => {
              const { image, collectionName } = item || {}
              const isVisible = index >= activeIndex && index < activeIndex + slidesPerView

              return (
                <SwiperSlide key={index}>
                  <div className={cn(isVisible ? 'opacity-100' : 'opacity-30')}>
                    {image && (
                      <Media
                        data={image as Media}
                        className="aspect-[4/5] h-full w-full max-w-full object-cover sm:max-w-[220px]"
                      />
                    )}
                    {collectionName && <p className="tag mt-2">{collectionName}</p>}
                  </div>
                </SwiperSlide>
              )
            })}
          <span slot="container-end">
            <SliderNavigation
              type="prev"
              className="translate-x-[-250%] transition-all duration-300 ease-linear group-hover:translate-x-[20%] group-hover:2xl:translate-x-[145%]"
            />
            <SliderNavigation
              type="next"
              className="translate-x-[250%] transition-all duration-300 ease-linear group-hover:-translate-x-[29%] group-hover:2xl:-translate-x-[155%]"
            />
          </span>
        </Swiper>
      </div>
    </div>
  )
}

export default CollectionSlider
