'use client'
import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import Button from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import Icon from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { postCustomer, PostCustomerResponse } from '@/lib/atreemo'
import { cn } from '@/lib/utils'

export const NewsletterFormSchema = z.object({
  name: z.string().min(3, { message: 'Name must be at least 3 characters' }),
  emailAddress: z.string().email(),
  birthday: z
    .date({
      message: 'Please enter valid date.',
    })
    .optional(),
  cafe: z.string().optional(),
  marketingOptIn: z.boolean().refine(
    (value) => {
      return value === true
    },
    { message: 'You must agree to the privacy notice.' },
  ),
})

const cafesOptions = [
  {
    label: 'Battersea',
    value: '10',
  },
  {
    label: 'Canary Wharf',
    value: '9',
  },
  {
    label: 'Carnaby',
    value: '4',
  },
  {
    label: 'Covent Garden',
    value: '1',
  },
  {
    label: 'Kensington',
    value: '6',
  },
  {
    label: "King's Cross",
    value: '3',
  },
  {
    value: '2',
    label: 'Shoreditch',
  },
  {
    label: 'Birmingham',
    value: '8',
  },
  {
    label: 'Edinburgh',
    value: '5',
  },
  {
    label: 'Manchester',
    value: '7',
  },
  {
    label: 'Brighton',
    value: '11',
  },
  {
    label: 'Cambridge',
    value: '12',
  },
  {
    label: 'Oxford',
    value: '13',
  },
]

const NewsletterForm = ({ isMin }: { isMin?: boolean }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<false | string>(false)
  const [response, setResponse] = useState<PostCustomerResponse | null>(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const form = useForm<z.infer<typeof NewsletterFormSchema>>({
    resolver: zodResolver(NewsletterFormSchema),
    defaultValues: {
      name: '',
      emailAddress: '',
      cafe: '',
      marketingOptIn: false,
    },
  })

  async function onSubmit(data: z.infer<typeof NewsletterFormSchema>) {
    try {
      setIsLoading(true)
      setIsError(false)
      const response = await postCustomer(data)

      if (!response || response instanceof Error) {
        throw new Error('Failed to submit form. Please try again!')
      }

      if (!response.ResponseStatus) {
        const message = response.Errors?.map((error) => error.ErrorDescription).join(', ')
        throw new Error(message || 'Failed to submit form. Please try again!')
      }

      setResponse(response)
      form.reset()
    } catch (error) {
      setIsError(error.message || 'Failed to submit form. Please try again!')
      console.log('Failed to submit form', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!response) return

    const timer = setTimeout(() => {
      setResponse(null)
    }, 3000)

    return () => clearTimeout(timer)
  }, [response])

  return (
    <Form {...form}>
      <form className="grid-container gap-x-2 gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="col-span-full">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="relative">
                <FormLabel className="tag absolute -top-[6px] left-3 z-[1] bg-beige px-1 font-bold">
                  First name
                </FormLabel>
                <FormControl>
                  <Input
                    className="focus:ring-unset rounded-none border-beige-dark bg-transparent !outline-none focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                    type="text"
                    placeholder="Kindly enter your name"
                    {...field}
                    required
                    autoComplete=""
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="col-span-full">
          <FormField
            control={form.control}
            name="emailAddress"
            render={({ field }) => (
              <FormItem className="relative">
                <FormLabel className="tag absolute -top-[6px] left-3 z-[1] bg-beige px-1 font-bold">
                  Email address
                </FormLabel>
                <FormControl>
                  <Input
                    className="focus:ring-unset rounded-none border-beige-dark bg-transparent !outline-none focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                    type="email"
                    placeholder="Kindly enter your email address"
                    {...field}
                    required
                    autoComplete=""
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="col-span-full">
          <FormField
            control={form.control}
            name="birthday"
            render={({ field }) => (
              <FormItem className="relative space-y-0">
                <FormLabel className="tag absolute -top-[6px] left-3 z-[1] bg-beige px-1 font-bold">
                  Birthday (optional)
                </FormLabel>
                <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="select"
                        className={cn(
                          !field.value && 'text-neutral-500',
                          'mt-0 h-auto rounded-none border-beige-dark bg-transparent py-2 focus:ring-0 focus:ring-offset-0',
                        )}
                      >
                        {field.value ? format(field.value, 'PPP') : 'For an annual surprise'}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      className="bg-beige pb-10"
                      mode="single"
                      selected={field.value}
                      defaultMonth={field.value || new Date()}
                      onSelect={(e) => {
                        console.log(e)
                        field.onChange(e)
                        setIsCalendarOpen(false)
                      }}
                      hideNavigation
                      captionLayout="dropdown"
                      weekStartsOn={1}
                      disabled={[
                        {
                          after: new Date(),
                        },
                      ]}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="col-span-full">
          <FormField
            control={form.control}
            name="cafe"
            render={({ field }) => (
              <FormItem className="relative">
                <FormLabel className="tag absolute -top-[6px] left-3 z-[1] bg-beige px-1 font-bold">
                  Select your local café
                </FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value} required>
                    <FormControl>
                      <SelectTrigger className="rounded-none border-beige-dark bg-transparent !outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0">
                        <SelectValue placeholder="..." />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="border-gray-light bg-beige">
                      {cafesOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div
          className={cn('col-span-full', {
            // 'md:col-span-12': isMin,
          })}
        >
          <FormField
            control={form.control}
            name="marketingOptIn"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel className="ml-2 cursor-pointer">
                    I consent to receive occasional emails with news about products, restaurants and
                    suchlike, in accordance with Dishoom&apos;s{' '}
                    <Link
                      href="/policies/privacy-policy/"
                      className="whitespace-nowrap underline hover:no-underline"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div
          className={cn('col-span-full', {
            // 'md:col-span-8': isMin,
            // 'mt-4': !isMin,
          })}
        >
          <Button
            className="w-full"
            variant="primary"
            disabled={isLoading}
            aria-disabled={isLoading}
          >
            {isLoading ? <Icon variant="spinner" /> : 'Subscribe'}
          </Button>
        </div>

        {response?.ResponseStatus && (
          <div className="col-span-full pt-3">
            <p>Successfully submitted</p>
          </div>
        )}
        {isError && (
          <div className="col-span-full pt-3">
            <p className="text-red">{isError}</p>
          </div>
        )}
      </form>
    </Form>
  )
}

export default NewsletterForm
