import { useRef, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Divider from '@/components/ui/divider'
import SliderNavigation from '@/components/ui/slider-navigation'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { SLIDER_DURATION } from '@/lib/constants'
import { cn } from '@/lib/utils'
import type { CareersModule } from '@/types'

import Media from '../../shared/media'
import SharedCopyBlock from '../../shared/shared-copy-block'

const CareersModuleDesktop = ({
  fullWidthMedia,
  copyBlock,
  sections,
  isDisabled,
}: CareersModule) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const swiperRef = useRef<any>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState(0)

  const onScreen = useIsOnscreen({
    ref: containerRef,
    once: false,
  })

  if (isDisabled) return null

  return (
    <div
      ref={containerRef}
      className="relative hidden h-screen max-h-800 min-h-[600px] overflow-hidden md:block"
    >
      <div className="-z-1 absolute left-0 top-0 h-full w-screen overflow-hidden">
        <Media data={fullWidthMedia} className="transition-translate w-full duration-1000" cover />
      </div>
      <Swiper
        ref={swiperRef}
        className={cn('relative h-full transition-transform duration-1000 ease-out', {
          'translate-x-full': !onScreen,
        })}
        spaceBetween={0}
        modules={[Navigation]}
        onSwiper={(swiper) => setTotalSlides(swiper.slides.length)}
        onSlideChange={({ realIndex }) => setCurrentSlide(realIndex)}
        speed={SLIDER_DURATION}
        loop
      >
        <SwiperSlide className={`py-10 pb-16 pt-24 text-white`}>
          <div className="grid-container container h-full items-center justify-items-center">
            <div className="pointer-events-none absolute inset-y-0 left-1/2 right-0 bg-black">
              <Divider
                type="solid-diamond-inverse"
                className="before:bottom-0 before:left-0 before:top-0 before:h-screen before:w-3"
              />
            </div>
            {copyBlock && (
              <SharedCopyBlock
                title={copyBlock.title}
                subtitle={copyBlock.subtitle}
                copy={copyBlock.copy}
                buttonVariant="transparent"
                cta={copyBlock?.cta}
                className="relative col-span-9 col-start-12 lg:col-span-8 lg:col-start-12"
                textPadding="xl:px-9"
                buttonClassName="xl:mt-16"
              />
            )}
          </div>
        </SwiperSlide>
        {sections?.map(({ sectionCopyBlock, media }, key) => {
          if (!sectionCopyBlock) return null
          const { title, subtitle, copy, cta } = sectionCopyBlock

          return (
            <SwiperSlide key={key} className={`bg-black py-10 pb-16 pt-24 text-white`}>
              <div className="grid-container container h-full items-center justify-items-center">
                <Media
                  data={media}
                  aspectRatio="square"
                  className="col-span-10 col-start-1 h-fit lg:col-span-9 lg:col-start-2"
                />
                <SharedCopyBlock
                  title={title}
                  subtitle={subtitle}
                  copy={copy}
                  buttonVariant="transparent"
                  cta={cta}
                  className="col-span-8 col-start-12 lg:col-span-7 lg:col-start-12"
                  textPadding="xl:px-9"
                  buttonClassName="xl:mt-16"
                />
              </div>
            </SwiperSlide>
          )
        })}
        <span slot="container-end">
          <SliderNavigation
            isDisabled={currentSlide === 0}
            type="prev"
            hideDisabled
            variant="dark"
            className="md:top-85 lg:top-1/2"
          />
          <SliderNavigation
            isDisabled={currentSlide === totalSlides - 1}
            type="next"
            hideDisabled
            variant="dark"
            className="md:top-85 lg:top-1/2"
          />
        </span>
      </Swiper>
    </div>
  )
}

export default CareersModuleDesktop
