'use client'
import { usePathname } from 'next/navigation'

import { cn } from '@/lib/utils'
import type { FullWidthMood } from '@/types'

import CallToAction from '../shared/call-to-action'
import Media from '../shared/media'
import Button from '../ui/button'

interface FullWidthMoodProps extends FullWidthMood {
  className?: string
}

const FullWidthMood = ({ cta, media, text, className, isDisabled }: FullWidthMoodProps) => {
  const pathname = usePathname()
  if (isDisabled) return null

  const cafePathnames = [
    '/carnaby/',
    '/kensington/',
    '/canary-wharf/',
    '/covent-garden/',
    '/battersea/',
    '/shoreditch/',
    '/manchester/',
    '/birmingham/',
    '/edinburgh/',
    '/kings-cross/',
  ]

  const isCafePath = cafePathnames.some((cafePath) => pathname.includes(cafePath))

  return (
    <section
      className={cn(
        'relative mb-[50px] h-full max-h-[45rem] min-h-[33.75rem] overflow-hidden',
        {
          'mb-0': isCafePath,
        },
        className,
      )}
    >
      {!!media && (
        <div className="aspect-h-16 aspect-w-9 col-span-full md:aspect-w-16 lg:aspect-h-9 after:absolute after:inset-0 after:block after:bg-[#000000]/35">
          <Media data={media} cover className="absolute" />
        </div>
      )}
      <div className="grid-container container absolute inset-0 z-10 self-end pb-6 md:pb-20">
        <div className="col-span-full lg:col-start-2">
          {text && <p className="prose-text mb-6 text-white md:mb-0">{text}</p>}
          {!!cta && (
            <Button variant="transparent" className="md:mt-8" asChild>
              <CallToAction {...cta} />
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}

export default FullWidthMood
