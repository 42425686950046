'use client'
import { PortableTextBlock } from 'next-sanity'

import {
  Accordion,
  AccordionContent,
  StyledAccordionItem,
  StyledAccordionTrigger,
} from '@/components/ui/accordion'
import { cn } from '@/lib/utils'
import type { MediaOnly } from '@/types'

import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'

type AccordionBlockProps = {
  className?: string
  isEvent?: boolean
  rows?: {
    _key: string
    title: string
    copy?: PortableTextBlock[]
    media?: MediaOnly
  }[]
}

const AccordionBlock = ({ rows, isEvent, className }: AccordionBlockProps) => {
  if (!rows?.length) return null

  return (
    <Accordion type="single" collapsible className={className}>
      {rows.map((item, index) => {
        if (!item?.copy && !item?.media) return null
        return (
          <div key={item._key} className={cn({ 'container px-5 md:px-12 xl:px-20': isEvent })}>
            <StyledAccordionItem value={item._key}>
              <StyledAccordionTrigger>{item.title}</StyledAccordionTrigger>
              <AccordionContent className="pb-6">
                {item.copy && (
                  <CustomPortableText
                    paragraphClasses="body"
                    className="[&>ul]:body space-y-4"
                    value={item.copy}
                  />
                )}
                {item?.media && <Media data={item.media} className="mt-6" />}
              </AccordionContent>
            </StyledAccordionItem>
          </div>
        )
      })}
    </Accordion>
  )
}

export default AccordionBlock
