'use client'

import { PortableTextBlock } from 'next-sanity'
import { useState } from 'react'
import type { Image as ImageType } from 'sanity'

import { cn } from '@/lib/utils'
import type { CollectionsSpotlight } from '@/sanity/types'
import type { CallToActionType } from '@/types'

import CallToAction from '../shared/call-to-action'
import { CustomPortableText } from '../shared/custom-portable-text'
import OrnamentHeading from '../shared/ornament-heading'
import { SanityImage } from '../shared/sanity-image'
import Button from '../ui/button'
import Divider from '../ui/divider'
export type CollecionsProps = Array<{
  content?: {
    title?: string
    images?: Array<{
      image?: {
        previewImage: { image: ImageType }

        hoverImage: { image: ImageType }
      }
      _key: string
    }>
  }
  _key: string
  isDisabled?: boolean
}>
const CollectionsSpotlight = ({
  collections,
  cta,
  description,
  heading,
  subHeading,
  isDisabled,
}: Omit<CollectionsSpotlight, 'collections' | 'cta'> & {
  collections: CollecionsProps
  cta: CallToActionType
  isDisabled?: boolean
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [hovered, setHovered] = useState<boolean | null>(null)
  const generateFormattedIndex = (index: number) => {
    const updatedCount = index + 1
    const formattedIndex = updatedCount < 10 ? `0${updatedCount}` : updatedCount
    return formattedIndex
  }

  if (isDisabled) return null

  return (
    <div className="pb-[50px] pt-[100px] lg:pb-[400px] xl:pb-[350px]">
      <>
        <div className="mb-5 lg:mb-20">
          <Divider type="thick-thin" />
        </div>

        {/* dasktop  */}

        <section className="lg:grid-container container hidden pb-[87px] pt-10">
          <div className="relative pt-[115px] lg:col-start-1 lg:-col-end-1">
            <OrnamentHeading className="text-black" subtitle={heading} style="style-1" />
            <div className="mx-auto w-full max-w-[740px] px-4">
              {collections &&
                collections?.[currentIndex]?.content?.images &&
                collections?.[currentIndex]?.content?.images?.map((images, index) => {
                  const { hoverImage, previewImage } = images?.image || {}
                  return (
                    <div key={index}>
                      {previewImage && (
                        <SanityImage
                          data={hoverImage?.image as ImageType}
                          className={cn(
                            'basic-transition absolute w-full object-cover',
                            hovered ? 'opacity-0' : 'opacity-100',
                            index === 0 &&
                              'left-0 top-[350px] h-[260px] max-w-[200px] xl:left-[-2%] xl:top-[120px] xl:h-[339px] xl:max-w-[254px]',
                            index === 1 &&
                              'right-0 top-[-50px] h-[196px] max-w-[200px] xl:h-[254px] xl:max-w-[254px]',
                            index === 2 &&
                              'right-[100px] top-[500px] h-[259px] max-w-[200px] xl:right-[200px] xl:max-w-[254px]',
                          )}
                        />
                      )}
                      {hoverImage && (
                        <SanityImage
                          data={previewImage?.image as ImageType}
                          className={cn(
                            'basic-transition absolute w-full object-cover',
                            hovered ? 'opacity-100' : 'opacity-0',
                            index === 0 &&
                              'left-0 top-[380px] h-[260px] max-w-[200px] xl:left-[-2%] xl:top-[120px] xl:h-[339px] xl:max-w-[254px]',
                            index === 1 &&
                              'right-0 top-[-50px] h-[196px] max-w-[200px] xl:h-[254px] xl:max-w-[254px]',
                            index === 2 &&
                              'right-[100px] top-[500px] h-[259px] max-w-[200px] xl:right-[200px] xl:max-w-[254px]',
                          )}
                        />
                      )}
                    </div>
                  )
                })}

              <div className={cn('flex flex-wrap justify-center gap-x-6 gap-y-2')}>
                {collections &&
                  collections?.map((item, index) => {
                    const { title } = item?.content || {}
                    const formattedIndex = generateFormattedIndex(index)

                    return (
                      title && (
                        <div
                          key={index}
                          onClick={() => setCurrentIndex(index)}
                          className="flex cursor-pointer items-baseline gap-2"
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <span className="tag block font-bold"> {formattedIndex}.</span>
                          <span
                            className={cn(
                              'xl-text',
                              currentIndex === index ? 'border-b-[2px]' : '',
                              hovered ? 'border-black' : 'border-transparent',
                            )}
                          >
                            {' '}
                            {title}
                          </span>
                        </div>
                      )
                    )
                  })}
              </div>
              {description && (
                <CustomPortableText
                  value={description as PortableTextBlock[]}
                  paragraphClasses="paragraph [&:not(:last-child)]:mb-1em"
                  className={cn(
                    'basic-transition absolute bottom-[-90%] left-0 mt-[350px] hidden w-full max-w-[670px] lg:block xl:bottom-[-50%] xl:mt-40',
                    hovered ? 'opacity-100' : 'opacity-0',
                  )}
                />
              )}
            </div>
            <div className="flex justify-center pt-[1.875rem] md:pt-6">
              {cta && (
                <Button variant="secondary" asChild>
                  <CallToAction {...cta} />
                </Button>
              )}
            </div>
          </div>
        </section>

        {/* dasktop  */}

        {/* mobile  */}

        <section className="container relative block pb-10 pt-10 lg:hidden">
          <OrnamentHeading
            className="![&>p]:font-sans [&>p]:tag text-black"
            subtitle={subHeading}
            title={heading}
            subtitleVariant="pre-header"
            style="style-1"
          />
          <div className="relative columns-2 gap-[24px]">
            {collections &&
              collections?.map((item, index) => {
                const { images, title } = item?.content || {}
                const isEven = index % 2 === 0
                return (
                  images &&
                  images?.length > 0 &&
                  images[0] && (
                    <div className="mb-4 break-inside-avoid" key={index}>
                      <div className={`w-full border-[3px] border-black ${isEven ? 'p-1' : 'p-0'}`}>
                        {images?.[currentIndex]?.image?.previewImage?.image && (
                          <SanityImage
                            data={images[0]?.image?.previewImage?.image as ImageType}
                            className="w-full [&>div>div]:relative [&>div]:before:aspect-auto"
                          />
                        )}
                      </div>
                      {title && (
                        <p className="block pt-1 font-sans text-[14px] font-bold uppercase tracking-[2.1px] text-black sm:text-[16px]">
                          {title}
                        </p>
                      )}
                    </div>
                  )
                )
              })}
          </div>
          {cta && (
            <Button
              variant="secondary"
              className="mx-auto mt-10 flex w-full items-center justify-center md:w-fit"
              asChild
            >
              <CallToAction {...cta} />
            </Button>
          )}
        </section>

        {/* mobile  */}
      </>
    </div>
  )
}

export default CollectionsSpotlight
