'use client'
import Link from 'next/link'
import { useRef } from 'react'

import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import type { FullWidthFeature } from '@/types'

import Media from '../shared/media'
import OrnamentHeading from '../shared/ornament-heading'
import CursorTooltipWrapper from '../ui/cursor-tooltip-wrapper'

const FullWidthFeature = ({
  layout,
  post,
  secondaryMedia,
  variant,
  cursorTooltip,
  tooltipCaption,
  isDisabled,
}: FullWidthFeature) => {
  const ref = useRef(null)
  const { featuredMedia, title, excerpt, _type } = post
  const category = 'category' in post ? post.category : undefined
  const isVisible = useIsOnscreen({
    ref,
  })

  if (isDisabled) return null

  return (
    <CursorTooltipWrapper isVisible={!!cursorTooltip} text={tooltipCaption}>
      <div className={`${variant === 'inset' ? 'container' : ''}`} ref={ref}>
        <Link
          href={resolveHref(post)}
          className="relative block h-[560px] lg:h-[43vw] lg:min-h-[560px] xl:min-h-[620px]"
        >
          <Media
            data={featuredMedia}
            cover
            className={cn(
              'items-center transition-opacity delay-200 duration-500 after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:to-[black]/60',
              {
                'opacity-0': !isVisible,
              },
            )}
          />
          {variant === 'with-secondary-media' ? (
            <>
              <div
                className={cn(
                  'grid-container container absolute bottom-14 left-0 right-0 hidden grid-flow-dense grid-cols-20 items-center text-center text-white transition-opacity delay-500 duration-500 md:bottom-16 md:top-16 md:grid lg:bottom-20 lg:top-20',
                  {
                    'opacity-0': !isVisible,
                  },
                )}
              >
                <div
                  className={`col-span-8 ${layout === 'text-media' ? 'col-start-2' : 'col-start-13'}`}
                >
                  <OrnamentHeading
                    title={title}
                    style="style-4"
                    excerpt={excerpt}
                    subtitle={_type === 'event' ? 'event' : category?.split('-').join(' ')}
                    isReadMoreVisible
                  />
                </div>
                <div
                  className={`relative col-span-5 ${layout === 'media-text' ? 'col-start-2' : 'col-start-13'}`}
                >
                  {secondaryMedia && (
                    <Media
                      cover
                      data={secondaryMedia}
                      className="h-full w-full"
                      aspectRatio="portrait"
                    />
                  )}
                </div>
              </div>
              <OrnamentHeading
                title={title}
                style="style-4"
                excerpt={excerpt}
                subtitle={_type === 'event' ? 'event' : category?.split('-').join(' ')}
                isReadMoreVisible
                className={cn(
                  'absolute bottom-10 left-1/2 -translate-x-1/2 text-center text-white transition-opacity delay-500 duration-500 md:bottom-16 md:hidden lg:bottom-20',
                  {
                    'opacity-0': !isVisible,
                  },
                )}
              />
            </>
          ) : (
            <OrnamentHeading
              title={title}
              style="style-4"
              excerpt={excerpt}
              subtitle={_type === 'event' ? 'event' : category?.split('-').join(' ')}
              isReadMoreVisible
              className={cn(
                'absolute bottom-14 left-1/2 -translate-x-1/2 text-center text-white transition-opacity delay-500 duration-500 md:bottom-16 lg:bottom-20',
                {
                  'opacity-0': !isVisible,
                },
              )}
            />
          )}
        </Link>
      </div>
    </CursorTooltipWrapper>
  )
}

export default FullWidthFeature
