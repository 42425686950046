'use client'

import { useEffect, useRef, useState } from 'react'

import Divider from '@/components/ui/divider'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import { MediaOnly, NewsletterCta } from '@/types'

import Media from '../../shared/media'
import Button from '../../ui/button'
import NewsletterTray from './newsletter-tray'

type NewsletterCtaProps = NewsletterCta & {
  className?: string
}

const NewsletterCTA = ({ media, text, ctaLabel, className }: NewsletterCtaProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useIsOnscreen({
    ref,
    once: false,
  })

  const toggleIsOpened = () => {
    setIsOpened(!isOpened)
  }

  useEffect(() => {
    if (!isVisible && isOpened) {
      setIsOpened(false)
    }
  }, [isOpened, isVisible])

  return (
    <section className="mb-[50px]">
      <div
        className={cn(
          'relative min-h-[390px] max-w-full overflow-hidden md:min-h-[500px] xl:min-h-[600px]',
          className,
        )}
        ref={ref}
      >
        {!!media && (
          <Media
            data={media}
            cover
            className="absolute inset-0 after:absolute after:inset-0 after:block after:bg-[#000000]/35"
          />
        )}
        <div className="grid-container align-items-center container absolute bottom-0 z-10 md:relative">
          <div className="col-span-full row-start-1 self-end pb-6 md:col-span-10 md:pb-20 lg:col-span-9 lg:col-start-2">
            {text && <p className="prose-text mb-6 text-balance text-white md:mb-0">{text}</p>}
            <Button variant="transparent" className="mt-8 hidden md:block" onClick={toggleIsOpened}>
              {ctaLabel ? ctaLabel : 'Subscribe to our newsletter'}
            </Button>
          </div>
          <NewsletterTray
            className={cn('hidden md:block', {
              'md:translate-x-0': isOpened,
            })}
          />
        </div>
      </div>
      <div className="md:hidden">
        <Divider type="7-line" />
      </div>
      <div className="grid-container md:hidden">
        <NewsletterTray
          className={cn({
            'translate-x-0': isOpened || !isVisible,
          })}
        />
      </div>
    </section>
  )
}

export default NewsletterCTA
