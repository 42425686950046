'use client'
import Image from 'next/image'
import { useRef } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import SliderNavigation from '@/components/ui/slider-navigation'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import type { ProductStoryBlock } from '@/types'

import CallToAction from '../shared/call-to-action'
import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'
// import BlogCardSmall from '../blog/card-small'
import ProductCard from '../shared/product-card'
import Button from '../ui/button'
import CursorTooltipWrapper from '../ui/cursor-tooltip-wrapper'
import Divider from '../ui/divider'

const ProductStory = ({
  productStory: {
    subtitle,
    title,
    copy,
    cta,
    media,
    bottomLineCopy,
    productSliderHeader,
    products,
    cursorTooltip,
    tooltipCaption,
    variant,
  },
}: ProductStoryBlock) => {
  const imgRef = useRef<HTMLImageElement | HTMLDivElement>(null)
  const leftArrow = useRef<HTMLButtonElement>(null)
  const rightArrow = useRef<HTMLButtonElement>(null)
  const onScreen = useIsOnscreen({
    ref: imgRef,
    rootMargin: '-0px 0px -40% 0px',
    once: false,
  })

  switch (variant) {
    case 'advertorial':
      return (
        //comment out to see grayscale effect
        // <div className="flex h-[3000px] w-full items-center">
        <CursorTooltipWrapper isVisible={!!cursorTooltip} text={tooltipCaption}>
          <div className="relative overflow-hidden">
            <div className="grid-container container">
              <div className="relative col-span-full lg:col-span-20 lg:col-start-3">
                <Divider
                  type="solid-diamond"
                  className="absolute left-1/2 top-0 w-screen -translate-x-1/2 lg:relative lg:left-auto lg:top-auto lg:w-full lg:translate-x-0"
                />
                <h3 className="absolute -top-2 left-[15vw] bg-beige px-3 py-2 leading-4">
                  Advertorial
                </h3>
              </div>
            </div>
            <div className="grid-auto-rows-auto grid-container container w-full py-[30px] md:pb-16 md:pt-[44px]">
              <div className="col-span-full col-start-1 md:col-span-8 md:col-start-12 md:row-span-1">
                {title && (
                  <p className="mb-4 font-sans text-[30px] font-bold leading-[36px] tracking-[0.6px] md:mb-11 md:text-[40px] md:leading-[45px] md:tracking-[0.8px]">
                    {title}
                  </p>
                )}
              </div>
              <div
                ref={imgRef}
                className={`relative col-span-full col-start-1 mx-4 aspect-square self-center grayscale md:col-span-9 md:col-start-3 md:row-start-1 md:row-end-4 md:mx-0 ${onScreen ? 'grayscale-0' : ''} transition-all duration-1000`}
              >
                <Image
                  fill
                  src={'/assets/chai.png'}
                  alt="image of tea"
                  className="w-full object-contain"
                />
              </div>
              <div className="col-span-5 col-start-2 mt-4 md:col-span-6 md:col-start-14 md:row-span-1 md:mt-0">
                {copy && (
                  <CustomPortableText
                    value={copy}
                    paragraphClasses="text-justify font-bold [&:not(:last-child)]:mb-1em"
                  />
                )}
                {cta && (
                  <Button asChild variant="tertiary" className="cta self-start pt-[30px]">
                    <CallToAction {...cta} />
                  </Button>
                )}
              </div>
              <div className="col-span-full col-start-1 md:col-span-9 md:col-start-12 md:row-span-1">
                {bottomLineCopy && (
                  <p className="mt-[50px] text-justify text-[24px] font-bold tracking-[1.2px] md:mt-[60px] md:text-[30px] md:tracking-[1.5px] xl:mt-6">
                    {bottomLineCopy}
                  </p>
                )}
              </div>
            </div>
            <Divider
              type="solid-diamond"
              className="bottom-0 top-auto w-full before:-translate-y-full"
            />
          </div>
        </CursorTooltipWrapper>
        // </div>
      )

    case 'in-line':
      return (
        <CursorTooltipWrapper isVisible={!!cursorTooltip} text={tooltipCaption}>
          <Divider type="shorter-outline-diamond" className="blog-module-spacing mb-8 md:mb-16" />
          <div className="grid-container container">
            <div className="blog-content-inner" ref={imgRef}>
              {subtitle && <p className="h3 mb-2 text-center">{subtitle}</p>}
              {title && <h2 className="h5 mb-6 text-balance text-center md:mb-12">{title}</h2>}

              {media && (
                <Media
                  data={media}
                  aspectRatio="16/9"
                  className={cn('mb-4 h-auto grayscale transition-all duration-1000 md:mb-6', {
                    'grayscale-0': onScreen,
                  })}
                />
              )}

              {copy && (
                <CustomPortableText
                  value={copy}
                  paragraphClasses="body text-justify"
                  className="space-y-6"
                />
              )}

              {!!products?.length && productSliderHeader && (
                <h3 className="h3 mb-6 mt-4 w-full md:my-8">{productSliderHeader}</h3>
              )}
              {!!products?.length && (
                <Swiper
                  slidesPerView={1.5}
                  spaceBetween={8}
                  breakpoints={{
                    768: {
                      slidesPerView: 2.5,
                      spaceBetween: 12,
                    },
                    1024: {
                      slidesPerView: 2.5,
                      spaceBetween: 16,
                    },
                    1280: {
                      slidesPerView: 2.5,
                      spaceBetween: 24,
                    },
                  }}
                  modules={[Navigation]}
                  speed={500}
                  loop
                  className={cn(`relative`, {
                    'mt-4': !productSliderHeader,
                  })}
                >
                  {products.map((post, i) => {
                    return (
                      <SwiperSlide key={post._id + '-' + i}>
                        <ProductCard data={post} variant="full" />
                      </SwiperSlide>
                    )
                  })}
                  <div slot="container-end">
                    <SliderNavigation type="prev" className="-translate-y-14" ref={leftArrow} />
                    <SliderNavigation type="next" className="-translate-y-14" ref={rightArrow} />
                  </div>
                </Swiper>
              )}
            </div>
          </div>
          <Divider type="shorter-outline-diamond" className="blog-module-spacing" />
        </CursorTooltipWrapper>
      )
  }
}

export default ProductStory
