'use client'
import 'swiper/css'

import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '@/components/ui/button'
import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'
import { cn } from '@/lib/utils'
import CallToAction from '../shared/call-to-action'
import { CustomPortableText } from '../shared/custom-portable-text'
import Media from '../shared/media'
import Divider from '../ui/divider'
import CtaBanner from './cta-banner'
import SliderNavigation from '../ui/slider-navigation'
import type { SectionFullHeader } from '@/types'
import { useRef } from 'react'
import { Navigation } from 'swiper/modules'
import ProductCard from '../shared/product-card'

const SectionFullHeader = ({
  fullWidthHeaderMedia,
  sectionSubtitle,
  sectionTitle,
  sectionCopyTop,
  sectionCopyBottom,
  sectionCtaPrimary,
  sectionCtaSecondary,
  sectionMedias,
  banner,
  products,
  variant,
  isDisabled,
}: SectionFullHeader) => {
  const isDesktop = useTailwindBreakpoint('md')
  const sliderRef = useRef(null)

  if (isDisabled) return null

  return (
    <>
      <div
        className={`relative ${fullWidthHeaderMedia && fullWidthHeaderMedia.mediaType !== 'none' ? 'pt-16' : 'pt-0'}`}
      >
        {fullWidthHeaderMedia && (
          <Media
            data={fullWidthHeaderMedia}
            className="top-header-height z-[1] pb-1 [&&]:sticky"
            aspectRatio={isDesktop ? '16/9' : 'portrait'}
          />
        )}
        <div className="relative z-[2] bg-default-bg-color">
          <section className="md:grid-container container overflow-hidden pb-12 pt-16 md:auto-rows-auto md:gap-y-16 md:overflow-visible md:pb-16 md:pt-16 xl:gap-y-24">
            <div
              className={cn(
                'col-span-full md:col-span-9 md:self-center xl:col-span-7 xl:col-start-2 2xl:col-span-6 2xl:col-start-2',
                `md:row-span-${Math.ceil(sectionMedias ? sectionMedias?.length / 2 : 0)}`,
              )}
            >
              <Divider type="shorter-2-line" className="mb-4 max-w-28" />
              {!!sectionSubtitle && <p className="h3 mb-2">{sectionSubtitle}</p>}
              {!!sectionTitle && <h2 className="h4 mb-9">{sectionTitle}</h2>}

              <div className="grid-container">
                {!!sectionCopyTop && (
                  <CustomPortableText
                    paragraphClasses="paragraph [&:not(:last-child)]:mb-1em text-justify"
                    value={sectionCopyTop}
                    className="col-span-full max-w-md md:max-w-full"
                  />
                )}
                <div className="col-span-full mt-9 flex space-x-4 md:hidden">
                  {!!sectionCtaPrimary && (
                    <Button variant="primary" asChild>
                      <CallToAction {...sectionCtaPrimary} />
                    </Button>
                  )}

                  {!!sectionCtaSecondary && (
                    <Button variant="secondary" asChild>
                      <CallToAction {...sectionCtaSecondary} />
                    </Button>
                  )}
                </div>

                {!!sectionMedias && (
                  <Swiper
                    className="col-span-5 my-9 w-full [&&]:overflow-visible [&&]:md:hidden"
                    spaceBetween={20}
                    slidesPerView="auto"
                    // loop
                  >
                    {/* dirty fix for loop crash on mobile */}
                    {[...sectionMedias, ...sectionMedias, ...sectionMedias, ...sectionMedias].map(
                      (sectionMedias, key) => (
                        <SwiperSlide key={key} className="max-w-xs">
                          <Media data={sectionMedias} />
                        </SwiperSlide>
                      ),
                    )}
                  </Swiper>
                )}

                {!!sectionCopyBottom && (
                  <CustomPortableText
                    paragraphClasses="paragraph [&:not(:last-child)]:mb-1em text-justify mt-1em"
                    value={sectionCopyBottom}
                    className="col-start-2 -col-end-1 max-w-md md:col-span-full md:max-w-full"
                  />
                )}

                <div className="mt-9 hidden space-x-4 md:col-span-full md:block">
                  {!!sectionCtaPrimary && (
                    <Button variant="primary" asChild>
                      <CallToAction {...sectionCtaPrimary} />
                    </Button>
                  )}

                  {!!sectionCtaSecondary && (
                    <Button variant="secondary" asChild>
                      <CallToAction {...sectionCtaSecondary} />
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {sectionMedias?.map((sectionMedias, index) => (
              <Media
                key={index}
                data={sectionMedias}
                className={cn('hidden md:block', {
                  'md:col-span-10 md:col-start-11': index === 0,
                  'md:col-span-5': index > 0,
                  'md:col-start-11': index > 0 && index % 2 === 1,
                  'md:col-start-16': index > 0 && index % 2 === 0,
                })}
              />
            ))}
          </section>
          {variant === 'products' && (
            <div className="w-full max-w-[1680px] overflow-hidden">
              <div className="em:mt-[60px] ml-auto mt-[20px] w-full max-w-full pl-2 md:max-w-[710px] md:pl-0">
                <Swiper
                  slidesPerView={1.4}
                  ref={sliderRef}
                  modules={[Navigation]}
                  spaceBetween={24}
                  speed={500}
                  breakpoints={{
                    480: {
                      slidesPerView: 2,
                    },
                    1280: {
                      slidesPerView: 2.4,
                    },
                  }}
                  className={`!em:pt-[40px] !overflow-visible !pt-[20px] md:!pt-[60px]`}
                >
                  {products &&
                    products?.map((item, index) => (
                      <SwiperSlide key={index}>{item && <ProductCard data={item} />}</SwiperSlide>
                    ))}
                  <span slot="container-end">
                    <SliderNavigation
                      type="next"
                      className="right-[17%] top-[45%] translate-x-full bg-beige"
                      hideDisabled
                    />
                  </span>
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
      {banner?.copy && !banner?.isDisabled && <CtaBanner {...banner} className="mt-12" />}
    </>
  )
}

export default SectionFullHeader
