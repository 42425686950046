const StoreHeroPagination = (index: number, className?: string) => {
  const activeImage = '/assets/activeLine.png'
  const inactiveImage = '/assets/inactive.svg'
  const spacerImage = '/assets/spacer.svg'

  return `
    <div class="relative flex items-center">
      <img
        src="${className?.includes('swiper-pagination-bullet-active') ? inactiveImage : activeImage}"
        class="${className} mx-1 transition-all duration-300 transform object-cover !bg-transparent !rounded-none !h-[3px] ${className?.includes('swiper-pagination-bullet-active') ? '!w-4' : '!w-14'}"
        alt="Bullet ${index + 1}"
      />
      <img src="${spacerImage}" alt="Spacer" class="w-2 h-2 mx-1" />
    </div>
  `
}

export default StoreHeroPagination
