import CallToAction from '@/components/shared/call-to-action'
import Divider from '@/components/ui/divider'
import { cn } from '@/lib/utils'
import type { CareersModule } from '@/types'

import Media from '../../shared/media'
import SharedCopyBlock from '../../shared/shared-copy-block'
import Button from '../../ui/button'

const CareersModuleMobile = ({
  fullWidthMedia,
  copyBlock,
  sections,
  isDisabled,
}: CareersModule) => {
  if (isDisabled) return null

  return (
    <div className="relative bg-black md:hidden">
      <Media
        data={fullWidthMedia}
        className="top-header-height [&&]:sticky"
        aspectRatio="portrait"
      />
      <section className="container top-header-height flex w-screen justify-center bg-black pb-[45px] md:h-auto [&&]:sticky">
        <Divider type="solid-diamond-inverse" />
        <div className="pt-12 text-white">
          <SharedCopyBlock
            title={copyBlock?.title || ''}
            subtitle={copyBlock?.subtitle || ''}
            copy={copyBlock?.copy}
            buttonVariant="tertiary"
            className="text-left [&_svg]:mb-[30px]"
          />
          {copyBlock?.cta && (
            <div className="mt-[30px] flex w-full justify-center">
              <Button asChild variant="transparent" className="cta self-start">
                <CallToAction {...copyBlock.cta} />
              </Button>
            </div>
          )}
        </div>
      </section>

      {sections?.map(({ sectionCopyBlock, media }, key) => {
        const { title, subtitle, copy, cta } = sectionCopyBlock as SharedCopyBlock

        return (
          <section
            key={key}
            className={cn(
              'container relative top-header-height flex w-screen flex-col justify-center bg-black pb-[45px] md:h-auto [&&]:sticky',
            )}
          >
            <Divider type="solid-diamond-inverse" />
            <div className="items-center justify-items-center">
              <div className="w-full px-8 pt-10 sm:justify-self-center">
                <Media data={media} className="max-w-[38rem] text-white" aspectRatio="16/9" />
              </div>
              <div className="pt-7 text-white md:py-12 md:pl-8 md:pr-16">
                <SharedCopyBlock
                  title={title}
                  subtitle={subtitle}
                  copy={copy}
                  buttonVariant="tertiary"
                  className="text-left [&_svg]:mb-[30px]"
                />
              </div>
            </div>
            {cta && key === sections.length - 1 && (
              <Button asChild variant="transparent" className="cta mt-7 self-center">
                <CallToAction {...cta} />
              </Button>
            )}
          </section>
        )
      })}
    </div>
  )
}

export default CareersModuleMobile
