'use client'
import Image from 'next/image'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import MuxVideo from '@/components/shared/mux-video'
import { cn } from '@/lib/utils'
import type { MediaOnly, MuxVideo as MuxVideoType } from '@/types'

type MuxVideoWrapperProps = {
  media?: MediaOnly
  video?: MuxVideoType
  title?: string
  className?: string
  isMuted?: boolean
  onPlay?: (event) => void
  buttonPosition: 'center' | 'bottom'
  aspectRatio: '16/9' | 'videos-block'
}

export type VideoControlledRef = {
  play: () => void
  pause: () => void
  current: HTMLVideoElement
}

const VideoControlled = forwardRef<VideoControlledRef, MuxVideoWrapperProps>(
  ({ media, video, title, className, buttonPosition, aspectRatio, ...props }, ref) => {
    const [isMuted, setIsMuted] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const videoRef = useRef<HTMLVideoElement | null>(null)

    // Forward the internal videoRef to the parent ref
    useImperativeHandle(ref, () => {
      return {
        current: videoRef.current as HTMLVideoElement,
        play() {
          playVideo()
        },
        pause() {
          pauseVideo()
        },
      }
    })

    const toggleSound = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()

      const video = videoRef.current
      if (!video) return

      const newState = !video.muted
      video.muted = newState
      setIsMuted(newState)
    }

    const togglePlayState = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()

      const video = videoRef.current
      if (!video) return

      if (video.paused) {
        playVideo()
      } else {
        pauseVideo()
      }
    }

    const playVideo = async () => {
      const video = videoRef.current
      if (!video) return

      try {
        await video.play()
        setIsPlaying(true)
      } catch (error) {
        console.log(error)
      }
    }

    const pauseVideo = () => {
      const video = videoRef.current
      if (!video) return

      video.pause()
      setIsPlaying(false)
    }

    const data = media?.video?.asset || video?.asset

    return (
      <div
        className={cn(
          'relative before:block',
          {
            'before:aspect-h-16 before:aspect-w-9': aspectRatio === 'videos-block',
            'before:aspect-h-9 before:aspect-w-16': aspectRatio === '16/9',
          },
          className,
        )}
      >
        <div
          className={cn('group absolute inset-0 cursor-pointer', {
            'before:absolute before:inset-0 before:z-10 before:bg-gradient-to-t before:from-[rgba(0,0,0,0.4)] before:to-transparent':
              !isPlaying,
          })}
          onClick={togglePlayState}
        >
          <MuxVideo
            {...data}
            title={title}
            className={cn('h-full w-full object-cover')}
            autoPlay={false}
            isMuted={false}
            ref={videoRef}
            {...props}
          />

          <div
            className={cn(
              'absolute bottom-[42px] z-20 flex w-full flex-col items-center justify-center gap-[19px] transition-opacity duration-500 md:gap-6',
              {
                'bottom-[42px] md:bottom-[71px]': buttonPosition === 'bottom',
                'top-1/2 -translate-y-1/2': buttonPosition === 'center',
              },
              isPlaying ? 'opacity-0' : 'opacity-1',
            )}
          >
            <div className="group h-[30px] w-[40px] md:h-[45px] md:w-[60px]">
              <button
                className="flex h-full w-full cursor-pointer items-center justify-center bg-black transition-colors duration-500 group-hover:bg-white"
                onClick={togglePlayState}
              >
                <Image
                  className="transition-all duration-300 group-hover:invert group-hover:filter"
                  src="/assets/icons/play.svg"
                  width="25"
                  height="25"
                  alt="Play/Pause"
                />
              </button>
            </div>

            {title && <h3 className="w-full px-2 text-center text-white">{title}</h3>}
          </div>

          <button
            className="absolute bottom-2 right-2 z-20 flex cursor-pointer items-center justify-center rounded-full bg-black p-1 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            onClick={toggleSound}
          >
            {isMuted ? (
              <Image src="/assets/icons/sound-off.svg" width="12" height="12" alt="Sound Off" />
            ) : (
              <Image src="/assets/icons/sound-on.svg" width="12" height="12" alt="Sound On" />
            )}
          </button>
        </div>
      </div>
    )
  },
)

VideoControlled.displayName = 'VideoControlled'

export default VideoControlled
