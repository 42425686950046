'use client'

import { CSSProperties, useMemo, useRef } from 'react'

import { BlogHeaderProps } from '@/components/modules/blog-header'
import BookmarkShare from '@/components/shared/bookmark-share'
import Media from '@/components/shared/media'
import { SanityImage } from '@/components/shared/sanity-image'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'

const BlogHeaderInset = ({
  title,
  excerpt,
  heroMedia,
  heroLogo,
  category,
  textColor,
  color,
}: BlogHeaderProps) => {
  const ref = useRef(null)
  const isVisible = useIsOnscreen({ ref })

  const mediaClassName = useMemo(
    () =>
      cn(
        'border-[8px] border-[#353839] transition-opacity delay-200 duration-500 after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:to-[black]/60',
        {
          'opacity-0': !isVisible,
        },
      ),
    [isVisible],
  )

  const headerInlineStyle = { '--bg-color': color?.hex } as CSSProperties
  return (
    <header
      className={cn(
        'inset-header relative py-16 after:absolute after:left-0 after:right-0 after:top-0 after:z-[-1] after:h-[70%]',
        {
          'text-black': textColor === 'dark',
          'text-off-white': textColor === 'light',
        },
      )}
      style={headerInlineStyle}
      ref={ref}
    >
      <div className="grid-container container mb-10">
        <div className="col-span-20 mt-[60px] text-center md:col-span-12 md:col-start-5">
          {category && <p className="tag mb-6 md:mb-8">{category.split('-').join(' ')}</p>}
          {title && (
            <h1 className="mb-3 text-balance font-serif text-36 leading-44 tracking-2 md:mb-5 md:text-42 md:leading-48">
              {title}
            </h1>
          )}
          {excerpt && <p className="paragraph">{excerpt}</p>}
          <div className="mt-10 md:mt-11">
            <BookmarkShare
              isBookmarkVisible={false}
              className="justify-center"
              iconClassName={cn('', {
                'text-black': textColor === 'dark',
                'text-off-white': textColor === 'light',
              })}
            />
          </div>
        </div>
      </div>
      {!!heroMedia && (
        <div className="container relative h-[390px] px-10 md:h-[760px]">
          {heroLogo && (
            <SanityImage data={heroLogo} className="absolute left-1 top-8 z-[1] max-w-[260px]" />
          )}
          <Media data={heroMedia} cover className={mediaClassName} />
        </div>
      )}
    </header>
  )
}

export default BlogHeaderInset
