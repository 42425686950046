'use client'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useRef } from 'react'

import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'
import useWindowDimensions from '@/hooks/use-window-dimensions'
import { theme } from '@/tailwind.config.js'
import type { ImpactModule } from '@/types'

import Media from '../shared/media'
import SharedCopyBlock from '../shared/shared-copy-block'
import Divider from '../ui/divider'

gsap.registerPlugin(useGSAP, ScrollTrigger)

const ImpactModule = ({ sections = [], isDisabled }: ImpactModule) => {
  const { width, clientWidth } = useWindowDimensions()
  const isMd = useTailwindBreakpoint('md')
  const containerRef = useRef<HTMLDivElement | null>(null)
  const darkColor = (theme?.colors?.['black'] as string) ?? 'black'
  const lightColor = (theme?.colors?.['off-white'] as string) ?? 'white'
  const textColor = sections[0].textColor === 'light' ? lightColor : darkColor
  const isMobile = width !== undefined && width < 400

  useGSAP(
    async () => {
      if (!containerRef.current || !sections || !width) return

      const container = containerRef.current as HTMLDivElement
      const sectionsContainer = container.querySelector('.horizontal-container') as HTMLDivElement
      const firstSection = container.querySelector('section:first-of-type') as HTMLDivElement
      const firstSectionCopy = firstSection.querySelector('.copy-block') as HTMLDivElement
      const copyRect = firstSectionCopy.getBoundingClientRect()
      const copyWidth = firstSectionCopy.offsetWidth
      const sectionsLength = sectionsContainer.offsetWidth

      const tl = gsap.timeline({
        ease: 'none',
        scrollTrigger: {
          trigger: container,
          scrub: true,
          pin: true,
          start: () => {
            const headerHeight = parseFloat(
              getComputedStyle(document.documentElement).getPropertyValue('--header-height'),
            )
            return `top ${headerHeight}px`
          },
          end: () => `+=${sectionsLength}`,
        },
      })

      gsap.set(firstSection, {
        marginLeft: () => {
          if (isMd) {
            const margin = clientWidth / 2 - copyRect.left - copyWidth / 2
            return margin
          }

          return 0
        },
      })

      tl.to(sectionsContainer, {
        x: () => {
          const sectionPercentageLength = 100 / sections.length
          const distance = 100 - sectionPercentageLength
          return '-' + distance + '%'
        },
        ease: 'linear',
        duration: 2,
      })

      const totalDuration = tl.duration()
      const durationPerSection = totalDuration / (sections.length - 1)
      sections.forEach((section, index) => {
        const nextSection = sections[index + 1]
        if (!nextSection) return

        const bg = nextSection.color?.hex
        const color = nextSection.textColor === 'light' ? lightColor : darkColor

        tl.to(
          container,
          {
            backgroundColor: bg,
            color: color,
            duration: 0.2,
          },
          durationPerSection * (index + 1) - 0.3,
        )
      })
    },
    { scope: containerRef, dependencies: [clientWidth, isMd], revertOnUpdate: true },
  )

  if (!sections?.length || isDisabled) return null

  return (
    <div className="overflow-hidden">
      <div
        className="relative flex h-[calc(100vh-var(--header-height))] overflow-hidden md:w-[255vw]"
        ref={containerRef}
        style={{
          backgroundColor: sections[0].color?.hex,
          color: textColor,
        }}
      >
        <Divider type="thick-thin" className="absolute inset-x-0 top-0" />
        <div className="horizontal-container flex max-w-none flex-shrink-0 items-center pt-5 md:pt-0">
          {sections.map(({ title, subtitle, copy, cta, media }, key) => {
            return (
              <section
                key={key}
                className={`section-${key} grid-container container mr-28 w-screen flex-shrink-0 auto-rows-auto items-center gap-y-9 md:mr-0 xl:max-w-none 2xl:ml-24 small-phone:gap-6`}
              >
                <SharedCopyBlock
                  title={title}
                  subtitle={subtitle}
                  copy={copy}
                  cta={cta}
                  buttonVariant="tertiary"
                  textAlign={isMd ? 'justify' : 'left'}
                  headerAlign={isMd ? 'center' : 'left'}
                  className="copy-block col-span-full md:col-span-8 xl:col-span-6 xl:col-start-3 small-phone:col-span-6"
                  ornamentClassName="hidden md:block"
                  subtitleVariant="h3"
                  buttonClassName="ml-0 md:mx-auto small-phone:mt-4"
                />

                <Media
                  data={media}
                  aspectRatio="square"
                  className="col-span-4 mb-4 max-w-sm translate-x-[60%] md:col-span-9 md:col-start-10 md:mb-0 md:h-auto md:translate-x-0 small-phone:h-[220px] small-phone:translate-x-0"
                />
              </section>
            )
          })}
        </div>

        <Divider type="thin-thick" className="absolute inset-x-0 bottom-0" />
      </div>
    </div>
  )
}

export default ImpactModule
