import type { Image } from 'sanity'

import FAQHero from '@/components/faq/faq-hero'
import BlogHeaderBackgroundMedia from '@/components/modules/blog-header-background-media'
import BlogHeaderInset from '@/components/modules/blog-header-inset'
import Media from '@/components/shared/media'
import OrnamentHeading from '@/components/shared/ornament-heading'
import ReadAndListen from '@/components/shared/read-and-listen-box'
import { Color } from '@/sanity/types'
import type { File, MediaOnly, NavigationDropdownLink, PostCategory, PostPayload } from '@/types'

import BookmarkShare from '../shared/bookmark-share'
import Divider from '../ui/divider'
import DlAlternativeHeader from './dl-alternative-header'
import GiftingHeader from './gifting-header'
import HubHeader from './hub-header'

export type BlogHeaderProps = {
  title?: string
  subtitle?: string
  heroMedia?: MediaOnly
  heroLogo?: Image
  category?: PostCategory | string
  excerpt?: string
  heroVariant?: PostPayload['heroVariant']
  readingTime?: string
  storyAudio?: File
  textColor?: string
  color?: Color
  isGifting?: boolean
  hideNavigationDropdown?: boolean
  navigationDropdownLinks?: NavigationDropdownLink[]
}

const DefaultHeader = ({
  title,
  category,
  excerpt,
  heroMedia,
  storyAudio,
  readingTime,
}: BlogHeaderProps) => (
  <>
    {!!heroMedia && <Media data={heroMedia} className="fixed top-0 h-[60vh]" />}
    <div className="h-[60vh]"></div>
    <header className="relative z-content bg-beige">
      <Divider type="thick-thin" className="absolute left-0 top-0 w-full" />
      <OrnamentHeading
        className="container mx-auto pt-10 lg:w-3/5 lg:pt-20"
        titleClassName="lg:text-[42px] lg:leading-[48px] tracking-[0.02em]"
        subtitleClassName="mb-7 lg:mb-8"
        excerptClassName="lg:text-[21px] lg:leading-[27px] tracking-[0.02em] mb-3"
        style="style-3"
        titleVariant="h1"
        title={title}
        subtitle={category}
        excerpt={excerpt}
        withoutTopLine={true}
        withoutBottomLine={!(readingTime && storyAudio)}
      />
      <div className="mx-auto mt-10 hidden w-fit md:block">
        {readingTime && storyAudio && (
          <ReadAndListen readingTime={readingTime} storyAudio={storyAudio} />
        )}
        <BookmarkShare isBookmarkVisible={false} className="mt-6" />
      </div>
    </header>
  </>
)

const BlogHeader = ({
  title,
  subtitle,
  category,
  excerpt,
  heroMedia,
  heroLogo,
  heroVariant,
  readingTime,
  storyAudio,
  textColor,
  color,
  isGifting,
  hideNavigationDropdown,
  navigationDropdownLinks,
}: BlogHeaderProps) => {
  switch (heroVariant) {
    case 'inset':
    case 'inset-with-logo':
      return (
        <BlogHeaderInset
          title={title}
          category={category}
          excerpt={excerpt}
          heroMedia={heroMedia}
          heroLogo={heroLogo}
          textColor={textColor}
          color={color}
        />
      )
    case 'backgroundMedia':
      return isGifting ? (
        <GiftingHeader
          title={title}
          subtitle={subtitle}
          category={category}
          excerpt={excerpt}
          heroMedia={heroMedia}
        />
      ) : (
        <BlogHeaderBackgroundMedia
          category={category}
          title={title}
          excerpt={excerpt}
          heroMedia={heroMedia}
          readingTime={readingTime}
          storyAudio={storyAudio}
          isGifting={isGifting}
        />
      )
    case 'hub':
      return (
        <HubHeader
          title={title}
          subtitle={subtitle}
          category={category}
          excerpt={excerpt}
          heroMedia={heroMedia}
          readingTime={readingTime}
          storyAudio={storyAudio}
        />
      )
    case 'faq-header':
      return (
        <FAQHero
          title={title}
          heroSubtitle={subtitle}
          media={heroMedia}
          copy={excerpt}
          hideNavigationDropdown={hideNavigationDropdown}
          navigationDropdownLinks={navigationDropdownLinks}
        />
      )
    case 'dl-alternative':
      return (
        <DlAlternativeHeader
          title={title}
          category={category}
          heroMedia={heroMedia}
          excerpt={excerpt}
          color={color}
        />
      )
    default:
      return (
        <DefaultHeader
          title={title}
          category={category}
          excerpt={excerpt}
          heroMedia={heroMedia}
          readingTime={readingTime}
          storyAudio={storyAudio}
        />
      )
  }
}

export default BlogHeader
