'use client'
import { usePathname, useRouter } from 'next/navigation'

import Media from '@/components/shared/media'
import Divider from '@/components/ui/divider'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { resolveHref } from '@/sanity/lib/utils'
import type { MediaOnly, NavigationDropdownLink } from '@/types'

type FAQHeroProps = {
  title?: string
  copy?: string
  media?: MediaOnly
  heroSubtitle?: string
  hideNavigationDropdown?: boolean
  navigationDropdownLinks?: NavigationDropdownLink[]
}

const FAQHero = ({
  title,
  copy,
  media,
  heroSubtitle,
  hideNavigationDropdown,
  navigationDropdownLinks,
}: FAQHeroProps) => {
  const pathname = usePathname()
  const router = useRouter()

  const onChangeHandler = (selectedSlug: string) => {
    if (pathname === selectedSlug) return
    router.push(selectedSlug)
  }

  const currentLink = navigationDropdownLinks?.find((item) => resolveHref(item.link) === pathname)
  const currentLinkTitle = currentLink ? currentLink.title : 'Support and policies'

  return (
    <div className="relative pt-header-height">
      <Divider
        type="thin"
        className="w-full -translate-y-1/2 border-b border-t border-gray-light py-[2px]"
      />
      <div className="lg:py-5">
        <div className="grid-container container items-center py-5">
          <div className="col-span-20 h-full lg:col-span-10 lg:px-2">
            <div className="flex h-full border-gray-light lg:border-l lg:border-r lg:px-1">
              <div className="grid h-full w-full grid-cols-10 border-gray-light lg:border-l lg:border-r">
                <div className="col-span-10 grid items-center lg:col-start-2 lg:col-end-9">
                  <div>
                    <p className="pre-heading">{heroSubtitle}</p>
                    <h1 className="py-2">{title}</h1>
                    <p className="text-gray-400 hidden pt-4 text-justify md:block">{copy}</p>
                    {!hideNavigationDropdown && (
                      <div className="md:pt-6">
                        <label className="block font-semibold">Support and policies:</label>
                        <Select value={pathname} onValueChange={onChangeHandler}>
                          <SelectTrigger className="mt-1 rounded-none border border-gray-light bg-transparent outline-none focus:ring-0 focus:ring-offset-0 lg:w-3/4">
                            <SelectValue className="bg-transparent">{currentLinkTitle}</SelectValue>
                          </SelectTrigger>
                          <SelectContent className="border-gray-light bg-beige">
                            <SelectGroup>
                              {navigationDropdownLinks?.map((item) => (
                                <SelectItem key={item.title} value={resolveHref(item.link)}>
                                  {item.title}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:col-span-10 lg:block">
            <Media data={media} aspectRatio="7/5" />
          </div>
        </div>
      </div>
      <Divider type="thin" className="border-gray-light" />
    </div>
  )
}

export default FAQHero
